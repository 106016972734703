import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Row, Col, DatePicker, Spin, Table } from 'antd';
import { wtGetFromLocalStorage } from 'src/helpers/commons/LocalStorage';
import WidgetAccountsSelector from 'src/widgets/Selectors/WidgetAccountsSelector'
import BaseListing from 'src/BaseListings';
import WidgetSelector from 'src/widgets/Selectors/WidgetSelector';
import { SOURCE_TYPE_NAMES, STATUS_NAME, STATUS_POSTED, VOUCHER_STATUS_DROPDOWN_OPTIONS } from 'src/models/vouchers';
import WidgetProjectSelector from 'src/widgets/Selectors/WidgetProjectSelector';
import { wtApiCall } from 'src/helpers/commons/ApiWrapper';
import WidgetBranchSelector from 'src/widgets/Selectors/WidgetBranchSelector'
import { lzNotifyErrorMessageFromResult } from 'src/helpers/commons/notifications';
import { wtFormatMoney } from 'src/helpers/commons/utils';
import VoucherPDF from 'src/PDF/VoucherPDF';
import dayjs from 'dayjs';

const AccountStatement = () => {
    const Base = new BaseListing('account_statement')
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm();

    const [accounts, setAccounts] = useState([])
    const [projects, setProjects] = useState([])
    const [branches, setBranches] = useState([])

    const [accountId, setAccountId] = useState()
    const [accountName, setAccountName] = useState('')
    const [projectId, setProjectId] = useState()
    const [projectName, setProjectName] = useState('')

    const [voucherStatus, setVoucherStatus] = useState(STATUS_POSTED)
    const [voucherStatusName, setVoucherStatusName] = useState(STATUS_NAME[STATUS_POSTED])

    const [branchId, setBranchId] = useState(0)
    const [branchName, setBranchName] = useState('')
    const [accountStatementData, setAccountStatementData] = useState([])
    const [openingBalance, setOpeningBalance] = useState(0)
    const [closingBalance, setClosingBalance] = useState(0)
    const [dateRange, setDateRange] = useState([])

    const companyName = wtGetFromLocalStorage("company_name");

    // Define columns of account statement
    const accountStatementHeaderColumns = [
        {
            title: 'Voucher No',
            dataIndex: 'voucher_no',
            key: 'voucher_no',
            fixed: 'left',
            render: (text, record) => (
                <span style={{
                    cursor: "pointer",
                    color: "blue"
                }}
                    onClick={() => getPdfData(record.voucher_id)}
                    title='Download Voucher'
                >
                    {text}
                </span>
            )
        },
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',
        },
        {
            title: 'Date',
            dataIndex: 'voucher_date',
            key: 'voucher_date',
        },
        {
            title: 'Source',
            dataIndex: 'source_type',
            key: 'source_type',
        },
        {
            title: 'Account',
            dataIndex: 'account_name',
            key: 'account_name',
        },
        {
            title: 'Party',
            dataIndex: 'party_name',
            key: 'party_name',
        },
        {
            title: 'Project',
            dataIndex: 'project_name',
            key: 'project_name',
        },
        {
            title: 'Reference',
            dataIndex: 'reference',
            key: 'reference',
        },
        {
            title: 'Cheque No',
            dataIndex: 'cheque_no',
            key: 'cheque_no',
        },
        {
            title: 'Cheque Date',
            dataIndex: 'cheque_date',
            key: 'cheque_date',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Narration',
            dataIndex: 'narration',
            key: 'narration',
            width: 400
        },
        {
            title: 'Created By',
            dataIndex: 'created_by',
            key: 'created_by',
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Approved By',
            dataIndex: 'approved_by',
            key: 'approved_by',
        },
        {
            title: 'Approved At',
            dataIndex: 'approved_at',
            key: 'approved_at',
        },
        {
            title: 'Posted By',
            dataIndex: 'posted_by',
            key: 'posted_by',
        },
        {
            title: 'Posted At',
            dataIndex: 'posted_at',
            key: 'posted_at',
        },
        {
            title: 'Debit (PKR)',
            dataIndex: 'debit',
            key: 'debit',
            fixed: 'right',
            align: 'right'
        },
        {
            title: 'Credit (PKR)',
            dataIndex: 'credit',
            key: 'credit',
            fixed: 'right',
            align: 'right'
        },
        {
            title: 'Balance (PKR)',
            dataIndex: 'showBalance',
            key: 'showBalance',
            fixed: 'right',
            align: 'right'
        },
    ];

    const changeValues = (key, val) => {

        //Set initial values
        setOpeningBalance(0);
        setClosingBalance(0);
        setAccountStatementData([]);

        if (key === 'branch_id') {
            setBranchId(val.value)
            setBranchName(val.label)
        }
        if (key === 'account_id') {
            setAccountId(val)
            if (val === '') {
                setAccountName('');
            }
        }
        if (key === 'project_id') {
            setProjectId(val)
            if (val === '') {
                setProjectName('');
            }
        }
        if (key === 'voucher_status') {
            setVoucherStatus(val.value)
            setVoucherStatusName(val.label)
        }
        if (key === 'date_range') {
            setDateRange(val);
        }
    }

    useEffect(() => {
        Base.dropdownData('accounts,projects,branches', { ignore_last_level: false, enable_all_levels: true }, [setAccounts, setProjects, setBranches], 1);
        getQueryParams();
    }, []);

    useEffect(() => {

        if (typeof dateRange !== 'undefined' && dateRange !== null && typeof accountId !== 'undefined' && typeof voucherStatus !== 'undefined') {
            getReportData();
        }

    }, [dateRange, accountId, voucherStatus]);

    const getQueryParams = async () => {

        //get URL
        const url = new URL(window.location.href);

        // Get the search parameters from the URL
        const searchParams = new URLSearchParams(url.search);

        // Get the value of the 'fromDate' parameter
        let fromDate = searchParams.get('fromDate');
        let toDate = searchParams.get('toDate');
        const accountId = searchParams.get('accountId');
        const voucherStatus = searchParams.get('voucherStatus');
        const branchId = searchParams.get('branchId');
        const branchName = searchParams.get('branchName');
        const projectId = searchParams.get('projectId');

        if (fromDate !== '' && toDate !== '' && typeof fromDate !== 'undefined' && typeof toDate !== 'undefined' && fromDate !== null && toDate !== null) {

            fromDate = dayjs(fromDate, "YYYY-MM-DD");
            toDate = dayjs(toDate, "YYYY-MM-DD");
            setDateRange([fromDate, toDate])
        }
        if (+accountId > 0) {
            setAccountId(+accountId);
        }
        if (+voucherStatus > 0) {
            setVoucherStatus(voucherStatus);
            setVoucherStatusName(STATUS_NAME[voucherStatus])
        }
        if (+branchId > 0) {
            setBranchId(branchId);
            setBranchName(branchName);
        }
        if (+projectId > 0) {
            setProjectId(+projectId);
        }
    }


    const getReportData = () => {
        if (dateRange.length < 2) {
            lzNotifyErrorMessageFromResult("Please select date range");
            return;
        }
        const fromDate = dateRange[0];
        const toDate = dateRange[1];

        setIsLoading(true);
        wtApiCall(
            'reports/account_statement',
            {
                branch_id: branchId,
                from_date: fromDate.format('YYYY-MM-DD'),
                to_date: toDate.format('YYYY-MM-DD'),
                account_id: accountId,
                project_id: projectId,
                voucher_status: voucherStatus
            },
            'get',
            (response) => {

                //set data
                if (response !== null) {
                    if (response.data !== null) {
                        if (response.data.transactional_data.length > 0) {
                            // Add balance to each transactional data object
                            const dataWithBalance = response.data.transactional_data.map((item, index) => {
                                if (index === 0) {
                                    item.balance = parseFloat(response.data.opening_balance) + parseFloat(item.debit) - parseFloat(item.credit);
                                } else {
                                    const prevBalance = parseFloat(response.data.transactional_data[index - 1].balance);
                                    item.balance = prevBalance + parseFloat(item.debit) - parseFloat(item.credit);
                                }
                                //Sert source type
                                item.source_type = SOURCE_TYPE_NAMES[item.source_type];
                                item.status = STATUS_NAME[item.status];

                                //check if balance is -ve than show () in value
                                item.showBalance = wtFormatMoney(item.balance, '', true, 2);

                                item.debit = wtFormatMoney(item.debit, '', true, 2);
                                item.credit = wtFormatMoney(item.credit, '', true, 2);
                                return item;
                            });

                            // Calculate closing balance
                            const closingBalance = wtFormatMoney(dataWithBalance[dataWithBalance.length - 1].balance, '', true, 2);

                            //set all data
                            setOpeningBalance(wtFormatMoney(response.data.opening_balance, '', true, 2));
                            setClosingBalance(closingBalance);
                            setAccountStatementData(dataWithBalance);
                        }
                        else {
                            //Set initial values
                            setOpeningBalance(0);
                            setClosingBalance(0);
                            setAccountStatementData([]);
                        }
                    }
                }

                setIsLoading(false);
            },
            (error) => {
                lzNotifyErrorMessageFromResult(error);
                setIsLoading(false);
            },
        );

    };


    const getPdfData = (id) => {
        wtApiCall(
            'vouchers/' + id,
            {},
            'get',
            (response) => {
                const { data } = response
                VoucherPDF(data)
            },
            (error) => {
                lzNotifyErrorMessageFromResult(error)
            },
        )
    }


    return (
        <div className='container-fluid' style={{
            padding: "1em", backgroundColor: "white", minHeight: "100vh", fontFamily: "Helvetica",
        }}>
            <Form
                layout={'vertical'}
                form={form}
                onFinish={getReportData}
                style={{ maxWidth: "100%", margin: '0 auto' }}
            >
                <Card title={<span style={{ fontSize: '22px', marginLeft: "40%" }}>{companyName + " Account Statement"}</span>} style={{ width: "100%" }}>

                    <Row gutter={[16, 16]}>
                        <Col xs={15} sm={10} md={4}>
                            <label>Date Range: *</label>
                            <DatePicker.RangePicker
                                onChange={(value) => changeValues('date_range', value)}
                                style={{ width: '100%', height: 35 }}
                                value={dateRange}
                            />
                        </Col>
                        <Col xs={18} sm={12} md={6}>
                            <label>Account: *</label>
                            <WidgetAccountsSelector
                                data={accounts}
                                value={accountId}
                                displayValue={accountName}
                                height={35}
                                onChange={(value) => changeValues('account_id', value)}
                                reloadDropdownData={() => Base.dropdownData('accounts', {}, [setAccounts], 1)}
                            />
                        </Col>
                        <Col xs={9} sm={6} md={3}>
                            <label>Voucher Status: *</label>
                            <WidgetSelector
                                selectedValue={voucherStatus}
                                displayValue={voucherStatusName}
                                options={VOUCHER_STATUS_DROPDOWN_OPTIONS}
                                showCloudIcon={false}
                                onChange={(values) => changeValues("voucher_status", values)}
                            />
                        </Col>

                        <Col xs={12} sm={8} md={4}>
                            <label>Branch:</label>
                            <WidgetBranchSelector
                                value={branchId}
                                displayValue={branchName}
                                branches={branches}
                                placeholder="Select Branch"
                                onChange={(values) => changeValues('branch_id', values)}
                                reloadDropdownData={() => Base.dropdownData('branches', {}, [setBranches], 1)}
                            />

                        </Col>
                        <Col xs={15} sm={10} md={5}>
                            <label>Project: </label>
                            <WidgetProjectSelector
                                onChange={(value) => changeValues('project_id', value)}
                                data={projects}
                                value={projectId}
                                displayValue={projectName}
                                height={35}
                                reloadDropdownData={() => Base.dropdownData('projects', {}, [setProjects], 1)}
                            />
                        </Col>
                        <Col xs={8} sm={4} md={2}>
                            <Button style={{ marginTop: 30 }} type="primary" htmlType="submit" disabled={isLoading ? true : false}>Search</Button>
                        </Col>
                    </Row>

                    <br />
                    <div style={{ overflow: 'auto', margin: "auto", width: "100%" }}>
                        <Table
                            columns={accountStatementHeaderColumns}
                            dataSource={accountStatementData}
                            pagination={false}
                            bordered
                            size="small"
                            scroll={{ x: 'max-content' }} // Set the x-axis scroll to max-content
                            loading={{ indicator: <div><Spin /></div>, spinning: isLoading }}
                            title={() => (
                                <div style={{ textAlign: "right" }}>
                                    <h6 style={{ marginBottom: 0 }}>Opening Balance: {openingBalance}</h6>
                                </div>
                            )}
                            footer={() => (
                                <div style={{ textAlign: "right" }}>
                                    <h6 style={{ marginBottom: 0 }}>Closing Balance: {closingBalance}</h6>
                                </div>
                            )}
                        />
                    </div>
                </Card>
            </Form>
        </div >
    );
};

export default AccountStatement;
