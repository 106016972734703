import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
export default function WidgetPieChart({ chartOptions }) {
    return (

        <HighchartsReact highcharts={Highcharts} options={chartOptions} />

    )
}
