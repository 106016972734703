import React from "react";
import { connect } from "react-redux";
import { wtApiCall } from "../../helpers/commons/ApiWrapper";
import OrgChart from "@unicef/react-org-chart";
import "./EmployeeHierarchy.css";
import BaseListing from "src/BaseListings";
import WidgetEmployeeSelector from 'src/widgets/Selectors/WidgetEmployeeSelector'
import Loading from "src/helpers/commons/loader";

class EmployeeHierarchy extends BaseListing {
    constructor(props) {
        super(props, "employees-hierarchy");
        this.state = {
            isLoading: false,
            records: [],
            employee: "",
            downloadingChart: false,
            config: {},
            highlightPostNumbers: [1],
            branchId: 0,
            employeesWithPermission: [],
            employeeId: 0,
            employeeName: "",
        };
    }

    componentDidMount = () => {
        this.dropdownData('employeesWithPermission')
    };

    componentWillMount = () => {

    };

    loadEmployees(employee_id) {
        // Set loading flag
        this.setState({ isLoading: true });

        if (+employee_id > 0) {
            // Make the call
            wtApiCall(
                "employees-hierarchy",
                { employee_id: employee_id, parent_check: 0 },
                "get",
                (result) => {
                    this.setState({
                        records: result.data,
                        isLoading: false,
                    });
                },
                (result) => {
                    alert(result.message);
                    this.setState({ isLoading: false, records: [] });
                }
            );
        } else {
            this.setState({ isLoading: false, records: [] });
        }
    }

    handleDownload = () => {
        this.setState({ downloadingChart: false });
    };

    handleOnChangeConfig = (config) => {
        this.setState({ config: config });
    };

    handleLoadConfig = () => {
        const { config } = this.state;
        return config;
    };

    render() {
        const {
            employeesWithPermission,
            employeeId,
            employeeName,
            records,
            isLoading,
        } = this.state;

        if (isLoading) {
            return Loading;
        }

        document.getElementById("root").classList.add("root-class");
        document.getElementsByTagName("body")[0].classList.add("body-class");
        document.getElementsByTagName("html")[0].classList.add("html-class");

        const downloadImageId = "download-image";
        const downloadPdfId = "download-pdf";


        let svg = document.getElementById("svg");
        if (svg !== null) {
            svg.setAttribute("viewBox", "0 0 1745 569");
            svg.setAttribute("height", "100vh");
        }

        return (
            <React.Fragment>
                <div style={{ float: 'right',textAlign:'right' }}>
                    <img src="assets/wil-icon.png" alt="WilLogo" style={{ width: '100%' }} />
                </div>
                <div className="zoom-buttons">
                    <button
                        className="btn outline-primary btn-org btn-outline-primary-org zoom-button"
                        id="zoom-in"
                    >
                        +
                    </button>
                    <button
                        className="btn outline-primary btn-org btn-outline-primary-org zoom-button"
                        id="zoom-out"
                    >
                        -
                    </button>
                </div>
                <div className="zoom-buttons"></div>
                <div
                    style={{ width: "40%", position: "absolute", top: "1%", left: "30%" }}
                >
                    <WidgetEmployeeSelector
                        employees={employeesWithPermission}
                        displayValue={employeeName}
                        value={employeeId}
                        placeholder="Select Employee"
                        onChange={(values) => this.setState({ employeeId: values.value, employeeName: values.label }, () => this.loadEmployees(values.value))}
                        reloadDropdownData={() =>
                            this.dropdownData('employeesWithPermission')
                        }
                    />
                </div>

                <div
                    style={{
                        width: "10%",
                        position: "absolute",
                        top: "1%",
                        right: "27%",
                    }}
                >
                </div>

                {records.length > 0 && (
                    <OrgChart
                        tree={records[0]}
                        downloadImageId={downloadImageId}
                        downloadPdfId={downloadPdfId}
                        onConfigChange={(config) => {
                            this.handleOnChangeConfig(config);
                        }}
                        loadConfig={(d) => {
                            let configuration = this.handleLoadConfig(d);
                            if (configuration) {
                                return configuration;
                            }
                        }}
                        downlowdedOrgChart={(d) => {
                            this.handleDownload();
                        }}
                        loadImage={(d) => {
                            return Promise.resolve("");
                        }}
                    />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currency: state.base_currency,
        branches: state.branches,
    };
};

export default connect(mapStateToProps)(EmployeeHierarchy);
