import React from 'react'
import WidgetSelector from './WidgetSelector'

export default function WidgetDepartmentSelector({
    value,
    displayValue,
    departments,
    onChange,
    reloadDropdownData,
    viewOnly,
    size,
}) {
    return (
        <React.Fragment>
            <WidgetSelector
                viewOnly={viewOnly || false}
                selectedValue={value}
                displayValue={displayValue}
                options={departments}
                onChange={(values) => onChange(values)}
                reloadDropdownData={() => reloadDropdownData()}
                placeHolder="Select Department"
                showCloudIcon={true}
                size={size}
            />
        </React.Fragment>
    )
}
