import React from 'react'

import HttpHelper from './helpers/httpHelper'
import { wtHrefHashGetVars, wtHrefHashSetVars } from './helpers/commons/HrefHash'
import { wtApiCall } from './helpers/commons/ApiWrapper'
import {
  lzNotifyErrorMessageFromResult,
  lzNotifySuccessMessage,
} from './helpers/commons/notifications'
import { wtGetFromLocalStorage } from './helpers/commons/LocalStorage'

class BaseListing extends React.Component {
  /**
   * Helper for centralized api calls through http.
   */
  httpRequest = new HttpHelper()

  /**
   * The entity name to be used for api calling.
   * Must be a plural kebab-case word.
   * For e.g. companies, company-users, etc.
   */
  entityName = ''

  /**
   * The entity model class reference to create model class objects and arrays if required.
   * This should be a model class and it must have a static parseArray() function defined in it.
   * For e.g. CompanyModel, CompanyUserModel, etc.
   */
  entityModelClassRef = null

  state = {}

  /**
   * Constructor used to initialize the entity component.
   *
   * @param {*} props
   * @param string entityName
   * @param reference entityModelClassRef
   */
  constructor(entityName, state = {}, props, entityModelClassRef) {
    super(props)
    this.extraListCallParams = {} // can be used in child classes at runtime to send extra params to list api call
    this.entityName = entityName
    this.entityModelClassRef = entityModelClassRef
    this.state = state;

    // Initialize rights object
    const authUser = wtGetFromLocalStorage('authUser', null)
    this.is_super = authUser ? authUser.is_super : 0
  }

  /**
   * Load action from url hash.
   * Used to call actions from the url hash value.
   * Override in child class if needed.
   */
  loadActionFromHash(setState) {

    // Get hash params and check if something is set
    const hashParams = wtHrefHashGetVars()
    if (hashParams) {
      // Set special modal actions
      if (hashParams.add) {
        this.state.isAdding = true
      } else if (hashParams.view) {
        this.state.viewId = hashParams.view
      } else if (hashParams.edit) {
        this.state.editId = hashParams.edit
      } else if (hashParams.config) {
        this.state.configId = hashParams.config
      } else if (hashParams.other) {
        this.state.otherId = hashParams.other
      }
      else {
        this.state = { isAdding: false, editId: 0, viewId: 0 }
      }
      setState(this.state)
    } else {
      setState(this.state)
    }
  }

  /**
   * Method to load listing.
   * Override in child class if needed.
   */
  loadList(setPageNo) {
    const { pageNo } = this.state

    // Avoid extra calls
    if (setPageNo === pageNo) {
      return
    }

    // Set loading flag
    this.setState({ isLoading: true })

    // Make the call
    wtApiCall(
      this.entityName,
      {
        ...this.extraListCallParams,
        _page_index: setPageNo ? setPageNo : pageNo,
      },
      'get',
      (result) => {
        this.setState({
          records: this.entityModelClassRef.parseArray(result.data.data),
          isLoading: false,
          pageNo: result.data.current_page,
          totalPages: result.data.last_page,
        })
      },
      (result) => {
        lzNotifyErrorMessageFromResult(result)
        this.setState({ isLoading: false })
      },
    )
  }

  /**
   * Load a record using api call.
   * Override in child class if needed.
   *
   * @param id Record id to load.
   * @param {*} extraCallParams Use it to add extra call params in the api call.
   */
  loadRecord(id, extraCallParams) {
    this.setState({ isLoading: true })
    wtApiCall(
      this.entityName + '/' + id,
      extraCallParams,
      'get',
      (result) => {
        this.setState({ record: new this.entityModelClassRef(result.data), isLoading: false })
      },
      (result) => {
        lzNotifyErrorMessageFromResult(result)
        this.setState({ isLoading: false })
      },
    )
  }

  /**
   * Show delete confirmation dialog and then call delete if opted.
   * Override in child class if needed.
   *
   * @param int id Record id to be deleted.
   */
  confirmDeleteRecord(id) {
    if (window.confirm('Are you sure you want to delete this record?')) {
      this.deleteRecord(id)
    }
  }

  /**
   * Show delete confirmation dialog and then call delete if opted.
   * Override in child class if needed.
   *
   * @param int id Record id to be deleted.
   */
  deleteRecord(id) {
    this.setState({ isLoading: true })

    wtApiCall(
      this.entityName + '/' + id,
      {},
      'delete',
      (result) => {
        lzNotifySuccessMessage('Deleted Successfully')
        this.loadList()
      },
      (result) => {
        lzNotifyErrorMessageFromResult(result)
        this.setState({ isLoading: false })
      },
    )
  }

  deleteData(entity, id, callBackFunction, extraCallParams = {}) {
    if (window.confirm('Are you sure you want to delete this record?')) {
      wtApiCall(
        entity + '/' + id,
        extraCallParams,
        'delete',
        (response) => {
          lzNotifySuccessMessage(response.data)
          callBackFunction()
        },
        (error) => {
          lzNotifyErrorMessageFromResult(error)
        },
      )
    }
  }

  /**
   *
   * @param {*} params
   */
  setHashAction(params, setState = null) {
    wtHrefHashSetVars(params)
    if (setState) {
      this.loadActionFromHash(setState)
    }
  }

  dropdownData(dropdownNames, extraCallParams = {}, states, isFunctional = 0) {
    Object.assign(extraCallParams, { dropdown_names: dropdownNames })
    wtApiCall(
      'dropdown',
      extraCallParams,
      'get',
      (result) => {
        if (result != null) {
          if (result.data.length > 0) {
            let dropdowns = dropdownNames.split(',')
            for (let i = 0; i < result.data.length; i++) {
              let dropdownName = dropdowns[i]
              if (isFunctional) {
                states[i](result.data[i][dropdownName])
              } else {
                this.setState({ [dropdownName]: result.data[i][dropdownName] })
              }
            }
          }
        }
      },
      (result) => {
        lzNotifyErrorMessageFromResult(result)
      },
    )
  }

  hasRights(name) {
    const userRights = wtGetFromLocalStorage('rights')
    if (Array.isArray(name)) {
      let isFounded = userRights.some(ai => name.includes(ai));
      return isFounded;
    }
    else {
      return userRights.includes(name)
    }
  }
}

export default BaseListing
