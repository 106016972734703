import React, { useEffect, useState } from 'react'
import './Prints.css'
import { useSearchParams } from 'react-router-dom'
import { wtApiCall } from '../../helpers/commons/ApiWrapper'
import Loading from 'src/helpers/commons/loader'
const Print = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')
    const [orderData, setOrderData] = useState([])

    var sub_total=0;
    const getData = () => {
        wtApiCall(
            'orders/' + id,
            {},
            'get',
            (response) => {
                // console.log(response.data)
                setOrderData(response.data)
                
                setIsLoading(false)          
                
            },
            (error) => {
                lzNotifyErrorMessageFromResult(error)
            },
        )
    }

   

    useEffect(() => {
        getData()
        

    }, [])
    useEffect(() => {
       
        const delayTime = 1000;// Adjust the delay time as needed
        const printTimer = setTimeout(() => {
            window.print();
        }, delayTime);
        return () => clearTimeout(printTimer);
      }, [isLoading, orderData]);
    
    



    if (isLoading) {
        return Loading
    }


    
    return (
        <div className='printView'>
            <div className='rps_logo' >
                <img 
                    className='rps_logo'
                    src={orderData.rps_company_logo}
                />
            </div>

            {/* <div className='Details' >
                <p>
                    This is a print of test our order invoice
                   
                </p>
            </div> */}
            <div className='bill_no' >
                <h5>Bill # :  {orderData.rps_id}</h5>
            </div>
            <p><strong>Type :</strong> Cash</p>
            <p><strong>Date :</strong> {orderData.date}</p>
            <table className='order_table'>
                <thead>
                    <tr>
                        <th>Code</th>
                        <th className='itemsname'>Item</th>
                        <th>UOM</th>
                        <th>Qty</th>
                        <th>Total</th>
                    </tr>
                </thead>

                <tbody>


                    {orderData.order_details.map((item) => <tr>
                        <td>{item.item_code}</td>
                        <td>{item.item_name}</td>
                        <td>{item.unit_of_measure}</td>
                        <td>{item.quantity}</td>
                        <td>{item.total_amount}</td>
                        <td style={{display:'none'}}>{sub_total+= item.total_amount}</td>
                            
                        
                    </tr>
                    
                    )
                    }

                </tbody>

                <tr style={{ borderTop: '1px solid black' }}>
                    <td style={{textAlign:'center'}} className='fnt'  colSpan={4}>Total Amount</td>


                    <td className='fnt'>{orderData.rps_currency_symbol}  {sub_total}</td>
                </tr>
                <tr>
                    <td style={{textAlign:'center'}} className='fnt' colSpan={4}>Amount Paid</td>


                    <td className='fnt'>{orderData.rps_currency_symbol}  {orderData.amount_paid}</td>
                </tr>
                <tr style={{ borderBottom: '1px solid black' }}>
                    <td  style={{textAlign:'center'}} className='fnt' colSpan={4}>Amount Return</td>


                    <td className='fnt'>{orderData.rps_currency_symbol}  {orderData.amount_paid-sub_total}</td>
                </tr>



            </table>
            <p>Thanks For Purchase !</p>
            <p><strong>Terms And Condition :</strong> {orderData.terms_and_conditions}</p>

        </div>
    )
}
export default Print



