import React, { useState } from 'react'
import { CCol, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
// import './table.css'
import { wtRoundNumberToSpecifedDigits } from 'src/helpers/commons/utils'
import { Link } from 'react-router-dom'
import { wtConvertHourValue, wtGetMonthNameYearFormat } from 'src/helpers/commons/moment'

import BaseListing from 'src/BaseListings'

export default function DataReportTable({ reportData, setReportData, dates }) {
  const Base = new BaseListing()

  const [sortedKey, setSortedKey] = useState('')
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc',
  })

  const sortTable = (key) => {
    let direction = 'asc'
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    const sorted = [...reportData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1
      }
      return 0
    })
    setReportData(sorted)
    setSortedKey(key)
    setSortConfig({ key, direction })
  }


  return (
    <>
      <thead class="thead-dark" style={{ backgroundColor: '#e8e9eb' }}>
        <tr>
          <th onClick={() => sortTable('id')}>Sr</th>
          <th onClick={() => sortTable('id')}>
            ID{' '}
            {sortedKey === 'id' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('full_name')}>
            Full Name
            {sortedKey === 'full_name' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>

          <th onClick={() => sortTable('designations')}>
            Designation
            {sortedKey === 'designations' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('depatments')}>
            Department
            {sortedKey === 'depatments' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('created_at')}>
            Evaluation Month
            {sortedKey === 'created_at' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('ratings')}>
            Evaluation 
            {sortedKey === 'ratings' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('recommended_ratings')}>
          Evaluation Recommendation
            {sortedKey === 'recommended_ratings' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          
          
          
        </tr>
      </thead>
      <tbody>
        {reportData.map((item, index) => (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td style={{ color: 'blue' }}>
              <Link
                to={`/employee-profile?id=${item.employee_id}&tab=emp_evaluation`}
                target="_blank"
              >
                {item.employee_id}
              </Link>
            </td>
            <td style={{ color: 'blue' }}>
              <Link
                to={`/employee-profile?id=${item.employee_id}&tab=emp_evaluation`}
                target="_blank"
              >
                {item.employee.name}
              </Link>
            </td>
            <td>{item.employee.designation.name}</td>
            <td>{item.employee.department.name}</td>
            <td>{wtGetMonthNameYearFormat(item.created_at)}</td>
            <td style={{ color: item.ratings ? 'blue' : 'red' }}>
            {Base.hasRights('Show Employee Performance Evaluation Report Detail') ? (
              item.ratings ? `${item.max_rating}/${item.total_ratings} By ${item.created_by.name}` : 'Pending'
            ) : (
              item.ratings ? 'Completed' : 'Pending'
            )}               
          </td>


          <td style={{ color: item.recommended_ratings ? 'blue' : 'red' }}>
            {Base.hasRights('Show Employee Performance Evaluation Report Detail') ? (
              item.recommended_ratings ? item.max_rating +'/'+ item.recommended_total_ratings +' By '+ item.recommended_by.name  : 'Pending'
            ) : (
              item.recommended_ratings ? 'Completed' : 'Pending'
            )}               
          </td>
            



          </tr>
        ))}
      </tbody>
    </>
  )
}
