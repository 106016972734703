import BankTransactionPDF from "src/PDF/BankTransactionPDF";
import VoucherPDF from "src/PDF/VoucherPDF";
import { wtApiCall } from "src/helpers/commons/ApiWrapper";
import { lzNotifyErrorMessageFromResult } from "src/helpers/commons/notifications";

// Source Types
export const SOURCE_TYPE_DEFAULT = 1
export const SOURCE_TYPE_BANK_TRANSACTIONS = 2

export const SOURCE_TYPE_NAMES = {
    1: 'Default',
    2: 'Bank Transactions',
}

// Voucher Types
export const VOUCHER_TYPE_BANK_PAYMENT_VOUCHER = 1
export const VOUCHER_TYPE_BANK_RECEIPT_VOUCHER = 2;
export const VOUCHER_TYPE_CASH_PAYMENT_VOUCHER = 3;
export const VOUCHER_TYPE_CASH_RECEIPT_VOUCHER = 4;
export const VOUCHER_TYPE_JOURNAL_VOUCHER = 5;
export const VOUCHER_TYPE_INTER_BANK_TRANSFER_VOUCHER = 6;
export const VOUCHER_TYPE_CASH_DEPOSIT_VOUCHER = 7;
export const VOUCHER_TYPE_CASH_WITHDRAWAL_VOUCHER = 8;

export const VOUCHER_TYPE_NAMES = {
    1: 'Bank Payment Voucher',
    2: 'Bank Receipt Voucher',
    3: 'Cash Payment Voucher',
    4: 'Cash Receipt Voucher',
    5: 'Journal Voucher',
    6: 'Inter Bank Transfer Voucher',
    7: 'Cash Deposit Voucher',
    8: 'Cash Withdrawal Voucher',
};

export const VOUCHER_TYPE_SHORT_NAMES = {
    1: 'BPV',
    2: 'BRV',
    3: 'CPV',
    4: 'CRV',
    5: 'JV',
    6: 'IBTV',
    7: 'CDV',
    8: 'CWV',
};

export const VOUCHER_TYPE_DROPDOWN_OPTIONS = [
    { value: VOUCHER_TYPE_BANK_PAYMENT_VOUCHER, label: VOUCHER_TYPE_NAMES[VOUCHER_TYPE_BANK_PAYMENT_VOUCHER] },
    { value: VOUCHER_TYPE_BANK_RECEIPT_VOUCHER, label: VOUCHER_TYPE_NAMES[VOUCHER_TYPE_BANK_RECEIPT_VOUCHER] },
    { value: VOUCHER_TYPE_CASH_PAYMENT_VOUCHER, label: VOUCHER_TYPE_NAMES[VOUCHER_TYPE_CASH_PAYMENT_VOUCHER] },
    { value: VOUCHER_TYPE_CASH_RECEIPT_VOUCHER, label: VOUCHER_TYPE_NAMES[VOUCHER_TYPE_CASH_RECEIPT_VOUCHER] },
    { value: VOUCHER_TYPE_JOURNAL_VOUCHER, label: VOUCHER_TYPE_NAMES[VOUCHER_TYPE_JOURNAL_VOUCHER] },
];


// voucher status
export const STATUS_JUST_PLACED = 1;
export const STATUS_APPROVED = 2;
export const STATUS_POSTED = 3;
export const STATUS_CANCELLED = 4;

export const STATUS_NAME = {
    1: 'Just Placed',
    2: 'Approved',
    3: 'Posted',
    4: 'Cancelled',
};

export const VOUCHER_STATUS_DROPDOWN_OPTIONS = [
    { value: STATUS_JUST_PLACED, label: STATUS_NAME[STATUS_JUST_PLACED] },
    { value: STATUS_APPROVED, label: STATUS_NAME[STATUS_APPROVED] },
    { value: STATUS_POSTED, label: STATUS_NAME[STATUS_POSTED] },
    { value: STATUS_CANCELLED, label: STATUS_NAME[STATUS_CANCELLED] },
];

export const generateVoucher = (id) => {
    wtApiCall(
        'vouchers/' + id,
        {},
        'get',
        (response) => {
            const { data } = response
            const { voucher } = data;
            if (voucher.source_type === SOURCE_TYPE_DEFAULT) {
                VoucherPDF(data)
            }
            else if (voucher.source_type === SOURCE_TYPE_BANK_TRANSACTIONS) {
                BankTransactionPDF(data)
            }
        },
        (error) => {
            lzNotifyErrorMessageFromResult(error)
        },
    )
}

