import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import { wtGetMonthNameYearFormat } from 'src/helpers/commons/moment'
import { wtRoundNumberToSpecifedDigits } from 'src/helpers/commons/utils'
import QRCode from 'qrcode'
import { FRONT_BASE_URL } from 'src/config/Request'

export const OvertimeFormPDF = (record, monthYear, isLockedForm = 0, reportView = 0) => {
  const bodyRows = (data) => {
    var body = []
    if (isLockedForm === 0) {
      data.map((item) => {
        body.push([
          item.attendance_date,
          moment(item.attendance_date).format('dddd'),
          item.updated_check_in ? item.updated_check_in : item.check_in,
          item.updated_check_out ? item.updated_check_out : item.check_out,
          wtRoundNumberToSpecifedDigits(
            item.updated_working_hours ? item.updated_working_hours : item.working_hours,
          ),
          wtRoundNumberToSpecifedDigits(item.overtime_hours),
          wtRoundNumberToSpecifedDigits(item.is_overtime_approved ? item.updated_overtime_hours : 0),
          item.updated_type ? item.updated_type : item.type,
        ])
      })
    }
    else {
      data.map((item) => {
        body.push([
          item.attendance_date,
          moment(item.attendance_date).format('dddd'),
          item.check_in ? item.check_in : '',
          item.check_out ? item.check_out : '',
          wtRoundNumberToSpecifedDigits(
            item.working_hours ? item.working_hours : '',
          ),
          wtRoundNumberToSpecifedDigits(item.overtime_hours),
          wtRoundNumberToSpecifedDigits(item.is_overtime_approved ? item.overtime_hours : 0),
          item.type ? item.type : '',
        ])
      })
    }
    return body
  }
  const watermarkText = 'DRAFT'
  const watermarkFontSize = 100

  const doc = new jsPDF()

  const pageWidth = doc.internal.pageSize.width
  const logo = 'assets/wil-icon.png'
  let yPosition = 53

  record.map((pdfData) => {

    if (isLockedForm === 0) {
      doc.setTextColor(255, 145, 145) // Set the color of the watermark text
      doc.setFont('Helvetica', 'bold') //Header middle branch logo
      doc.setFontSize(watermarkFontSize)
      doc.text(130, 200, watermarkText, null, 45, 'center')
    }

    if (pdfData.cancelled_at !== 0 && reportView === 0) {
      doc.setTextColor(255, 145, 145) // Set the color of the watermark text
      doc.setFont('Helvetica', 'bold') //Header middle branch logo
      doc.setFontSize(watermarkFontSize)
      doc.text(140, 200, "CANCELLED", null, 45, 'center')
    }

    doc.setTextColor('#3c4b64')
    doc.setFont('Helvetica', 'bold') //Header middle branch logo

    doc.addImage(logo, 'PNG', pageWidth - 200, 4, 25, 25)
    doc.line(pageWidth - 173, 7, pageWidth - 173, 27) // Adjust the coordinates as needed

    doc.setFontSize(11)
    doc.text('WAH', pageWidth - 171, 10, { align: 'left' })
    doc.setFontSize(11)
    doc.setFont('Helvetica')
    doc.text('INDUSTRIES', pageWidth - 171, 15)
    doc.text('LIMITED', pageWidth - 171, 20)
    doc.setFontSize(9)
    doc.text('www.wahindustries.com', pageWidth - 171, 25)

    doc.setTextColor('#000000') // Set text color to black
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(12)
    const label = 'OVERTIME FORM'
    doc.text(label, 150, 10, { align: 'left' })

    const qrCodeSize = 24
    const qrCodeX = (pageWidth - qrCodeSize) / 2
    const qrCodeY = 4

    const qrCodeText = FRONT_BASE_URL + 'employee-profile?id=1' + pdfData.id
    QRCode.toDataURL(qrCodeText, { width: qrCodeSize }, (err, url) => {
      if (err) {
        console.error(err)
      } else {
        doc.addImage(url, 'PNG', qrCodeX, qrCodeY, qrCodeSize, qrCodeSize)
      }
    })

    doc.setFont('helvetica', 'normal')
    doc.setTextColor('Black')

    doc.setFontSize(12)

    doc.text(`ID:`, 16, 34, { align: 'left' })
    doc.text(`${pdfData.id ? pdfData.id : ''}`, 25, 34, { align: 'left' })

    const textWidth = 23
    function drawLine(x1, y1, x2, y2) {
      doc.moveTo(x1, y1).lineTo(x2, y2).stroke()
    }

    const lineHeight = 1
    drawLine(23, 34 + lineHeight, 23 + textWidth, 34 + lineHeight)

    doc.text('Personal File No:', 47, 34, { align: 'left' })
    doc.text(`${pdfData.personal_file_no ? pdfData.personal_file_no : ''}`, 83, 34, {
      align: 'left',
    })

    const PersonalFileNoWidth = 34

    const lineStartX1 = 81
    const lineStartY1 = 34
    const lineEndX1 = lineStartX1 + PersonalFileNoWidth
    const lineEndY1 = lineStartY1

    drawLine(lineStartX1, lineStartY1 + lineHeight, lineEndX1, lineEndY1 + lineHeight)

    doc.text('Name:', 117, 34, { align: 'left' })
    doc.text(`${pdfData.full_name ? pdfData.full_name : ''}`, 133, 34, {
      align: 'left',
    })

    const NameWidth = 59

    const lineStartX = 132
    const lineStartY = 34
    const lineEndX = lineStartX + NameWidth
    const lineEndY = lineStartY

    drawLine(lineStartX, lineStartY + lineHeight, lineEndX, lineEndY + lineHeight)

    doc.text(`Designation:`, 16, 42, { align: 'left' })
    doc.text(`${pdfData.designation ? pdfData.designation.name : ''}`, 43, 42, {
      align: 'left',
    })

    const DesignationWidth = 54

    const DesignationLineStartX = 42
    const DesignationLineStartY = 42
    const DesignationLineEndX = DesignationLineStartX + DesignationWidth
    const DesignationLineEndY = DesignationLineStartY

    drawLine(
      DesignationLineStartX,
      DesignationLineStartY + lineHeight,
      DesignationLineEndX,
      DesignationLineEndY + lineHeight,
    )

    doc.text(`Department:`, 98, 42, { align: 'left' })
    doc.text(`${pdfData.department ? pdfData.department.name : ''}`, 125, 42, {
      align: 'left',
    })

    const DepartmentWidth = 72

    const DepartmentLineStartX = 123
    const DepartmentLineStartY = 42
    const DepartmentLineEndX = DepartmentLineStartX + DepartmentWidth
    const DepartmentLineEndY = DepartmentLineStartY

    drawLine(
      DepartmentLineStartX,
      DepartmentLineStartY + lineHeight,
      DepartmentLineEndX,
      DepartmentLineEndY + lineHeight,
    )

    doc.text(`Basic Pay:`, 16, 49, { align: 'left' })
    doc.text(`${pdfData.basic_pay ? pdfData.basic_pay.name : ''}`, 41, 49, {
      align: 'left',
    })

    const BasicPayWidth = 50

    const BasicPayLineStartX = 39
    const BasicPayLineStartY = 49
    const BasicPayLineEndX = BasicPayLineStartX + BasicPayWidth
    const BasicPayLineEndY = BasicPayLineStartY

    drawLine(
      BasicPayLineStartX,
      BasicPayLineStartY + lineHeight,
      BasicPayLineEndX,
      BasicPayLineEndY + lineHeight,
    )

    let Table1 = [
      [
        {
          content: 'Date',
          styles: { fontStyle: 'bold', halign: 'center', cellWidth: 25 },
        },
        { content: 'Day', styles: { fontStyle: 'bold', halign: 'center', cellWidth: 23 } },
        { content: 'Check In', styles: { fontStyle: 'bold', halign: 'center', cellWidth: 18 } },
        { content: 'Check Out', styles: { fontStyle: 'bold', halign: 'center', cellWidth: 18 } },
        {
          content: 'Working Hours',
          styles: { fontStyle: 'bold', halign: 'center', cellWidth: 26 },
        },
        {
          content: 'Overtime Hours',
          styles: { fontStyle: 'bold', halign: 'center', cellWidth: 26 },
        },
        {
          content: 'Approved Overtime Hours',
          styles: { fontStyle: 'bold', halign: 'center', cellWidth: 20 },
        },
        {
          content: 'Attendance Type',
          styles: { fontStyle: 'bold', halign: 'center', cellWidth: 26 },
        },
      ],

      // ['2024-02-01', 'Monday', '2', 'Present'],
      // ['2024-02-02', 'Tuesday', '3', 'Present'],
    ]

    doc.autoTable({
      startY: yPosition,
      margin: { top: 20 },
      head: Table1,
      body: bodyRows(pdfData.attendances),
      foot: [
        [
          {
            content: 'Total Overtime',
            colSpan: 6,
            styles: { halign: 'center', fontStyle: 'bold' },
          },
          {
            content: wtRoundNumberToSpecifedDigits(pdfData.total_overtime_hours),
            styles: { textColor: 'blue' },
          },
        ],
      ],
      theme: 'plain',
      tableLineColor: '#3c4b64',
      tableLineWidth: 0.2,
      tableWidth: 'auto',
      headStyles: { fillColor: [217, 218, 219] },
      styles: {
        lineColor: '#3c4b64',
        lineWidth: 0.2,
        halign: 'center',
        font: 'helvetica',
        cellPadding: { top: 0.7, right: 0.7, bottom: 0.7, left: 0.7 },
      },
      didDrawPage: function (data) { },
    })

    const Table2 = [
      [
        {
          content: 'For Accounts Department',
          styles: { halign: 'center', fontStyle: 'bold', fontSize: 10 },
        },
      ],

      [
        {
          content:
            'Rate (Rs) _________________________________\tAmount (Rs) __________________________________',
          styles: { halign: 'left' },
        },
      ],
      [
        {
          content:
            'In Words __________________________________________________________________________________',
          styles: { halign: 'left' },
        },
      ],
    ]

    const Table3 = [
      [
        {
          content: '_______________________\t\t\t\t\t\t\t\t\t\t\t  ______________________',
          styles: { halign: 'left', fontStyle: 'bold' },
        },
      ],

      [
        {
          content: '\tSr. Manager F&A\t\t\t\t\t\t\t\t\t\t\t\t\t    CFO & Secy WIL',
          styles: { halign: 'left', fontStyle: 'bold' },
        },
      ],
      [
        {
          content: ' _______________________',
          styles: { halign: 'center', fontStyle: 'bold' },
        },
      ],

      [
        {
          content: ' CEO & MD-WIL',
          styles: { halign: 'center', fontStyle: 'bold' },
        },
      ],
    ]

    const gapBetweenTables = 2
    const startYForTable12 = doc.autoTable.previous.finalY + gapBetweenTables
    doc.autoTable({
      startY: startYForTable12,
      margin: { top: 20 },
      body: Table2, // Body rows for second table
      theme: 'plain',
      tableLineColor: '#ffffff',
      tableLineWidth: 0,
      tableWidth: 'auto', // Set table width automatically
      styles: {
        lineColor: '#ffffff',
        lineWidth: 0,
        halign: 'center',
        font: 'helvetica',
        cellPadding: { top: 0.5, right: 0.5, bottom: 0.5, left: 0.5 },
      },
      didDrawPage: function (data) { },
    })

    const gapBetweenTable23 = 4
    const startYForTable23 = doc.autoTable.previous.finalY + gapBetweenTable23
    doc.autoTable({
      startY: startYForTable23,
      margin: { top: 20 },
      body: Table3, // Body rows for second table
      theme: 'plain',
      tableLineColor: '#ffffff',
      tableLineWidth: 0,
      tableWidth: 'auto', // Set table width automatically
      styles: {
        lineColor: '#ffffff',
        lineWidth: 0,
        halign: 'center',
        font: 'helvetica',
        cellPadding: { top: 0.1, right: 0.1, bottom: 0.1, left: 0.1 },
      },
      didDrawPage: function (data) { },
    })
    doc.addPage()
  })

  doc.save('OvertimeForm.pdf')
}
