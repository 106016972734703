import React, { useState } from 'react'
import { CCol, CRow } from '@coreui/react'
import './table.css'
import { wtRoundNumberToSpecifedDigits } from 'src/helpers/commons/utils'
export default function VehicleLogReportDataTable({ reportData, setReportData}) {
  const [sortedKey, setSortedKey] = useState('')
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc',
  })
  const sortTable = (key) => {
    let direction = 'asc'
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    const sorted = [...reportData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1
      }
      return 0
    })
    setReportData(sorted)
    setSortedKey(key)
    setSortConfig({ key, direction })
  }

  return (
    <CRow>
      <CCol md={12}>
        <table className="table table-bordered data-table">
          <thead class="thead-dark" style={{ backgroundColor: '#e8e9eb' }}>
            <tr>
              <th onClick={() => sortTable('id')}>Sr</th>
              <th onClick={() => sortTable('vehicle_no')}>Vehicle No</th>
              <th onClick={() => sortTable('date')}>Date</th>
              <th onClick={() => sortTable('departments')}>Departments</th>
              <th onClick={() => sortTable('employees')}>Employees</th>
              <th onClick={() => sortTable('drivers')}>Drivers</th>
              <th onClick={() => sortTable('milage')}>Milage</th>
              <th onClick={() => sortTable('current_reading')}>Current Reading</th>
              <th onClick={() => sortTable('previous_reading')}>Previous Reading</th>
            </tr>
          </thead>
          <tbody>
            {reportData.map((item, index) => (
    <tr key={item.id}>

        <td>{index + 1}</td>
        <td>{item.vehicle.vehicle_no}</td>
        <td>{item.date}</td>
        <td>
        {item.departments.map((department, index) => (
                <span key={index}>{department.department.name}
                {index !== item.departments.length - 1 && ", "}
                </span> 
            ))}
        </td>
        <td>
            {item.employees.map((employee, index) => (
                <span key={index}>
                    {employee.employee.full_name} 
                    {index !== item.employees.length - 1 && ", "} 
                
                </span>
            ))}
        </td>
        <td>
            {item.drivers.map((employee, index) => (
                <span key={index}>
                    {employee.employee.full_name} 
                    {index !== item.employees.length - 1 && ", "} 
                
                </span>
            ))}
        </td>
        <td>{item.milage}</td>
        <td>{item.current_reading}</td>
        <td>{item.previous_reading}</td>
    </tr>
))}

          </tbody>
        </table>
      </CCol>
    </CRow>
  )
}
