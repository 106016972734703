import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { wtGetDateMonthYearFormat, wtGetDateTimeFormat, wtGetMonthNameYearFormat } from 'src/helpers/commons/moment'
import { performanceEvaluationRatingRemarks } from 'src/helpers/commons/dropdownOptions'
const StaffOutletTemplatePDF = (pdfData) => {
  const doc = new jsPDF()
  const logo = 'assets/wil-icon.png'
  let yPosition = 45

  let performanceRating = null
  const { approved_ratings, recommended_ratings, ratings, max_rating } = pdfData
  if (pdfData.approved_at !== null) {
    performanceRating = approved_ratings
  } else if (pdfData.recommended_ratings !== null) {
    performanceRating = recommended_ratings
  } else {
    performanceRating = ratings
  }

  performanceRating = JSON.parse(performanceRating)

  const pageWidth = doc.internal.pageSize.width
  doc.setTextColor('#0501ff')
  doc.setFont('Helvetica', 'bold')
  doc.setFontSize(18)
  doc.text(`PERFORMANCE EVALUATION FORM`, pageWidth - 103, 40, { align: 'center' })

  const addLogoToAllPages = () => {
    const pageCount = doc.internal.getNumberOfPages()
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      doc.setTextColor('#3c4b64')
      doc.setFont('Helvetica', 'bold')

      const pageWidth = doc.internal.pageSize.width
      doc.setTextColor('#3c4b64')
      doc.setFont('Helvetica', 'bold') //Header middle branch logo

      doc.addImage(logo, 'PNG', pageWidth - 200, 4, 25, 25)
      doc.line(pageWidth - 173, 7, pageWidth - 173, 27) // Adjust the coordinates as needed

      doc.setFontSize(11)
      doc.text('WAH', pageWidth - 171, 10, { align: 'left' })
      doc.setFontSize(11)
      doc.setFont('Helvetica')
      doc.text('INDUSTRIES', pageWidth - 171, 15)
      doc.text('LIMITED', pageWidth - 171, 20)
      doc.setFontSize(9)
      doc.text('www.wahindustries.com', pageWidth - 171, 25)
    }
  }

  addLogoToAllPages()

  const getTotalRating = (performanceRating) => {
    const {
      punctuality,
      devotion_to_duty,
      team_spirit,
      client_handling,
      successful_leads,
      complaint_handling,
      customer_services,
      maintaining_of_record,
      maintenance_of_outlets,
      display_of_products,
    } = performanceRating
    let total =
      parseInt(punctuality) +
      parseInt(devotion_to_duty) +
      parseInt(team_spirit) +
      parseInt(client_handling) +
      parseInt(successful_leads) +
      parseInt(complaint_handling) +
      parseInt(customer_services) +
      parseInt(maintaining_of_record) +
      parseInt(maintenance_of_outlets) +
      parseInt(display_of_products)
    return total
  }

  const getTotalPercentage = () => {
    const totalRating = getTotalRating(performanceRating)
    const percentage = (totalRating / max_rating) * 100
    return percentage.toFixed(2) // Limit to two decimal places
  }

  const tableData1 = [
    [
      {
        content: 'Name of Employee:',
        styles: { fontStyle: 'bold', cellWidth: 45, halign: 'left' },
      },
      {
        content: pdfData.employee.full_name,
        styles: { cellWidth: 45, halign: 'left', fontStyle: 'normal' },
      },
      {
        content: 'Personal File No:',
        styles: { fontStyle: 'bold', cellWidth: 46, halign: 'left' },
      },
      {
        content: pdfData.employee.personal_file_no,
        styles: { cellWidth: 46, halign: 'left', fontStyle: 'normal' },
      },
    ],
    [
      { content: 'Designation:', styles: { fontStyle: 'bold', cellWidth: 45, halign: 'left' } },
      {
        content: pdfData.employee.designation.name,
        styles: { cellWidth: 45, halign: 'left', fontStyle: 'normal' },
      },
      { content: 'Department:', styles: { fontStyle: 'bold', cellWidth: 46, halign: 'left' } },
      {
        content: pdfData.employee.department.name,
        styles: { cellWidth: 46, halign: 'left', fontStyle: 'normal' },
      },
    ],
    [
      {
        content: 'Date of Joining :',
        styles: { fontStyle: 'bold', cellWidth: 45, halign: 'left' },
      },
      {
        content: wtGetDateMonthYearFormat(pdfData.employee.date_of_joining),
        styles: { cellWidth: 45, halign: 'left', fontStyle: 'normal' },
      },
      {
        content: 'Evaluation Month:',
        styles: { fontStyle: 'bold', cellWidth: 46, halign: 'left' },
      },
      {
        content: wtGetMonthNameYearFormat(pdfData.created_at),
        styles: { cellWidth: 46, halign: 'left', fontStyle: 'normal' },
      },
    ],
  ]
  const tableData2 = [
    [
      {
        content: 'Misc. Aspects',
        styles: { fontStyle: 'bold', halign: 'center', textColor: '#0e2d62' },
      },
      {
        content: 'Rating Factors',
        styles: { fontStyle: 'bold', halign: 'center', textColor: '#0e2d62' },
      },
      { content: 'Rating', styles: { fontStyle: 'bold', halign: 'center', textColor: '#0e2d62' } },
    ],
    [
      {
        content: 'Punctuality',
        styles: { halign: 'left', fontSize: 10, fontStyle: 'plain' },
      },
      {
        content: performanceEvaluationRatingRemarks[performanceRating.punctuality],
        styles: { halign: 'left', fontSize: 10, fontStyle: 'plain', textColor: '#0501ff' },
      },
      {
        content: `${performanceRating.punctuality}/10`,
        styles: {
          halign: 'center',
          fontSize: 10,
          fontStyle: 'plain',
          textColor: '#0501ff',
        },
      },
    ],
    [
      {
        content: 'Devotion to Duty',
        styles: { halign: 'left', fontSize: 10 },
      },
      {
        content: performanceEvaluationRatingRemarks[performanceRating.devotion_to_duty],
        styles: { halign: 'left', fontSize: 10, fontStyle: 'plain', textColor: '#0501ff' },
      },
      {
        content: `${performanceRating.devotion_to_duty}/10`,
        styles: {
          halign: 'center',
          fontSize: 10,
          fontStyle: 'plain',
          textColor: '#0501ff',
        },
      },
    ],
    [
      {
        content: 'Team Spirit',
        styles: { halign: 'left', fontSize: 10 },
      },
      {
        content: performanceEvaluationRatingRemarks[performanceRating.team_spirit],
        styles: { halign: 'left', fontSize: 10, fontStyle: 'plain', textColor: '#0501ff' },
      },
      {
        content: `${performanceRating.team_spirit}/10`,
        styles: {
          halign: 'center',
          fontSize: 10,
          fontStyle: 'plain',
          textColor: '#0501ff',
        },
      },
    ],
    [
      {
        content: 'Client handling',
        styles: { halign: 'left', fontSize: 10 },
      },
      {
        content: performanceEvaluationRatingRemarks[performanceRating.client_handling],
        styles: { halign: 'left', fontSize: 10, fontStyle: 'plain', textColor: '#0501ff' },
      },
      {
        content: `${performanceRating.client_handling}/10`,
        styles: {
          halign: 'center',
          fontSize: 10,
          fontStyle: 'plain',
          textColor: '#0501ff',
        },
      },
    ],
    [
      {
        content: 'Successful leads / orders',
        styles: { halign: 'left', fontSize: 10 },
      },
      {
        content: performanceEvaluationRatingRemarks[performanceRating.successful_leads],
        styles: { halign: 'left', fontSize: 10, fontStyle: 'plain', textColor: '#0501ff' },
      },
      {
        content: `${performanceRating.successful_leads}/10`,
        styles: {
          halign: 'center',
          fontSize: 10,
          fontStyle: 'plain',
          textColor: '#0501ff',
        },
      },
    ],
    [
      {
        content: 'Complaint handling',
        styles: { halign: 'left', fontSize: 10 },
      },
      {
        content: performanceEvaluationRatingRemarks[performanceRating.complaint_handling],
        styles: { halign: 'left', fontSize: 10, fontStyle: 'plain', textColor: '#0501ff' },
      },
      {
        content: `${performanceRating.complaint_handling}/10`,
        styles: {
          halign: 'center',
          fontSize: 10,
          fontStyle: 'plain',
          textColor: '#0501ff',
        },
      },
    ],
    [
      {
        content: 'Customer Services',
        styles: { halign: 'left', fontSize: 10 },
      },
      {
        content: performanceEvaluationRatingRemarks[performanceRating.customer_services],
        styles: { halign: 'left', fontSize: 10, fontStyle: 'plain', textColor: '#0501ff' },
      },
      {
        content: `${performanceRating.customer_services}/10`,
        styles: {
          halign: 'center',
          fontSize: 10,
          fontStyle: 'plain',
          textColor: '#0501ff',
        },
      },
    ],
    [
      {
        content: 'Closing/maintaining of record',
        styles: { halign: 'left', fontSize: 10 },
      },
      {
        content: performanceEvaluationRatingRemarks[performanceRating.maintaining_of_record],
        styles: { halign: 'left', fontSize: 10, fontStyle: 'plain', textColor: '#0501ff' },
      },
      {
        content: `${performanceRating.maintaining_of_record}/10`,
        styles: {
          halign: 'center',
          fontSize: 10,
          fontStyle: 'plain',
          textColor: '#0501ff',
        },
      },
    ],
    [
      {
        content: 'Maintenance of outlets',
        styles: { halign: 'left', fontSize: 10 },
      },
      {
        content: performanceEvaluationRatingRemarks[performanceRating.maintenance_of_outlets],
        styles: { halign: 'left', fontSize: 10, fontStyle: 'plain', textColor: '#0501ff' },
      },
      {
        content: `${performanceRating.maintenance_of_outlets}/10`,
        styles: {
          halign: 'center',
          fontSize: 10,
          fontStyle: 'plain',
          textColor: '#0501ff',
        },
      },
    ],
    [
      {
        content: 'Display of Products',
        styles: { halign: 'left', fontSize: 10 },
      },
      {
        content: performanceEvaluationRatingRemarks[performanceRating.display_of_products],
        styles: { halign: 'left', fontSize: 10, fontStyle: 'plain', textColor: '#0501ff' },
      },
      {
        content: `${performanceRating.display_of_products}/10`,
        styles: {
          halign: 'center',
          fontSize: 10,
          fontStyle: 'plain',
          textColor: '#0501ff',
        },
      },
    ],
    // Add other rows similar to the provided structure
    [
      {
        content: 'Overall Performance:',
        colSpan: 2,
        styles: { halign: 'right', fontStyle: 'bold' },
      },
      {
        content: `${getTotalRating(performanceRating)} / ${max_rating}`,
        styles: { textColor: '#008000' },
      },
    ],
    [
      { content: 'Percentage:', colSpan: 2, styles: { halign: 'right', fontStyle: 'bold' } },
      { content: `${getTotalPercentage()}%`, styles: { textColor: '#008000' } },
    ],
    [
      {
        content: `Remarks: ${pdfData.remarks}`,
        colSpan: 3,
        styles: { halign: 'left', fontStyle: 'bold' },
      },
    ],
  ]

  doc.autoTable({
    startY: yPosition,
    head: tableData1.slice(0, 2), // Header rows for first table
    body: tableData1.slice(2), // Body rows for first table
    theme: 'plain',
    tableLineColor: '#99bae6',
    tableLineWidth: 0.2,
    tableWidth: 'auto', // Set table width automatically
    styles: {
      lineColor: '#99bae6',
      lineWidth: 0.2,
      halign: 'center',
      font: 'helvetica',
    },
    didDrawPage: function (data) {},
  })
  const gapBetweenTable12 = 8
  const startYForTable12 = doc.autoTable.previous.finalY + gapBetweenTable12
  doc.autoTable({
    startY: startYForTable12,
    margin: { top: 20 },
    head: tableData2.slice(0, 2), // Header rows for second table
    body: tableData2.slice(2), // Body rows for second table
    margin: { top: 20 },
    theme: 'plain',
    tableLineColor: '#99bae6',
    tableLineWidth: 0.2,
    tableWidth: 'auto', // Set table width automatically
    headStyles: { fillColor: [237, 243, 253] },
    styles: {
      lineColor: '#99bae6',
      lineWidth: 0.2,
      halign: 'center',
      font: 'helvetica',
    },
    columnStyles: {
      0: { columnWidth: 100 },
      1: { columnWidth: 60 },
      2: { columnWidth: 22 },
    },
    didDrawPage: function (data) {},
    didParseCell: function (data) {
      const rowIndex = data.row.index
      const columnIndex = data.column.index
      if (
        rowIndex === 1 ||
        (rowIndex >= 2 && rowIndex <= 11 && columnIndex >= 0 && columnIndex <= 2)
      ) {
        data.cell.styles.fillColor = [255, 255, 255]
      }
    },
  })

  doc.setTextColor('#000000')
  doc.setFont('Helvetica', 'bold')
  doc.setFontSize(10)

  const PreparedBy = 'Evaluated By'
  const ApprovedBy = 'Approved By'
  const RecommendedBy = 'Recommended By'

  const PreparedByValue = pdfData.created_by.name ? pdfData.created_by.name : ''
  const ApprovedByValue = pdfData.approved_by ? pdfData.approved_by.name : ''
  const RecommendedByValue = pdfData.recommended_by ? pdfData.recommended_by.name : ''

  // Coordinates for PreparedBy
  const infoX = 16
  const infoY = 230

  // Coordinates for ApprovedBy
  const rightInfoX = 145
  const rightInfoY = 230

  // Coordinates for RecommendedBy
  const centerInfoX = 80
  const centerInfoY = 230

  const centerText = (text, x, width) => {
    const textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor
    const adjustedX = x + (width - textWidth) / 2
    return adjustedX
  }

  doc.setFontSize(9)

  // Display PreparedByValue above the line
  const lineYAbovePreparedBy = infoY - 4
  doc.setTextColor('#0501ff')
  doc.text(PreparedByValue, centerText(PreparedByValue, infoX, 50), lineYAbovePreparedBy)

  // Draw a horizontal line above PreparedBy details
  const lineYAbovePreparedByDetails = infoY - 3
  doc.setLineWidth(0.4)
  doc.line(infoX - 0, lineYAbovePreparedByDetails, infoX + 50, lineYAbovePreparedByDetails)

  doc.setTextColor('#000000')
  doc.text(PreparedBy, centerText(PreparedBy, infoX, 50), infoY)

  // Display ApprovedByValue above the line
  const lineYAboveRightInfo = rightInfoY - 4
  doc.setTextColor('#0501ff')
  doc.text(ApprovedByValue, centerText(ApprovedByValue, rightInfoX, 50), lineYAboveRightInfo)

  // Draw a horizontal line above right side details
  const lineYAboveRightInfoDetails = rightInfoY - 3
  doc.setLineWidth(0.4)
  doc.line(rightInfoX - 0, lineYAboveRightInfoDetails, rightInfoX + 50, lineYAboveRightInfoDetails)

  doc.setTextColor('#000000')
  doc.text(ApprovedBy, centerText(ApprovedBy, rightInfoX, 50), rightInfoY)

  // Display RecommendedByValue above the line
  const lineYAboveCenterInfo = centerInfoY - 4
  doc.setTextColor('#0501ff')
  doc.text(
    RecommendedByValue,
    centerText(RecommendedByValue, centerInfoX, 50),
    lineYAboveCenterInfo,
  )

  // Draw a horizontal line above center side details
  const lineYAboveCenterInfoDetails = centerInfoY - 3
  doc.setLineWidth(0.4)
  doc.line(
    centerInfoX - 0,
    lineYAboveCenterInfoDetails,
    centerInfoX + 50,
    lineYAboveCenterInfoDetails,
  )

  doc.setTextColor('#000000')
  doc.text(RecommendedBy, centerText(RecommendedBy, centerInfoX, 50), centerInfoY)

  ///////DATE TIME////////////

  const CreatedAtValue = wtGetDateTimeFormat(pdfData.created_at ? pdfData.created_at : '')
  const ApprovedAtValue = wtGetDateTimeFormat(pdfData.approved_at ? pdfData.approved_at : '')
  const RecommendedAtValue = wtGetDateTimeFormat(
    pdfData.recommended_at ? pdfData.recommended_at : '',
  )

  // Coordinates for CreatedAt
  const infoX1 = 16
  const infoY1 = 238

  // Coordinates for ApprovedAt
  const rightInfoX1 = 145
  const rightInfoY1 = 238

  // Coordinates for RecommendedAt
  const centerInfoX1 = 80
  const centerInfoY1 = 238

  const centerText1 = (text, x, width) => {
    const textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor
    const adjustedX = x + (width - textWidth) / 2
    return adjustedX
  }

  doc.setFontSize(9)

  // Display CreatedAtValue above the line
  const lineYAboveCreatedAt = infoY1 - 4
  doc.setTextColor('#0501ff')
  doc.text(CreatedAtValue, centerText1(CreatedAtValue, infoX1, 50), lineYAboveCreatedAt)

  // Display ApprovedAtValue above the line
  const lineYAboveRightInfo1 = rightInfoY1 - 4
  doc.setTextColor('#0501ff')
  doc.text(ApprovedAtValue, centerText1(ApprovedAtValue, rightInfoX1, 50), lineYAboveRightInfo1)

  // Display RecommendedAtValue above the line
  const lineYAboveCenterInfo1 = centerInfoY1 - 4
  doc.setTextColor('#0501ff')
  doc.text(
    RecommendedAtValue,
    centerText1(RecommendedAtValue, centerInfoX1, 50),
    lineYAboveCenterInfo1,
  )

  const addFooter = (doc) => {
    const pageCount = doc.internal.getNumberOfPages()
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      doc.setTextColor('black')
      doc.setFontSize(9)

      const lineHeight = 286
      const lineWidth = 182
      const lineX = 14
      const lineY = lineHeight
      doc.line(lineX, lineY, lineX + lineWidth, lineY)
      doc.text(`Page ${i} / ${pageCount}`, 190, 290, { align: 'right' })
    }
  }
  addFooter(doc)

  doc.save('StaffOutletTemplate.pdf')
}

export default StaffOutletTemplatePDF
