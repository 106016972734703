import { CButton, CCard, CCardBody, CCol, CForm, CInputGroup, CRow } from '@coreui/react'
import React, { useEffect,useRef, useState } from 'react'
import BaseListing from 'src/BaseListings'
import { wtApiCall } from '../../helpers/commons/ApiWrapper'
import { lzNotifyErrorMessageFromResult } from '../../helpers/commons/notifications'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import VehicleLogReportDataTable from './VehicleLogReportDataTable'
import WidgetEmployeeMultiSelector from 'src/widgets/Multi-Selectors/WidgetEmployeeMultiSelector'
import Loading from 'src/helpers/commons/loader'
import { DatePicker } from 'antd';
import Select from 'react-select'
import { useDownloadExcel } from 'react-export-table-to-excel'
const { RangePicker } = DatePicker;
export default function EmployeeAttendanceReport() {
  const Base = new BaseListing()
  const [isLoading, setIsLoading] = useState(false)
  const [Date, setDate] = useState('')
  const [Employees, setEmployees] = useState([])
  const [departments, setDepartments] = useState([])
  const [errors, setErrors] = useState([])
  const [reportData, setReportData] = useState([])
  const [range, setRange] = useState(null);
const [selectedEmployees, setSelectedEmployees] = useState([])
const [selectedDepartments, setSelectedDepartments] = useState([])
const tableRef = useRef(null)


//   const generatePDF = () => {
//     const doc = new jsPDF('landscape')
//     const logo = 'assets/wil-icon.png'
//     const img = new Image()
//     var totalPagesExp = "{total_pages_count_string}";
//     let yPosition = 45

//     const headRows = [
//       {
//         content: 'Sr',
//       },
//       {
//         dataKey: 'full_name',
//         content: 'Employee Name',
//       },
//       {
//         dataKey: 'designation.name',
//         content: 'Designation',
//       },
//       {
//         dataKey: 'department.name',
//         content: 'Department',
//       },
//       {
//         dataKey: 'check_in',
//         content: 'Check In',
//       },
//       {
//         dataKey: 'check_out',
//         content: 'Check Out',
//       },
//       {
//         dataKey: 'late_coming',
//         content: 'Late Coming',
//       },
//       {
//         dataKey: 'early_going',
//         content: 'Early Going',
//       },
//       {
//         dataKey: 'woking_hours',
//         content: 'Working Hours',
//       },
//       {
//         dataKey: 'shift_duration',
//         content: 'Shift Duration',
//       },
//       {
//         dataKey: 'difference',
//         content: 'Difference',
//       },
//       {
//         dataKey: 'oveetime_hours',
//         content: 'Overtime Hours',
//       },
//       {
//         dataKey: 'is_overtime_approved',
//         content: 'Approved Ovetime',
//       },
//     ]

//     const employeeDetails = reportData.map((employee, index) => [
//       index + 1,
//       employee.employee.full_name,
//       employee.employee.designation?.name || '',
//       employee.employee.department?.name || '',
//       { content: employee.check_in || '-' },
//       { content: employee.check_out || '-' },
//       { content: employee.late_coming !== null ? employee.early_going + ' min' : '-' },
//       { content: employee.early_going !== null ? employee.early_going + ' min' : '-' },

//       // employee.working_hours,
//       { content: wtConvertHourValue(employee.working_hours) },

//       { content: wtConvertHourValue(employee.shift_duration) },

//       { content: (employee.working_hours - employee.shift_duration) * 60 + ' Minutes' },
//       { content: employee.overtime_hours ? wtRoundNumberToSpecifedDigits(employee.overtime_hours) + ' Hours' : '-' ,
//         styles:{textColor:employee.overtime_hours  ? 'green' : 'red' } 
//       },

//       { content: employee.is_overtime_approved === 0 ? '-' : employee.updated_overtime_hours ? wtRoundNumberToSpecifedDigits(employee.updated_overtime_hours) + ' Hours' : '-',
//       styles:{textColor:employee.is_overtime_approved === 0 ? 'red' : 'green'}
//     },

//     ])

//     doc.autoTable({
//       head: [headRows],
//       body: employeeDetails,
//       startY: 45,
//       margin: { top: 50, bottom: 20, left: 10, right: 10 },
//       theme: 'plain',
//       tableLineColor: '#3c4b64',
//       tableLineWidth: 0.2,
//       tableWidth: 280,
//       styles: { lineColor: '#3c4b64', lineWidth: 0.2, halign: 'center' },
//       headStyles: {
//         fillColor: '#fff',
//         textColor: [60, 75, 100],
//         setFontSize: 8,
//         font: 'helvetica',
//       },

//       didDrawPage: function (data) {
//         //get page no
//         totalPagesExp = doc.internal.getNumberOfPages()
//         if (typeof doc.putTotalPages === 'function') {
//           doc.putTotalPages(totalPagesExp)
//         }
//         let pageNo = doc.internal.getNumberOfPages()
//         var pageSize = doc.internal.pageSize
//         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
//         var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth() // Header

//         doc.setTextColor('#3c4b64')
//         doc.setFont('Helvetica', 'bold') //Header middle branch logo

//         doc.addImage(logo, 'PNG', pageWidth - 280, 4, 25, 25)
//         doc.line(pageWidth - 254, 7, pageWidth - 254, 27) // Adjust the coordinates as needed
//         doc.setFontSize(11)
//         doc.text('WAH', pageWidth - 251, 10, { align: 'left' })
//         doc.setFont('Helvetica')
//         doc.text('INDUSTRIES', pageWidth - 251, 15)
//         doc.text('LIMITED', pageWidth - 251, 20)
//         doc.setFontSize(9)
//         doc.text('www.wahindustries.com', pageWidth - 251, 25)

//         doc.setFontSize(14)
//         doc.text('Employee Attendance Report', pageWidth - 20, 10, { align: 'right' }) // Add header separator

//         yPosition -= -5 // Set alignment for the text that follows the line
//         doc.setFont('Helvetica')
//         doc.setFontSize(12)
//         doc.setTextColor('#3c4b64')

//         //Page No
//         var str = "Page " + pageNo;
//         // Total page number plugin only available in jspdf v1.0+
//         if (typeof doc.putTotalPages === "function") {
//           str = str + " of " + totalPagesExp;
//         }

//         doc.setTextColor("black");
//         doc.text(str, 10, pageHeight - 4);
//       },
//     })

//     doc.setFontSize(10)
//     const printReport =
//       'This Report is printed through ERP at ' + moment().format('YYYY-MM-DD HH:mm:ss')
//     const styleReport = { halign: 'center' }
//     const pageWidth = doc.internal.pageSize.width
//     const textWidth =
//       (doc.getStringUnitWidth(printReport) * doc.internal.getFontSize()) / doc.internal.scaleFactor
//     const startXForPrintReport = (pageWidth - textWidth) / 2
//     const startYForPrintReport = doc.autoTable.previous.finalY + 20

//     doc.text(printReport, startXForPrintReport, startYForPrintReport, styleReport)

//     doc.save('EmployeeAttendance.pdf')
//   }
  const handleMultiSelectDepartments = (options) => {
    if (options.length) {
      if (options[0].value === '*') {
        setSelectedDepartments([...Departments])
      } else {
        setSelectedDepartments(options)
      }
    } else {
      setSelectedDepartments([])
    }
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Vehicle Log Report',
    sheet: 'Sheet',
  })


  const getReportData = () => {
    if(range!=null)
    {
        
    const fromDate = range[0]
    const toDate = range[1]
    wtApiCall(
      'reports/vehicle_log_report',
      {
    
        fromDate: fromDate.format('YYYY-MM-DD'),
        toDate: toDate.format('YYYY-MM-DD'),
        employee_travelled: JSON.stringify(selectedEmployees),
        departments:JSON.stringify(selectedDepartments),

      },
      'get',
      (response) => {
        const { data } = response
        setReportData(data)
        setIsLoading(false)
      },
      (error) => {
        lzNotifyErrorMessageFromResult(error)
        setIsLoading(false)
      },
    )
    }
    else
    {
      alert("Date Range Is Required")
    }
  }
  useEffect(() => {
    Base.dropdownData(
      'employees,departments',
      {},
      [setEmployees,setDepartments],
      1,
    )
  }, [])
  return (
    <>
      <div style={{ padding: '2%' }}>
        <center>
          <h4>Vehicle Log Report</h4>
        </center>
        <CRow style={{ width: '100%' }}>
          <CCol md={12}>
            <CCard className="card-dark">
              <CCardBody style={{ backgroundColor: '#f7f9fa' }}>
                <br />
                <CForm>
                  <CRow>
                    {/* <CCol md={1}></CCol> */}
                    <CCol md={1}>
                      <CInputGroup>
                        <label style={{ fontSize: '140%', marginTop: '50px' }}>Filters:</label>
                      </CInputGroup>
                    </CCol>
                    <CCol md={3}>
                      <div className="form-group">
                        <label>Date Range:</label>
                        <br />
                        <RangePicker value={range} onChange={setRange} size='middle' />
                      </div>
                    </CCol>
                    <CCol md={3}>
                    <div className="form-group">
                    <label>Employees</label>
                    <>
                        <WidgetEmployeeMultiSelector
                        Employees={Employees}
                        value={selectedEmployees}
                        onChange={(values) => setSelectedEmployees(values)}
                        // isDisabled={props.viewOnly}
                        />
                    </>
                    {errors.employee_id && <ErrorMessage messages={errors.employee_id} />}
                    </div>
                    </CCol>
                    <CCol md={3}>
                  <div className="form-group">
                    <label>Departments</label>
                    <>
                      <Select
                        isMulti
                        options={[{ value: '*', label: 'Select All' }, ...departments]}
                        value={selectedDepartments}
                        onChange={handleMultiSelectDepartments}
                        // isDisabled={props.viewOnly}
                      />
                    </>
                    {errors.department_id && <ErrorMessage messages={errors.department_id} />}
                  </div>
                </CCol>

                    <CCol md={2}>
                
                        <br></br>
                        
                      <CButton
                        color="primary"
                        onClick={() => getReportData()}
                        style={{ marginLeft: '1%' }}
                      >
                        Search
                      </CButton><CButton
                        color="success"
                        className="text-white"
                        style={{ marginBottom: '20px', marginTop: '20px', marginLeft: '1%' }}
                        onClick={onDownload}
                      >
                        Export
                      </CButton>
                      {/* <CButton
                        color="danger"
                        className="text-white"
                        style={{ marginLeft: '1%' }}
                        onClick={generatePDF}
                      >
                        PDF
                      </CButton> */}
                    </CCol>
                  </CRow>

                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <br />
        <br />
        <table className="table table-bordered data-table" ref={tableRef}>

        {isLoading ? (
          Loading
        ) : (
          <>
            {reportData.length > 0 ? (
              <VehicleLogReportDataTable
                reportData={reportData}
                setReportData={(data) => setReportData(data)}
                isLoading={isLoading}
              />
            ) : (
              <center style={{ fontSize: '19px', fontWeight: 'bold' }}>No record to show...</center>
            )}
          </>
        )}
        </table>

      </div>
    </>
  )
}
