import React, { useState } from 'react'
import { CCol, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import './table.css'
import { wtGetDateMonthYearFormat } from 'src/helpers/commons/moment'
import { Link} from 'react-router-dom'

export default function DataReportTable({ reportData, setReportData }) {
  const [sortedKey, setSortedKey] = useState('')
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc',
  })

  const sortTable = (key) => {
    let direction = 'asc'
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    const sorted = [...reportData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1
      }
      return 0
    })
    setReportData(sorted)
    setSortedKey(key)
    setSortConfig({ key, direction })
  }

  return (
    <CRow>
      <CCol md={12}>
        <table className="table table-bordered data-table">
          <thead class="thead-dark" style={{ backgroundColor: '#e8e9eb' }}>
            <tr>
              <th onClick={() => sortTable('id')}>Sr</th>
              <th onClick={() => sortTable('id')}>
                ID{' '}
                {sortedKey === 'id' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('personal_file_no')}>
                Personal File No.{' '}
                {sortedKey === 'personal_file_no' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('full_name')}>
                Full Name
                {sortedKey === 'full_name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('branch.name')}>
                Branch
                {sortedKey === 'branch.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('department.name')}>
                Department
                {sortedKey === 'department.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('designation.name')}>
                Designation
                {sortedKey === 'designation.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('mobile_no')}>
                Mobile no.
                {sortedKey === 'mobile_no' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('cnic')}>
                CNIC
                {sortedKey === 'cnic' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('date_of_birth')}>
                Date Of Birth
                {sortedKey === 'date_of_birth' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('date_of_joining')}>
                Date Of Joining
                {sortedKey === 'date_of_joining' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('duration')}>
                Duration
                {sortedKey === 'duration' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('age')}>
                Age
                {sortedKey === 'age' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('job_status')}>
                Job Status
                {sortedKey === 'job_status' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {reportData.map((employee, index) => (
              <tr key={employee.id}>
                <td>{index + 1}</td>
                <td style={{ color: 'blue' }}>
                <Link to={`/employee-profile?id=${employee.id}`} target="_blank">
                      {employee.id} 
                </Link>
                </td>
                <td style={{ color: 'blue' }}>{employee.personal_file_no}</td>
                <td style={{ color: 'blue' }}>
                <Link to={`/employee-profile?id=${employee.id}`} target="_blank">
                      {employee.full_name}
                </Link>
                  </td>
                <td>{employee.branch.name}</td>
                <td>{employee.department.name}</td>
                <td>{employee.designation.name}</td>
                <td>{employee.mobile_no}</td>
                <td>{employee.cnic}</td>
                <td style={{ color: 'blue' }}>
                  {employee.date_of_birth ? wtGetDateMonthYearFormat(employee.date_of_birth) : ''}
                </td>
                <td style={{ color: 'blue' }}>
                  {employee.date_of_joining ? wtGetDateMonthYearFormat(employee.date_of_joining) : ''}
                </td>
                {/* <td style={{ color: 'blue' }}>{employee.date_of_joining}</td> */}
                <td style={{ color: 'red' }}>{employee.duration}</td>
                <td style={{ color: 'green' }}>{employee.age}</td>
                <td
                  style={{
                    color:
                      employee.job_status === 'Permanent' ? 'green' : 'Trainee' ? 'Blue' : 'red',
                  }}
                >
                  {employee.job_status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CCol>
    </CRow>
  )
}
