import { CButton, CCard, CCardBody, CCol, CForm, CInputGroup, CRow } from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import BaseListing from 'src/BaseListings'
import { wtApiCall } from '../../helpers/commons/ApiWrapper'
import { lzNotifyErrorMessageFromResult } from '../../helpers/commons/notifications'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import DetailedDataReportTable from './DetailedDataReportTable'
import Loading from 'src/helpers/commons/loader'
import WidgetSelector from 'src/widgets/Selectors/WidgetSelector'
import { useDownloadExcel } from 'react-export-table-to-excel';
import OfficerTemplatePDF from 'src/PDF/PerformanceEvaluationsPDF/OfficerTemplatePDF'
import StaffOutletTemplatePDF from 'src/PDF/PerformanceEvaluationsPDF/StaffOutletTemplatePDF'
import StaffTemplatePDF from 'src/PDF/PerformanceEvaluationsPDF/StaffTemplatePDF'
import CafeteriaStaffTemplatePDF from 'src/PDF/PerformanceEvaluationsPDF/CafeteriaStaffTemplatePDF'
import SalesTemplatePDF from 'src/PDF/PerformanceEvaluationsPDF/SalesTemplatePDF'
import OutletLabTemplatePDF from 'src/PDF/PerformanceEvaluationsPDF/OutletLabTemplatePDF'

export default function EmployeeAttendanceDifferenceReport() {
  const Base = new BaseListing()
  const tableRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false)
  const [Date, setDate] = useState('')
  const [Employees, setEmployees] = useState([])
  const [Months, setMonths] = useState([])
  const [month, setMonth] = useState(0)
  const [Years, setYears] = useState([])
  const [year, setYear] = useState(0)
  const [isOfficer, setIsOfficer] = useState(null)
  const [EmployeeId, setEmployeeId] = useState(0)
  const [EmployeeName, setEmployeeName] = useState('')
  const [branches, setBranches] = useState([])
  const [branchId, setBranchId] = useState(0)
  const [branch_name, setBranchName] = useState('')
  const [departments, setDepartments] = useState([])
  const [department_id, setDepartmentId] = useState(0)
  const [department_name, setDepartmentName] = useState('')
  const [designations, setDesignations] = useState([])
  const [designation_id, setDesignationId] = useState(0)
  const [designation_name, setDesignationName] = useState('')
  const [EmployeeShifts, setEmployeeShifts] = useState([])
  const [EmployeeShiftId, setEmployeeShiftId] = useState(0)
  const [EmployeeShiftName, setEmployeeShiftName] = useState('')
  const [CheckOut, setCheckOut] = useState('')
  const [CheckIn, setCheckIn] = useState('')
  const [WorkingHours, setWorkingHours] = useState('')
  const [Remarks, setRemarks] = useState('')
  const [errors, setErrors] = useState([])
  const [isMore, setIsMore] = useState(false)
  const [reportData, setReportData] = useState([])
  const [dates, setDates] = useState([])

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Performance Evaluation Detailed Report',
    sheet: 'Performance'
  })

  const changeValues = (key, val) => {
    if (key === 'branch_id') {
      setBranchId(val.value)
      setBranchName(val.label)
      Base.dropdownData('departments', { branch_id: val.value }, [setDepartments], 1)
    }
    if (key === 'department_id') {
      setDepartmentId(val.value)
      setDepartmentName(val.label)
    }
    if (key === 'designation_id') {
      setDesignationId(val.value)
      setDesignationName(val.label)
    }
    if (key === 'employee_id') {
      setEmployeeId(val.value)
      setEmployeeName(val.label)
    }
  }

  
  const getPdfData = (id) => {
    wtApiCall(
      'performance_evaluations/' + id,
      {},
      'GET',
      (response) => {
        const { data } = response

        if (data.template_name === 'officerTemplate') {
          OfficerTemplatePDF(data)
        }
        if (data.template_name === 'staffOutletTemplate') {
          StaffOutletTemplatePDF(data)
        }
        if (data.template_name === 'staffTemplate') {
          StaffTemplatePDF(data)
        }
        if (data.template_name === 'cafeteriaStaffTemplate') {
          CafeteriaStaffTemplatePDF(data)
        }
        if (data.template_name === 'salesTemplate') {
          SalesTemplatePDF(data)
        }
        if (data.template_name === 'outletLabTemplate') {
          OutletLabTemplatePDF(data)
        }
      },
      (error) => {
        lzNotifyErrorMessageFromResult(error)
      },
    )
  }

  const generatePDF = () => {
    const doc = new jsPDF('portrait')
    const logo = 'assets/wil-icon.png'
    const img = new Image()
    let totalPagesExp = doc.internal.getNumberOfPages()
    let yPosition = 45

    const headRows = [
      {
        content: 'Sr',
      },
      {
        content: 'ID',
      },
      {
        dataKey: 'full_name',
        content: 'Employee Name',
      },
      {
        dataKey: 'designation.name',
        content: 'Designation',
      },
      {
        dataKey: 'department.name',
        content: 'Department',
      },
      {
        dataKey: 'employee.creaed_at',
        content: 'Evaluation Month',
      },
      {
        dataKey: 'employee.total_ratings',
        content: 'Total Submitted Ratings',
      },
      {
        dataKey: 'employee.total_recommended_ratings',
        content: 'Total Recommended Ratings',
      },

      {
        dataKey: 'employee.total_approved_ratings',
        content: 'Total Approved Ratings',
      },


    ]

    const employeeDetails = reportData.map((employee, index) => [
      index + 1,
      employee.employee_id,
      employee.employee.name,
      employee.employee.designation?.name || '',
      employee.employee.department?.name || '',
      moment(employee.created_at).format('MMMM,YYYY'),
      // Format to display month name and year
      {
        content: Base.hasRights('Show Employee Performance Evaluation Detailed Report Detail') ? (
          employee.ratings !== null ? `${employee.max_rating}/${employee.total_ratings} By ${employee.created_by.name}` : 'Pending'
        ) : (
          employee.ratings !== null ? 'Completed' : 'Pending'
        ),
      
        styles: {textColor: employee.ratings === null ? 'red' : 'blue'}
      },
      {
        content:Base.hasRights('Show Employee Performance Evaluation Detailed Report Detail') ? (
          employee.recommended_ratings !== null ? employee.max_rating + '/' + employee.recommended_total_ratings + ' By ' + employee.recommended_by.name : 'Pending'
        ) : (
          employee.recommended_ratings !== null ? 'Completed' : 'Pending'
        ),
      
        styles: {textColor: employee.recommended_ratings === null ? 'red' : 'blue'}
      },
      {
        content:Base.hasRights('Show Employee Performance Evaluation Detailed Report Detail') ? (
          employee.recommended_ratings !== null ? employee.max_rating + '/' + employee.approved_total_ratings + ' By ' + employee.approved_by.name : 'Pending'
        ) : (
          employee.recommended_ratings !== null ? 'Completed' : 'Pending'
        ),
      
        styles: {textColor: employee.approved_ratings === null ? 'red' : 'blue'}
      },


      


    ])

    doc.autoTable({
      // head: [headRows],
      // body: employeeDetails,
      // startY: 45,
      // margin: { top: 50, bottom: 20, left: 10, right: 10 },
      // theme: 'plain',
      // tableLineColor: '#3c4b64',
      // tableLineWidth: 0.2,
      // tableWidth: 280,
      // styles: { lineColor: '#3c4b64', lineWidth: 0.2, halign: 'center' },
      // headStyles: {
      //   fillColor: '#fff',
      //   textColor: [60, 75, 100],
      //   setFontSize: 8,
      //   font: 'helvetica',
      // },


      head: [headRows],
      body: employeeDetails,
      startY: 45,
      margin: { top: 50, bottom: 20, left: 14, right: 10 },
      theme: 'plain',
      tableLineColor: '#3c4b64',
      tableLineWidth: 0.2,
      tableWidth: 180, // Adjust the width as needed
      styles: { lineColor: '#3c4b64', lineWidth: 0.2, halign: 'center', fontSize: 8 }, // Adjust font size
      headStyles: {
        fillColor: '#fff',
        textColor: [60, 75, 100],
        setFontSize: 8,
        font: 'helvetica',
      },



      didDrawPage: function (data) {
        //get page no
        totalPagesExp = doc.internal.getNumberOfPages()
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp)
        }
        let pageNo = doc.internal.getNumberOfPages()
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth() // Header

        doc.setTextColor('#3c4b64')
        doc.setFont('Helvetica', 'bold') //Header middle branch logo

        doc.addImage(logo, 'PNG', pageWidth - 197, 4, 25, 25)
        doc.line(pageWidth - 171, 7, pageWidth - 171, 27) // Adjust the coordinates as needed
        doc.setFontSize(11)
        doc.text('WAH', pageWidth - 169, 10, { align: 'left' })
        doc.setFont('Helvetica')
        doc.text('INDUSTRIES', pageWidth - 169, 15)
        doc.text('LIMITED', pageWidth - 169, 20)
        doc.setFontSize(9)
        doc.text('www.wahindustries.com', pageWidth - 169, 25)

        doc.setFontSize(14)
        doc.text('Employees Performance Evaluation Detailed Report', pageWidth - 20, 10, { align: 'right' }) // Add header separator

        yPosition -= -5 // Set alignment for the text that follows the line
        doc.setFont('Helvetica')
        doc.setFontSize(12)
        doc.setTextColor('#3c4b64')

        yPosition += 20 // Page No
        var str = 'Page ' + pageNo // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }

        doc.setTextColor('black')
        doc.text(str, 10, pageHeight - 4)
      },
    })

    doc.setFontSize(10)
    const printReport =
      'This Report is printed through ERP at ' + moment().format('YYYY-MM-DD HH:mm:ss')
    const styleReport = { halign: 'center' }
    const pageWidth = doc.internal.pageSize.width
    const textWidth =
      (doc.getStringUnitWidth(printReport) * doc.internal.getFontSize()) / doc.internal.scaleFactor
    const startXForPrintReport = (pageWidth - textWidth) / 2
    const startYForPrintReport = doc.autoTable.previous.finalY + 20

    doc.text(printReport, startXForPrintReport, startYForPrintReport, styleReport)

    doc.save('PerformanceEvaluationReport.pdf')
  }

  const getReportData = () => {
    setIsLoading(true)
    wtApiCall(
      'reports/employee-performance-details_report',
      {
        month: month,
        year: year,
        // is_officer: isOfficer
      },
      'get',
      (response) => {
        const { data } = response
        setReportData(data)
        // setDates(data.dates)
        setIsLoading(false)
      },
      (error) => {
        lzNotifyErrorMessageFromResult(error)
        setIsLoading(false)
      },
    )
  }
  useEffect(() => {
    Base.dropdownData(
      'employees,branches,designations,performance_evaluation_months,performance_evaluation_years',
      {},
      [setEmployees, setBranches, setDesignations, setMonths, setYears],
      1,
    )
  }, [])
  return (
    <>
      <div style={{ padding: '2%' }}>
        <center>
          <h4>Employee Performance Evaluation Detailed Report</h4>
        </center>
        <CRow style={{ width: '100%' }}>
          <CCol md={12}>
            <CCard className="card-dark">
              <CCardBody style={{ backgroundColor: '#f7f9fa' }}>
                <br />
                <CForm>
                  <CRow>
                    {/* <CCol md={1}></CCol> */}
                    <CCol md={1}>
                      <CInputGroup>
                        <label style={{ fontSize: '140%', marginTop: '50px' }}>Filters:</label>
                      </CInputGroup>
                    </CCol>
                    <CCol md={3}>
                      <div className="form-group">
                        <label>Month</label>
                        <WidgetSelector
                          options={Months}
                          placeHolder={"Select Performance Month"}
                          onChange={(values) => setMonth(values.value)}
                          selectedValue={month}
                        />
                        {errors.attendance_date && (
                          <ErrorMessage messages={errors.attendance_date} />
                        )}
                      </div>
                    </CCol>
                    <CCol md={3}>
                      <div className="form-group">
                        <label>Year</label>
                        <WidgetSelector
                          options={Years}
                          placeHolder={"Select Performance Year"}
                          onChange={(values) => setYear(values.value)}
                          selectedValue={year}
                        />
                        {errors.attendance_date && (
                          <ErrorMessage messages={errors.attendance_date} />
                        )}
                      </div>
                    </CCol>
                    {/* <CCol md={3}>
                      <CInputGroup>
                        <label>Is Officer?</label>
                        <WidgetSelector
                          options={[{ value: '*', label: 'All' }, ...YesNoOptions]}
                          onChange={(values) => setIsOfficer(values.value)}
                          selectedValue={isOfficer}
                        />
                        {errors.branch_id && <ErrorMessage messages={errors.branch_id} />}
                      </CInputGroup>
                    </CCol> */}
                    <CCol md={2}>
                      {/* <CButton
                        color="warning"
                        className="text-white"
                        onClick={() => setIsMore(!isMore)}
                        style={{ marginBottom: '20px', marginTop: '20px' }}
                      >
                        More
                      </CButton> */}

                      <CButton
                        color="primary"
                        onClick={() => getReportData()}
                        style={{ marginBottom: '20px', marginTop: '20px' }}
                      >
                        Search
                      </CButton>

                      {Base.hasRights('Export Employee Performance Evaluation Report') && (
                        <CButton color='success' onClick={onDownload} className='text-white'>Export</CButton>
                      )}
                      {Base.hasRights('Print Employee Performance Evaluation Report') && (
                        <CButton
                          color="danger"
                          className="text-white"
                          style={{ marginBottom: '20px', marginTop: '20px', marginLeft: '1%' }}
                          onClick={generatePDF}
                        >
                          PDF
                        </CButton>
                      )}
                    </CCol>
                  </CRow>
                  {/* {isMore && (
                    <>
                      <CRow>
                        <CCol md={1}></CCol>

                        <CCol md={3}>
                          <CInputGroup>
                            <label>Department</label>
                            <WidgetDepartmentSelector
                              departments={departments}
                              displayValue={department_name}
                              value={department_id}
                              placeholder="Select Department"
                              onChange={(values) => changeValues('department_id', values)}
                              reloadDropdownData={() => getDepartments(branchId)}
                            />
                            {errors.department_id && (
                              <ErrorMessage messages={errors.department_id} />
                            )}
                          </CInputGroup>
                        </CCol>
                        <CCol md={3}>
                          <CInputGroup>
                            <label>Designation</label>
                            <WidgetDesignationSelector
                              designations={designations}
                              displayValue={designation_name}
                              value={designation_id}
                              placeholder="Select Designation"
                              onChange={(values) => changeValues('designation_id', values)}
                              reloadDropdownData={() =>
                                Base.dropdownData('designations', {}, [setDesignations], 1)
                              }
                            />
                            {errors.designation_id && (
                              <ErrorMessage messages={errors.designation_id} />
                            )}
                          </CInputGroup>
                        </CCol>
                      </CRow>
                    </>
                  )}
                  <br /> */}
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <br />
        <br />
        {/* <AttendanceReportLegends /> */}
        <CRow>
          <CCol md={12}>
            <table className="table table-bordered data-table" ref={tableRef}>
              {isLoading ? (
                Loading
              ) : (
                <>
                  {reportData.length > 0 ? (
                    <DetailedDataReportTable
                      reportData={reportData}
                      getPdfData={getPdfData}
                      setReportData={(data) => setReportData(data)}
                      isLoading={isLoading}
                      ref={tableRef}
                    />
                  ) : (
                    <center style={{ fontSize: '19px', fontWeight: 'bold' }}>No record to show...</center>
                  )}
                </>
              )}
            </table>
          </CCol>
        </CRow>
      </div>
    </>
  )
}
