import { CButton, CCard, CCardBody, CCol, CForm, CInputGroup, CRow } from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import BaseListing from 'src/BaseListings'
import WidgetBranchSelector from '../../widgets/Selectors/WidgetBranchSelector'
import WidgetDepartmentSelector from '../../widgets/Selectors/WidgetDepartmentSelector'
import WidgetDesignationSelector from '../../widgets/Selectors/WidgetDesignationSelector'
import { wtApiCall } from '../../helpers/commons/ApiWrapper'
import { lzNotifyErrorMessageFromResult } from '../../helpers/commons/notifications'
import WidgetEmployeeSelector from 'src/widgets/Selectors/WidgetEmployeeSelector'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import DataReportTable from './DataReportTable'
import Loading from 'src/helpers/commons/loader'
import WidgetDatePicker from 'src/widgets/DatePickers/WidgetDatePicker'
import { color } from 'highcharts'
import { wtConvertHourValue } from 'src/helpers/commons/moment'
import { wtRoundNumberToSpecifedDigits } from 'src/helpers/commons/utils'
import { useDownloadExcel } from 'react-export-table-to-excel'

export default function EmployeeAttendanceReport() {
  const Base = new BaseListing()
  const tableRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [Date, setDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [Employees, setEmployees] = useState([])
  const [EmployeeId, setEmployeeId] = useState(0)
  const [EmployeeName, setEmployeeName] = useState('')
  const [branches, setBranches] = useState([])
  const [branchId, setBranchId] = useState(0)
  const [branch_name, setBranchName] = useState('')
  const [departments, setDepartments] = useState([])
  const [department_id, setDepartmentId] = useState(0)
  const [department_name, setDepartmentName] = useState('')
  const [designations, setDesignations] = useState([])
  const [designation_id, setDesignationId] = useState(0)
  const [designation_name, setDesignationName] = useState('')
  const [EmployeeShifts, setEmployeeShifts] = useState([])
  const [EmployeeShiftId, setEmployeeShiftId] = useState(0)
  const [EmployeeShiftName, setEmployeeShiftName] = useState('')
  const [CheckOut, setCheckOut] = useState('')
  const [CheckIn, setCheckIn] = useState('')
  const [WorkingHours, setWorkingHours] = useState('')
  const [Remarks, setRemarks] = useState('')
  const [errors, setErrors] = useState([])
  const [isMore, setIsMore] = useState(false)
  const [reportData, setReportData] = useState([])
  const [totalPresents, setTotalPresents] = useState(0)
  const [totalOvertime, setTotalOvertime] = useState(0)

  const generatePDF = () => {
    const doc = new jsPDF()
    const logo = 'assets/wil-icon.png'
    const img = new Image()
    var totalPagesExp = '{total_pages_count_string}'
    let yPosition = 45

    doc.setTextColor('#3c4b64')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('Total Presents:', 10, 40, { align: 'left' })
    doc.setFont('Helvetica', 'bold')
    doc.text(wtRoundNumberToSpecifedDigits(totalPresents).toString(), 40, 40, { align: 'left' })

    doc.setTextColor('#3c4b64')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('Total Overtime:', 70, 40, { align: 'left' })
    doc.setFont('Helvetica', 'bold')
    doc.text(wtRoundNumberToSpecifedDigits(totalOvertime).toString() + " Hours", 100, 40, { align: 'left' })


    const headRows = [
      {
        content: 'Sr',
      },
      {
        dataKey: 'personal_file_no',
        content: 'Card',
      },
      {
        dataKey: 'full_name',
        content: 'Employee\nName',
      },
      {
        dataKey: 'department.name',
        content: 'Department',
      },
      {
        dataKey: 'attendance_date',
        content: 'Date',
      },
      {
        dataKey: 'check_in',
        content: 'Check\nIn',
      },
      {
        dataKey: 'check_out',
        content: 'Check\nOut',
      },
      {
        dataKey: 'late_coming',
        content: 'Late\nComing',
      },
      {
        dataKey: 'early_going',
        content: 'Early\nGoing',
      },
      {
        dataKey: 'woking_hours',
        content: 'Working\nHours',
      },
      // {
      //   dataKey: 'difference',
      //   content: 'Difference',
      // },
      {
        dataKey: 'oveetime_hours',
        content: 'Overtime\nHours',
      },
      {
        dataKey: 'is_overtime_approved',
        content: 'Approved\nOvetime',
      },
    ]


    const employeeDetails = reportData.map((employee, index) => [
      index + 1,
      employee.employee.personal_file_no,
      employee.employee.full_name,
      employee.employee.department?.name || '',
      employee.attendance_date || '-',
      employee.is_updated ? employee.updated_check_in : employee.check_in,
      employee.is_updated ? employee.updated_check_out : employee.check_out,
      employee.is_updated ? employee.updated_late_coming : employee.late_coming,
      employee.is_updated ? employee.updated_early_going : employee.early_going,



      // employee.working_hours,
      wtConvertHourValue(employee.is_updated ? employee.updated_working_hours : employee.working_hours),


      // wtConvertHourValue(employee.shift_duration),


      // { content: (employee.working_hours - employee.shift_duration) * 60 + ' Minutes' },
      {
        content: employee.overtime_hours
          ? wtRoundNumberToSpecifedDigits(employee.updated_overtime_hours ? employee.updated_overtime_hours : employee.overtime_hours) + ' Hours'
          : '-',
        styles: { textColor: employee.overtime_hours ? 'green' : 'red' },
      },


      {
        content:
          employee.is_overtime_approved === 0
            ? '-'
            : employee.updated_overtime_hours
              ? wtRoundNumberToSpecifedDigits(employee.updated_overtime_hours) + ' Hours'
              : '-',
        styles: { textColor: employee.is_overtime_approved === 0 ? 'red' : 'green' },
      },
    ])


    doc.autoTable({
      head: [headRows],
      body: employeeDetails,
      startY: 45,
      margin: { top: 30, bottom: 20, left: 10, right: 10 },
      theme: 'plain',
      tableLineColor: '#3c4b64',
      tableLineWidth: 0.2,
      tableWidth: 'auto',
      styles: { lineColor: '#3c4b64', lineWidth: 0.2, halign: 'center' },
      headStyles: {
        fillColor: '#fff',
        textColor: [60, 75, 100],
        fontSize: 9,
        font: 'helvetica',
      },
      bodyStyles: {
        fontSize: 8
      },


      didDrawPage: function (data) {
        //get page no
        totalPagesExp = doc.internal.getNumberOfPages()
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp)
        }
        let pageNo = doc.internal.getNumberOfPages()
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth() // Header


        doc.addImage(logo, 'PNG', pageWidth - 200, 4, 25, 25)
        doc.line(pageWidth - 173, 7, pageWidth - 173, 27) // Adjust the coordinates as needed
        doc.setTextColor('#3c4b64')
        doc.setFont('Helvetica', 'bold')
        doc.setFontSize(11)
        doc.text('Al-Malik', pageWidth - 171, 10, { align: 'left' })
        doc.setFontSize(11)
        doc.setFont('Helvetica')
        doc.text('Enterprises', pageWidth - 171, 15)
        doc.text('LIMITED', pageWidth - 171, 20)
        doc.setFontSize(14)
        doc.text('Employee Attendance Report', pageWidth - 12, 10, { align: 'right' })


        yPosition -= -5 // Set alignment for the text that follows the line
        doc.setFont('Helvetica')
        doc.setFontSize(12)
        doc.setTextColor('#3c4b64')


        //Page No
        var str = 'Page ' + pageNo
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }


        doc.setTextColor('black')
        doc.text(str, 10, pageHeight - 4)
      },
    })


    doc.setFontSize(10)
    const printReport =
      'This Report is printed through ERP at ' + moment().format('YYYY-MM-DD HH:mm:ss')
    const styleReport = { halign: 'center' }
    const pageWidth = doc.internal.pageSize.width
    const textWidth =
      (doc.getStringUnitWidth(printReport) * doc.internal.getFontSize()) / doc.internal.scaleFactor
    const startXForPrintReport = (pageWidth - textWidth) / 2
    const startYForPrintReport = doc.autoTable.previous.finalY + 20


    doc.text(printReport, startXForPrintReport, startYForPrintReport, styleReport)


    doc.save('EmployeeAttendance.pdf')
  }

  const changeValues = (key, val) => {
    if (key === 'branch_id') {
      setBranchId(val.value)
      setBranchName(val.label)
      Base.dropdownData('departments', { branch_id: val.value }, [setDepartments], 1)
    }
    if (key === 'department_id') {
      setDepartmentId(val.value)
      setDepartmentName(val.label)
    }
    if (key === 'designation_id') {
      setDesignationId(val.value)
      setDesignationName(val.label)
    }
    if (key === 'employee_id') {
      setEmployeeId(val.value)
      setEmployeeName(val.label)
    }
  }

  const getReportData = () => {
    setIsLoading(true)
    wtApiCall(
      'reports/employee_attendance_report',
      {
        employee_id: EmployeeId,
        branch_id: branchId,
        department_id: department_id,
        designation_id: designation_id,
        employee_shift_id: EmployeeShiftId,
        check_in: CheckIn,
        check_out: CheckOut,
        working_hours: WorkingHours,
        remarks: Remarks,
        attendance_date_from: Date,
        attendance_date_to: toDate,
      },
      'get',
      (response) => {
        const { data } = response
        setReportData(data.employee_attendance)
        setTotalPresents(data.totalPresents)
        setTotalOvertime(data.totalOvertime)
        setIsLoading(false)
      },
      (error) => {
        lzNotifyErrorMessageFromResult(error)
        setIsLoading(false)
      },
    )
  }
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Attendance Report',
    sheet: 'Sheet 1',
  })
  useEffect(() => {
    Base.dropdownData(
      'employees,branches,designations,departments',
      {},
      [setEmployees, setBranches, setDesignations, setDepartments],
      1,
    )
  }, [])
  return (
    <>
      <div style={{ padding: '2%' }}>
        <center>
          <h4>Employee Attendance Report</h4>
        </center>
        <CRow style={{ width: '100%' }}>
          <CCol md={12}>
            <CCard className="card-dark">
              <CCardBody style={{ backgroundColor: '#f7f9fa' }}>
                <br />
                <CForm>
                  <CRow>
                    {/* <CCol md={1}></CCol> */}
                    <CCol md={1}>
                      <CInputGroup>
                        <label style={{ fontSize: '140%', marginTop: '50px' }}>Filters:</label>
                      </CInputGroup>
                    </CCol>
                    <CCol md={2}>
                      <div className="form-group">
                        <label>From Date</label>
                        <WidgetDatePicker
                          value={Date}
                          setDate={setDate}
                          placeholder="Select From Date"
                          className={errors.attendance_date ? 'is-invalid' : ''}
                        />
                        {errors.attendance_date && (
                          <ErrorMessage messages={errors.attendance_date} />
                        )}
                      </div>
                    </CCol>
                    <CCol md={2}>
                      <div className="form-group">
                        <label>To Date</label>
                        <WidgetDatePicker
                          value={toDate}
                          setDate={setToDate}
                          placeholder="Select From Date"
                          className={errors.attendance_date ? 'is-invalid' : ''}
                        />
                        {errors.attendance_date && (
                          <ErrorMessage messages={errors.attendance_date} />
                        )}
                      </div>
                    </CCol>
                    <CCol md={2}>
                      <CInputGroup>
                        <label>Employee</label>
                        <WidgetEmployeeSelector
                          employees={Employees}
                          displayValue={EmployeeName}
                          value={EmployeeId}
                          placeholder="Select Employee"
                          onChange={(values) => changeValues('employee_id', values)}
                          reloadDropdownData={() =>
                            Base.dropdownData('employees', {}, [setEmployees], 1)
                          }
                        />
                        {errors.employee_id && <ErrorMessage messages={errors.employee_id} />}
                      </CInputGroup>
                    </CCol>
                    <CCol md={2}>
                      <CInputGroup>
                        <label>Department</label>
                        <WidgetDepartmentSelector
                          departments={departments}
                          displayValue={department_name}
                          value={department_id}
                          placeholder="Select Department"
                          onChange={(values) => changeValues('department_id', values)}
                          reloadDropdownData={() => getDepartments(branchId)}
                        />
                        {errors.department_id && (
                          <ErrorMessage messages={errors.department_id} />
                        )}
                      </CInputGroup>
                    </CCol>

                    <CCol md={3}>
                      <CButton
                        color="warning"
                        className="text-white"
                        onClick={() => setIsMore(!isMore)}
                        style={{ marginBottom: '20px', marginTop: '20px' }}
                      >
                        More
                      </CButton>

                      <CButton
                        color="primary"
                        onClick={() => getReportData()}
                        style={{ marginLeft: '1%' }}
                      >
                        Search
                      </CButton>

                      <CButton
                        color="danger"
                        className="text-white"
                        style={{ marginLeft: '1%' }}
                        onClick={generatePDF}
                      >
                        PDF
                      </CButton>
                      <CButton
                        color="success"
                        onClick={onDownload}
                        style={{ marginBottom: '20px', marginTop: '20px', marginLeft: '1%' }}
                        className="text-white"
                      >
                        Export
                      </CButton>
                    </CCol>
                  </CRow>
                  {isMore && (
                    <>
                      <CRow>
                        <CCol md={1}></CCol>


                        <CCol md={3}>
                          <CInputGroup>
                            <label>Designation</label>
                            <WidgetDesignationSelector
                              designations={designations}
                              displayValue={designation_name}
                              value={designation_id}
                              placeholder="Select Designation"
                              onChange={(values) => changeValues('designation_id', values)}
                              reloadDropdownData={() =>
                                Base.dropdownData('designations', {}, [setDesignations], 1)
                              }
                            />
                            {errors.designation_id && (
                              <ErrorMessage messages={errors.designation_id} />
                            )}
                          </CInputGroup>
                        </CCol>
                      </CRow>
                    </>
                  )}
                  <br />
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <br />
        <br />
        <CRow>
          <CCol md={12}>
            <table className="table table-bordered data-table" ref={tableRef}>
              {isLoading ? (
                Loading
              ) : (
                <>
                  <CRow>
                    <CCol md={3}>
                      <h5>Total Presents: <span style={{ color: 'blue' }}>{totalPresents}</span></h5>
                    </CCol>
                    <CCol md={3}>
                      <h5>Total Overtime: <span style={{ color: 'blue' }}>{totalOvertime ? wtRoundNumberToSpecifedDigits(totalOvertime) + ' Hours' : 0}</span></h5>
                    </CCol>
                  </CRow>
                  {reportData.length > 0 ? (
                    <DataReportTable
                      reportData={reportData}
                      setReportData={(data) => setReportData(data)}
                      isLoading={isLoading}
                      ref={tableRef}
                    />
                  ) : (
                    <center style={{ fontSize: '19px', fontWeight: 'bold' }}>
                      No record to show...
                    </center>
                  )}
                </>
              )}
            </table>
          </CCol>
        </CRow>
      </div>
    </>
  )
}
