import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Row, Col, DatePicker, Spin, Table, Switch } from 'antd';
import { wtGetFromLocalStorage } from 'src/helpers/commons/LocalStorage';
import BaseListing from 'src/BaseListings';
import WidgetSelector from 'src/widgets/Selectors/WidgetSelector';
import { STATUS_NAME, STATUS_POSTED, VOUCHER_STATUS_DROPDOWN_OPTIONS } from 'src/models/vouchers';
import WidgetProjectSelector from 'src/widgets/Selectors/WidgetProjectSelector';
import { wtApiCall } from 'src/helpers/commons/ApiWrapper';
import WidgetBranchSelector from 'src/widgets/Selectors/WidgetBranchSelector'
import { lzNotifyErrorMessageFromResult } from 'src/helpers/commons/notifications';
import { CaretDownFilled, CaretRightFilled } from '@ant-design/icons'
import { wtFormatMoney } from 'src/helpers/commons/utils';
import { Link, useNavigate } from 'react-router-dom';

const TrialBalance = () => {
    const Base = new BaseListing('trial_balance')
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm();

    const [projects, setProjects] = useState([])
    const [branches, setBranches] = useState([])

    const [hideZeroBalances, setHideZeroBalances] = useState(true)
    const [showLastLevelOnly, setShowLastLevelOnly] = useState(false)
    const [projectId, setProjectId] = useState()
    const [projectName, setProjectName] = useState('')

    const [voucherStatus, setVoucherStatus] = useState(STATUS_POSTED)
    const [voucherStatusName, setVoucherStatusName] = useState(STATUS_NAME[STATUS_POSTED])

    const [branchId, setBranchId] = useState(0)
    const [branchName, setBranchName] = useState('')
    const [trialBalanceData, setTrialBalanceData] = useState([])
    const [dateRange, setDateRange] = useState([])

    const [boldRows, setBoldRows] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);

    const companyName = wtGetFromLocalStorage("company_name");

    // Define columns of trial balance
    const trialBalanceHeaderColumns = [
        {
            title: <span style={{ fontSize: '0.8rem', marginLeft: '5%' }}>Account Description</span>,
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: 600,
            onCell: (record) => ({
                onClick: () => handleExpand(record),
                style: { cursor: 'pointer' }
            }),
            render: (text, record) => (
                <span style={{ fontWeight: (boldRows.includes(record.key) || record.key === 'total') ? 'bold' : 'normal', fontSize: "0.8rem", cursor: "pointer" }}>
                    {text}
                </span>
            )
        },
        {
            title: <span style={{ fontSize: '0.8rem' }}>Opening Balance</span>,
            children: [
                {
                    title: <span style={{ fontSize: '0.8rem' }}>Debit ( .Rs. )</span>,
                    dataIndex: 'opening_balance_debit',
                    key: 'opening_balance_debit',
                    align: 'right',
                    render: (text, record) => (
                        <span style={{ fontWeight: (boldRows.includes(record.key) || record.key === 'total') ? 'bold' : 'normal', fontSize: "0.8rem" }}
                        >
                            {getLink(record, text, 'opening')}
                        </span>
                    ),
                },
                {
                    title: <span style={{ fontSize: '0.8rem' }}>Credit ( .Rs. )</span>,
                    dataIndex: 'opening_balance_credit',
                    key: 'opening_balance_credit',
                    align: 'right',
                    render: (text, record) => (
                        <span style={{ fontWeight: (boldRows.includes(record.key) || record.key === 'total') ? 'bold' : 'normal', fontSize: "0.8rem" }}
                        >
                            {getLink(record, text, 'opening')}
                        </span>
                    ),
                },
            ]
        },
        {
            title: <span style={{ fontSize: '0.8rem' }}>Current Period</span>,
            children: [
                {
                    title: <span style={{ fontSize: '0.8rem' }}>Debit ( .Rs. )</span>,
                    dataIndex: 'current_period_debit',
                    key: 'current_period_debit',
                    align: 'right',
                    render: (text, record) => (
                        <span style={{ fontWeight: (boldRows.includes(record.key) || record.key === 'total') ? 'bold' : 'normal', fontSize: "0.8rem" }}
                        >
                            {getLink(record, text, 'current')}
                        </span>
                    ),
                },
                {
                    title: <span style={{ fontSize: '0.8rem' }}>Credit ( .Rs. )</span>,
                    dataIndex: 'current_period_credit',
                    key: 'current_period_credit',
                    align: 'right',
                    render: (text, record) => (
                        <span style={{ fontWeight: (boldRows.includes(record.key) || record.key === 'total') ? 'bold' : 'normal', fontSize: "0.8rem" }}
                        >
                            {getLink(record, text, 'current')}
                        </span>
                    ),
                },
            ]
        },
        {
            title: <span style={{ fontSize: '0.8rem' }}>Closing Balance</span>,
            children: [
                {
                    title: <span style={{ fontSize: '0.8rem' }}>Debit ( .Rs. )</span>,
                    dataIndex: 'closing_balance_debit',
                    key: 'closing_balance_debit',
                    align: 'right',
                    render: (text, record) => (
                        <span style={{ fontWeight: (boldRows.includes(record.key) || record.key === 'total') ? 'bold' : 'normal', fontSize: "0.8rem" }}>
                            {getLink(record, text, 'closing')}
                        </span>
                    ),
                },
                {
                    title: <span style={{ fontSize: '0.8rem' }}>Credit ( .Rs. )</span>,
                    dataIndex: 'closing_balance_credit',
                    key: 'closing_balance_credit',
                    align: 'right',
                    render: (text, record) => (
                        <span style={{ fontWeight: (boldRows.includes(record.key) || record.key === 'total') ? 'bold' : 'normal', fontSize: "0.8rem" }}
                        >
                            {getLink(record, text, 'closing')}
                        </span>
                    ),
                },
            ]
        },
    ];


    const getLink = (record, text, key) => {

        if (dateRange.length < 2) {
            lzNotifyErrorMessageFromResult("Please select date range");
            return;
        }
        if (record.key === 'total' || key === 'closing') {
            return wtFormatMoney(text, "", false, 2);
        }

        let fromDate = "";
        let toDate = "";

        if (key === 'opening') {
            fromDate = "2000-01-01";
            toDate = (dateRange[0].subtract(1, 'day')).format('YYYY-MM-DD');
        }

        if (key === 'current') {
            fromDate = dateRange[0].format('YYYY-MM-DD');
            toDate = dateRange[1].format('YYYY-MM-DD');
        }

        const queryParams = "fromDate=" + fromDate + "&toDate=" + toDate + "&accountId=" + (+record.id) + "&voucherStatus=" + (+voucherStatus) + "&branchId=" + (+branchId) + "&branchName=" + branchName + "&projectId=" + (+projectId) + " ";
        return (
            <a href={'/reports?' + queryParams + '#code:account-statement'}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#000000" }}
            >
                {wtFormatMoney(text, "", false, 2)}
            </a>
        )
    }


    const changeValues = (key, val) => {

        //Set initial values
        setTrialBalanceData([]);
        setExpandedRows([]);

        if (key === 'branch_id') {
            setBranchId(val.value)
            setBranchName(val.label)
        }
        if (key === 'project_id') {
            setProjectId(val)
            if (val === '') {
                setProjectName('');
            }
        }
        if (key === 'voucher_status') {
            setVoucherStatus(val.value)
            setVoucherStatusName(val.label)
        }
        if (key === 'date_range') {
            setDateRange(val);
        }

        if (key === 'show_last_level_only') {
            setShowLastLevelOnly(val)
        }
        if (key === 'hide_zero_balances') {
            setHideZeroBalances(val)
        }
    }

    useEffect(() => {
        Base.dropdownData('projects,branches', {}, [setProjects, setBranches], 1)
    }, [])

    const getReportData = () => {
        if (dateRange.length < 2) {
            lzNotifyErrorMessageFromResult("Please select date range");
            return;
        }
        const fromDate = dateRange[0];
        const toDate = dateRange[1];

        setIsLoading(true);
        wtApiCall(
            'reports/trial_balance',
            {
                branch_id: branchId,
                from_date: fromDate.format('YYYY-MM-DD'),
                to_date: toDate.format('YYYY-MM-DD'),
                hide_zero_balances: hideZeroBalances,
                show_last_level_only: showLastLevelOnly,
                project_id: projectId,
                voucher_status: voucherStatus
            },
            'get',
            (response) => {

                //set data
                if (response !== null) {
                    if (response.data !== null) {
                        if (response.data.length > 0) {
                            setTrialBalanceData(response.data);
                        }
                        else {
                            //Set initial values
                            setTrialBalanceData([]);
                            setExpandedRows([]);
                        }
                    }
                }

                setIsLoading(false);
            },
            (error) => {
                lzNotifyErrorMessageFromResult(error);
                setIsLoading(false);
            },
        );

    };



    const expandIcon = ({ expanded, onExpand, record }) => {
        if (record.hasItems) {
            return (
                <span style={{ marginRight: '2%' }}>
                    {expanded ? (
                        <CaretDownFilled style={{ color: "#706f71", fontSize: 12 }} onClick={(e) => onExpand(record, e)} />
                    ) : (
                        <CaretRightFilled style={{ color: "#706f71", fontSize: 12 }} onClick={(e) => onExpand(record, e)} />
                    )}
                </span>
            )
        } else {
            return <span style={{ marginRight: '3%' }}></span>
        }
    };

    const handleExpand = (record) => {
        const key = record.key;
        const isExpanded = expandedRows.includes(key);
        if (record.hasItems) {
            if (isExpanded) {
                setExpandedRows(expandedRows.filter(rowKey => rowKey !== key));
                setBoldRows(prevBoldRows => prevBoldRows.filter(key => key !== record.key)); // Remove the key from boldRows
            } else {
                setExpandedRows([...expandedRows, key]);
                setBoldRows(prevBoldRows => [...prevBoldRows, record.key]);
            }
        }
    };

    return (
        <div className='container-fluid' style={{
            padding: "1em", backgroundColor: "white", minHeight: "100vh", fontFamily: "Helvetica",
        }}>
            <Form
                layout={'vertical'}
                form={form}
                onFinish={getReportData}
                style={{ maxWidth: "100%", margin: '0 auto' }}
            >
                <Card title={<span style={{ fontSize: '22px', marginLeft: "40%" }}>{companyName + " Trial Balance"}</span>} style={{ width: "100%" }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={15} sm={10} md={4}>
                            <label>Date Range: *</label>
                            <DatePicker.RangePicker
                                onChange={(value) => changeValues('date_range', value)}
                                style={{ width: '100%', height: 35 }}
                                value={dateRange}
                            />
                        </Col>
                        <Col xs={9} sm={6} md={3}>
                            <label>Voucher Status: *</label>
                            <WidgetSelector
                                selectedValue={voucherStatus}
                                displayValue={voucherStatusName}
                                options={VOUCHER_STATUS_DROPDOWN_OPTIONS}
                                showCloudIcon={false}
                                size={"md"}
                                onChange={(values) => changeValues("voucher_status", values)}
                            />
                        </Col>
                        <Col xs={12} sm={8} md={4}>
                            <label>Branch:</label>
                            <WidgetBranchSelector
                                value={branchId}
                                displayValue={branchName}
                                branches={branches}
                                placeholder="Select Branch"
                                onChange={(values) => changeValues('branch_id', values)}
                                reloadDropdownData={() => Base.dropdownData('branches', {}, [setBranches], 1)}
                            />

                        </Col>
                        <Col xs={15} sm={10} md={5}>
                            <label>Project: </label>
                            <WidgetProjectSelector
                                onChange={(value) => changeValues('project_id', value)}
                                data={projects}
                                value={projectId}
                                displayValue={projectName}
                                height={35}
                                reloadDropdownData={() => Base.dropdownData('projects', {}, [setProjects], 1)}
                            />
                        </Col>

                        <Col xs={9} sm={6} md={3}>
                            <label>Show Last Level Only: *</label>
                            <Switch style={{ marginTop: 10 }} value={showLastLevelOnly} onChange={(value) => changeValues('show_last_level_only', value)} />

                        </Col>
                        <Col xs={9} sm={6} md={3}>
                            <label>Hide Zero Balances: *</label>
                            <Switch style={{ marginTop: 10 }} value={hideZeroBalances} onChange={(value) => changeValues('hide_zero_balances', value)} />
                        </Col>

                        <Col xs={8} sm={4} md={2}>
                            <Button style={{ marginTop: 35 }} type="primary" htmlType="submit" disabled={isLoading ? true : false}>Search</Button>
                        </Col>
                    </Row>

                    <br /><br />
                    <div style={{ overflow: 'auto', margin: "auto", width: "90%" }}>
                        <Table
                            columns={trialBalanceHeaderColumns}
                            dataSource={trialBalanceData}
                            pagination={false}
                            expandable={{ expandedRowKeys: expandedRows, expandIcon: expandIcon }}
                            bordered
                            size="small"
                            loading={{ indicator: <div><Spin /></div>, spinning: isLoading }}
                        />
                    </div>
                </Card>
            </Form>
        </div >
    );
};

export default TrialBalance;
