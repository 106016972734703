import React, { useState } from 'react'
import { CCol, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import './table.css'
import { wtRoundNumberToSpecifedDigits } from 'src/helpers/commons/utils'
import { Link } from 'react-router-dom'
import { wtConvertHourValue } from 'src/helpers/commons/moment'
// import Difference from './Difference'
import moment from 'moment'

export default function DataReportTable({ reportData, setReportData, isOfficer }) {
  const [sortedKey, setSortedKey] = useState('')
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc',
  })

  const sortTable = (key) => {
    let direction = 'asc'
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    const sorted = [...reportData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1
      }
      return 0
    })
    setReportData(sorted)
    setSortedKey(key)
    setSortConfig({ key, direction })
  }


  return (
    <>
      <thead class="thead-dark" style={{ backgroundColor: '#e8e9eb' }}>
        <tr>
          <th onClick={() => sortTable('id')}>Sr</th>
          <th onClick={() => sortTable('id')}>
            ID{' '}
            {sortedKey === 'id' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('id')}>
            Card#{' '}
            {sortedKey === 'id' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('full_name')}>
            Full Name
            {sortedKey === 'full_name' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>

          <th onClick={() => sortTable('designations')}>
            Designation
            {sortedKey === 'designations' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>

          <th onClick={() => sortTable('presents')}>
            Presents
            {sortedKey === 'presents' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('total_absent')}>
            Absents
            {sortedKey === 'total_absent' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('paid_leaves')}>
            Paid Leaves
            {sortedKey === 'paid_leaves' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('unpaid_leaves')}>
            Unpaid Leaves
            {sortedKey === 'unpaid_leaves' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          {isOfficer === 2 && (
            <>
              <th onClick={() => sortTable('late_coming_days')}>
                Late Coming Days
                {sortedKey === 'late_coming_days' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>

            </>
          )}

          {/* {dates.map((item) => <th key={item}>{moment(item).format("DD")}</th>)} */}
          <th onClick={() => sortTable('total_overtime')}>
            OT Hours
            {sortedKey === 'total_overtime' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('difference')}>
            Difference (hrs)
            {sortedKey === 'difference' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          {/* <th onClick={() => sortTable('difference')}>
            Unpaid Leaves
            {sortedKey === 'difference' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th> */}
          <th onClick={() => sortTable('t_ifference')}>
            Total Deductions
            {sortedKey === 't_difference' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>

        </tr>
      </thead>
      <tbody>
        {reportData.map((item, index) => (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td style={{ color: 'black' }}>
              <Link
                to={`/employee-profile?id=${item.id}&tab=emp_attendance`}
                target="_blank"
              >
                {item.id}
              </Link>
            </td>
            <td style={{ color: 'black' }}>
              <Link
                to={`/employee-profile?id=${item.id}&tab=emp_attendance`}
                target="_blank"
              >
                {item.personal_file_no}
              </Link>
            </td>
            <td style={{ color: 'blue' }}>
              <Link
                to={`/employee-profile?id=${item.id}&tab=emp_attendance`}
                target="_blank"
              >
                {item.full_name}
              </Link>
            </td>
            <td style={{ color: 'blue' }} >{item.designation.name}</td>
            <td style={{ color: 'green' }}>{item.total_present}</td>
            <td style={{ color: item.total_absent > 0 ? 'red' : 'black' }}>{item.total_absent}</td>
            <td style={{ color: 'green' }}>{item.paid_leaves}</td>
            <td style={{ color: 'red' }}>{item.unpaid_leaves}</td>

            {isOfficer === 2 && (
              <>
                <td style={{ color: 'blue' }}>{item.total_lates}</td>

              </>
            )}
            <td style={{ color: 'blue' }}>{item.total_overtime}</td>
            <td style={{ color: 'blue' }}>{wtRoundNumberToSpecifedDigits(item.total_difference)}</td>
            {/* <td style={{ color: 'blue' }}>{item.unpaid_leaves}</td> */}

            <td style={{ color: item.total_deduction > 0 ? 'red' : 'black' }}>{item.total_deduction}</td>
          </tr>
        ))}
      </tbody>
    </>
  )
}

