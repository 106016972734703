import React from 'react'
import { TreeSelect } from 'antd';
import { CFormInput, CInputGroup, CInputGroupText } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilCloudDownload, cilTrash } from '@coreui/icons';
export default function WidgetAccountsSelector({ data, value, onChange, reloadDropdownData, viewOnly, displayValue, showCloudIcon = true, showFormInputView = false, height, width = '100%' }) {
    return (
        <>
            {+value > 0 && displayValue !== "" && showFormInputView ? (
                <React.Fragment >
                    <CInputGroup>
                        <CFormInput value={displayValue} disabled />
                        {!viewOnly && (
                            <CInputGroupText
                                style={{ cursor: 'pointer' }}
                                onClick={() => onChange(null)}
                            >
                                <CIcon icon={cilTrash} alt="Remove" title="Remove" />
                            </CInputGroupText>
                        )}
                    </CInputGroup>
                </React.Fragment >

            ) : (

                < CInputGroup style={{ height: 30}
                }>
                    <div style={{ flexGrow: 1}}>
                        <TreeSelect
                            showSearch
                            style={{ width: width, height: height }}
                            dropdownStyle={{ maxHeight: 400, overflow: 'hidden', zIndex: 100000 }}
                            placeholder="Please select"
                            allowClear
                            // treeDefaultExpandAll
                            onChange={(value) => onChange(value)}
                            treeData={data}
                            value={value}
                            disabled={viewOnly}
                            treeNodeFilterProp="title"
                            virtual={false}
                        />

                    </div>
                    {
                        showCloudIcon && (
                            <>
                                <CInputGroupText
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => reloadDropdownData()}
                                >
                                    <CIcon icon={cilCloudDownload} alt="Remove" title="Remove" />
                                </CInputGroupText>
                            </>
                        )
                    }
                </CInputGroup >
            )
            }
        </>
    )
}
