import { CButton, CCard, CCardBody, CCol, CForm, CInputGroup, CRow } from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import BaseListing from 'src/BaseListings'
import { wtApiCall } from '../../helpers/commons/ApiWrapper'
import { lzNotifyErrorMessageFromResult } from '../../helpers/commons/notifications'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import DataReportTable from './DataReportTable'
import Loading from 'src/helpers/commons/loader'
import WidgetSelector from 'src/widgets/Selectors/WidgetSelector'
import { useDownloadExcel } from 'react-export-table-to-excel'
import { YesNoOptions } from 'src/helpers/commons/dropdownOptions'
import AttendanceReportLegends from 'src/widgets/Legends/AttendanceReportLegends'
import { wtRoundNumberToSpecifedDigits } from 'src/helpers/commons/utils'
import WidgetDepartmentSelector from '../../widgets/Selectors/WidgetDepartmentSelector'

export default function EmployeeEarlyGoingReport() {
  const Base = new BaseListing()
  const tableRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [Employees, setEmployees] = useState([])
  const [Months, setMonths] = useState([])
  const [month, setMonth] = useState(0)
  const [Years, setYears] = useState([])
  const [year, setYear] = useState(0)
  const [isOfficer, setIsOfficer] = useState(null)
  const [branches, setBranches] = useState([])
  const [designations, setDesignations] = useState([])
  const [errors, setErrors] = useState([])
  const [reportData, setReportData] = useState([])
  const [dates, setDates] = useState([])
  const [departments, setDepartments] = useState([])
  const [department_id, setDepartmentId] = useState(0)
  const [department_name, setDepartmentName] = useState('')
  const generatePDF = () => {
    const doc = new jsPDF('landscape', 'mm', [360, 447])
    const logo = 'assets/wil-icon.png'
    const img = new Image()
    var totalPagesExp = '{total_pages_count_string}'
    let yPosition = 49

    const pageWidth1 = doc.internal.pageSize.width
    doc.setTextColor('#3c4b64')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(14)
    doc.text('Legend:', pageWidth1 - 430, 43, { align: 'left' })
    doc.setTextColor('#3c4b64')
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(12)
    // Set bold font for labels
    doc.setFont('Helvetica', 'bold')

    // Write labels with bold font
    doc.text('. A:', pageWidth1 - 425, 50)
    doc.text('. P:', pageWidth1 - 425, 55)
    doc.text('. L:', pageWidth1 - 425, 60)
    doc.text('. MV:', pageWidth1 - 425, 65)
    doc.text('. PH:', pageWidth1 - 425, 70)
    doc.text('. WH:', pageWidth1 - 425, 75)
    doc.text('. M(IN):', pageWidth1 - 425, 80)
    doc.text('. M(OUT):', pageWidth1 - 425, 85)

    // Reset font to normal style
    doc.setFont('Helvetica', 'normal')

    // Write descriptions in normal style
    doc.text('Absent', pageWidth1 - 417, 50)
    doc.text('Present', pageWidth1 - 417, 55)
    doc.text('Leave', pageWidth1 - 417, 60)
    doc.text('On Move', pageWidth1 - 414, 65)
    doc.text('Public Holiday', pageWidth1 - 414, 70)
    doc.text('Weekly Holiday', pageWidth1 - 414, 75)
    doc.text('Check IN Missing', pageWidth1 - 410, 80)
    doc.text('Check OUT Missing', pageWidth1 - 405, 85)

    doc.setTextColor('#3c4b64')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(14)
    doc.text('** All values are in min', pageWidth1 - 430, 93, { align: 'left' })

    const getPdfDifferenceData = (date, record) => {
      let difference = '-'
      record.map((item) => {
        if (item.hasOwnProperty(date)) {
          difference = item[date]
        }
      })
      return difference
    }

    const getPdfDifferenceColor = (date, record) => {
      const difference = getPdfDifferenceData(date, record)
      let color
      if (difference === 'A' || parseFloat(difference) < 0) {
        color = '#FF0000'
      } else if (difference === 'L') {
        color = '#0000FF'
      } else if (
        typeof difference === 'number' &&
        !Number.isNaN(difference) &&
        parseFloat(difference) > 0
      ) {
        color = '#008000'
      } else {
        color = '#000000'
      }

      return color
    }

    const headRows = [
      {
        content: 'Sr',
      },
      {
        dataKey: 'id',
        content: 'ID',
      },
      {
        dataKey: 'full_name',
        content: 'Full Name',
      },
      {
        dataKey: 'designation.name',
        content: 'Designation',
      },
      ...dates.map((date) => ({
        dataKey: `attendance_difference.${date}`,
        content: moment(date).format('DD'),
      })),
      {
        dataKey: 'total_early',
        content: 'Total Early days',
      },
      {
        dataKey: 'total_difference',
        content: 'Total Early (min)',
      },
      {
        dataKey: 'total_leave',
        content: 'Total Leaves',
      },
      {
        dataKey: 'total_holidays',
        content: 'Total Holidays',
      },
      {
        dataKey: 'total_absent',
        content: 'Total Absent',
      },
      {
        dataKey: 'total_attendance_missing',
        content: 'Total Attendance Missing',
      },
    ]

    const employeeDetails = reportData.map((employee, index) => [
      index + 1,
      { content: employee.id, styles: { textColor: '#0000FF' } },
      { content: employee.full_name, styles: { textColor: '#0000FF' } },
      employee.designation?.name || '',
      ...dates.map((date) => ({
        content: getPdfDifferenceData(date, employee.attendance_difference),
        styles: { textColor: getPdfDifferenceColor(date, employee.attendance_difference) },
      })),
      {
        content: employee.total_early,
        styles: { textColor: employee.total_early >= 0 ? 'green' : 'red' },
      },
      {
        content: wtRoundNumberToSpecifedDigits(employee.total_difference),
        styles: { textColor: employee.total_difference >= 0 ? 'green' : 'red' },
      },
      employee.total_leave,
      employee.total_holidays,
      { content: employee.total_absent, styles: { textColor: '#f32013' } },
      employee.total_attendance_missing,
    ])

    doc.autoTable({
      head: [headRows],
      body: employeeDetails,
      startY: 98.5,
      margin: { top: 50, bottom: 20, left: 10, right: 10 },
      theme: 'plain',
      tableLineColor: '#3c4b64',
      tableLineWidth: 0.2,
      tableWidth: 430,
      styles: { lineColor: '#3c4b64', lineWidth: 0.2, halign: 'center' },
      headStyles: {
        fillColor: '#fff',
        textColor: [60, 75, 100],
        setFontSize: 8,
        font: 'helvetica',
      },

      didDrawPage: function (data) {
        let pageNo = doc.internal.getNumberOfPages()
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth() // Header

        doc.setTextColor('#3c4b64')
        doc.setFont('Helvetica', 'bold') //Header middle branch logo

        doc.addImage(logo, 'PNG', pageWidth - 430, 4, 25, 25)
        doc.line(pageWidth - 404, 7, pageWidth - 404, 27) // Adjust the coordinates as needed
        doc.setFontSize(11)
        doc.text('WAH', pageWidth - 401, 10, { align: 'left' })
        doc.setFont('Helvetica')
        doc.text('INDUSTRIES', pageWidth - 401, 15)
        doc.text('LIMITED', pageWidth - 401, 20)
        // doc.setFontSize(9)
        doc.text('www.wahindustries.com', pageWidth - 401, 25)

        doc.setFontSize(14)
        doc.text('Employee Early Going Report', pageWidth - 20, 10, { align: 'right' }) // Add header separator

        yPosition -= -5 // Set alignment for the text that follows the line
        doc.setFont('Helvetica')
        doc.setFontSize(12)
        doc.setTextColor('#3c4b64')

        //Page No
        var str = 'Page ' + pageNo
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }

        doc.setTextColor('black')
        doc.text(str, 10, pageHeight - 4)
      },
    })

    doc.setFontSize(10)
    const printReport =
      'This Report is printed through ERP at ' + moment().format('YYYY-MM-DD HH:mm:ss')
    const styleReport = { halign: 'center' }
    const pageWidth = doc.internal.pageSize.width
    const textWidth =
      (doc.getStringUnitWidth(printReport) * doc.internal.getFontSize()) / doc.internal.scaleFactor
    const startXForPrintReport = (pageWidth - textWidth) / 2
    const startYForPrintReport = doc.autoTable.previous.finalY + 20

    doc.text(printReport, startXForPrintReport, startYForPrintReport, styleReport)

    doc.setTextColor('#3c4b64')
    doc.setFontSize(9)
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    doc.save('EarlyGoingReport.pdf')
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Early Going Report',
    sheet: 'Difference',
  })

  const changeValues = (key, val) => {

    if (key === 'department_id') {
      setDepartmentId(val.value)
      setDepartmentName(val.label)
    }
    // if (key === 'designation_id') {
    //   setDesignationId(val.value)
    //   setDesignationName(val.label)
    // }
    // if (key === 'employee_id') {
    //   setEmployeeId(val.value)
    //   setEmployeeName(val.label)
    // }
  }

  const getReportData = () => {
    setIsLoading(true)
    wtApiCall(
      'reports/employee_early_going_report',
      {
        month: month,
        year: year,
        is_officer: isOfficer,
        department_id: department_id,

      },
      'get',
      (response) => {
        const { data } = response
        setReportData(data.employees)
        setDates(data.dates)
        setIsLoading(false)
      },
      (error) => {
        lzNotifyErrorMessageFromResult(error)
        setIsLoading(false)
      },
    )
  }
  useEffect(() => {
    Base.dropdownData(
      'employees,branches,designations,attendance_months,attendance_years,departments',
      {},
      [setEmployees, setBranches, setDesignations, setMonths, setYears,setDepartments],
      1,
    )
  }, [])
  return (
    <>
      <div style={{ padding: '2%' }}>
        <center>
          <h4>Employee Early Going Report</h4>
        </center>
        <CRow style={{ width: '100%' }}>
          <CCol md={12}>
            <CCard className="card-dark">
              <CCardBody style={{ backgroundColor: '#f7f9fa' }}>
                <br />
                <CForm>
                  <CRow>
                    {/* <CCol md={1}></CCol> */}
                    <CCol md={1}>
                      <CInputGroup>
                        <label style={{ fontSize: '140%', marginTop: '50px' }}>Filters:</label>
                      </CInputGroup>
                    </CCol>
                    <CCol md={2}>
                      <div className="form-group">
                        <label>Month</label>
                        <WidgetSelector
                          options={Months}
                          placeHolder={'Select Month'}
                          onChange={(values) => setMonth(values.value)}
                          selectedValue={month}
                        />
                        {errors.attendance_date && (
                          <ErrorMessage messages={errors.attendance_date} />
                        )}
                      </div>
                    </CCol>
                    <CCol md={2}>
                      <div className="form-group">
                        <label>Year</label>
                        <WidgetSelector
                          options={Years}
                          placeHolder={'Select Year'}
                          onChange={(values) => setYear(values.value)}
                          selectedValue={year}
                        />
                        {errors.attendance_date && (
                          <ErrorMessage messages={errors.attendance_date} />
                        )}
                      </div>
                    </CCol>
                    <CCol md={3}>
                      <CInputGroup>
                        <label>Department</label>
                        <WidgetDepartmentSelector
                          departments={departments}
                          displayValue={department_name}
                          value={department_id}
                          placeholder="Select Department"
                          onChange={(values) => changeValues('department_id', values)}
                          reloadDropdownData={() => getDepartments(branchId)}
                        />
                        {errors.department_id && <ErrorMessage messages={errors.department_id} />}
                      </CInputGroup>
                    </CCol>
                    <CCol md={2}>
                      <CInputGroup>
                        <label>Is Officer?</label>
                        <WidgetSelector
                          options={[{ value: '*', label: 'All' }, ...YesNoOptions]}
                          onChange={(values) => setIsOfficer(values.value)}
                          selectedValue={isOfficer}
                        />
                        {errors.branch_id && <ErrorMessage messages={errors.branch_id} />}
                      </CInputGroup>
                    </CCol>
                    <CCol md={2}>
                      <CButton
                        color="primary"
                        onClick={() => getReportData()}
                        style={{ marginBottom: '20px', marginTop: '20px', marginLeft: '1%' }}
                      >
                        Search
                      </CButton>
                      {Base.hasRights('Export Employee Early Going Report') && (
                        <CButton
                          color="success"
                          onClick={onDownload}
                          style={{ marginBottom: '20px', marginTop: '20px', marginLeft: '1%' }}
                          className="text-white"
                        >
                          Export
                        </CButton>
                      )}
                      {Base.hasRights('Print Employee Early Going Report') && (
                        <CButton
                          color="danger"
                          className="text-white"
                          style={{ marginBottom: '20px', marginTop: '20px', marginLeft: '1%' }}
                          onClick={generatePDF}
                        >
                          PDF
                        </CButton>
                      )}
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <br />
        <br />
        <AttendanceReportLegends />
        <CRow>
          <CCol md={12}>
            <table className="table table-bordered data-table" ref={tableRef}>
              {isLoading ? (
                Loading
              ) : (
                <>
                  {reportData.length > 0 ? (
                    <DataReportTable
                      reportData={reportData}
                      dates={dates}
                      setReportData={(data) => setReportData(data)}
                      isLoading={isLoading}
                      ref={tableRef}
                    />
                  ) : (
                    <center style={{ fontSize: '19px', fontWeight: 'bold' }}>
                      No record to show...
                    </center>
                  )}
                </>
              )}
            </table>
          </CCol>
        </CRow>
      </div>
    </>
  )
}
