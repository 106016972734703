// pnl config detail Types
export const TYPE_GROUP = 1
export const TYPE_TOTAL = 2

export const TYPE_NAMES = {
    1: 'Group',
    2: 'Total'
}


//pnl config detail account Source types
export const SOURCE_TYPE_ACCOUNT = 1
export const SOURCE_TYPE_PNL_CONFIG_DETAIL = 2

export const SOURCE_TYPE_NAMES = {
    1: 'Account',
    2: 'Pnl Config Detail'
}
