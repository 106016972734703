import React from 'react';
import { wtApiCall } from '../../helpers/commons/ApiWrapper';
import { lzNotifyErrorMessageFromResult } from '../../helpers/commons/notifications';
import { CCol, CRow } from '@coreui/react';
import './index.css';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loading from 'src/helpers/commons/loader';
import { wtRoundValue } from 'src/helpers/commons/utils';
import { Table, Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const TYPE_LEAVE = 'Leave';
const TYPE_PRESENT = 'Present';
const TYPE_ABSENT = 'Absent';
const TYPE_WEEKLY_HOLIDAY = 'Weekly Holiday';
const TYPE_PUBLIC_HOLIDAY = 'Public Holiday';
const TYPE_ON_MOVE = 'On Move';
const TYPE_UNPRESENT = 'Unpresented';

class HRDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            isLoading: false,
            searchText: '',
            searchedColumn: '',
        };
    }

    componentDidMount() {
        this.loadList();
    }

    loadList() {
        this.setState({ isLoading: true });

        wtApiCall(
            "hr_dashboards",
            {},
            "get",
            (result) => {
                if (typeof result !== 'undefined') {
                    this.setState({
                        data: result.data
                    });
                }
                this.setState({ isLoading: false });
            },
            (result) => {
                lzNotifyErrorMessageFromResult(result);
                this.setState({ isLoading: false });
            }
        );
    }

    // Column filter input
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    });

    // Handle search input change
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    // Handle clear search
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        const {
            isLoading,
            data
        } = this.state;

        if (isLoading) {
            return Loading;
        }



        const employeeFieldsUpcomingBirthdays = [
            {
                key: "profile_picture",
                title: "Picture",
                dataIndex: 'profile_picture',
            },
            {
                key: "name",
                title: "Name",
                dataIndex: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
                ...this.getColumnSearchProps('name'),
                sortDirections: ['ascend', 'descend'],
            },
            {
                key: "department",
                title: "Department",
                dataIndex: 'department',
                sorter: (a, b) => a.department.localeCompare(b.department),
                ...this.getColumnSearchProps('department'),
                sortDirections: ['ascend', 'descend'],
            },
            {
                key: "designation",
                title: "Designation",
                dataIndex: 'designation',
                sorter: (a, b) => a.designation.localeCompare(b.designation),
                ...this.getColumnSearchProps('designation'),
                sortDirections: ['ascend', 'descend'],
            },
            {
                key: "cnic",
                title: "CNIC",
                dataIndex: 'cnic',
                sorter: (a, b) => a.cnic.localeCompare(b.cnic),
                ...this.getColumnSearchProps('cnic'),
                sortDirections: ['ascend', 'descend'],
            },
            {
                key: "date_of_birth",
                title: "Date Of Birth",
                dataIndex: 'date_of_birth',
                sorter: (a, b) => a.date_of_birth.localeCompare(b.date_of_birth),
                ...this.getColumnSearchProps('date_of_birth'),
                sortDirections: ['ascend', 'descend'],
            },
        ];

        const employeeFieldsUpcomingAniversaries = [

            {
                key: "name",
                title: "Name",
                dataIndex: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
                ...this.getColumnSearchProps('name'),
                sortDirections: ['ascend', 'descend'],
            },
            {
                key: "department",
                title: "Department",
                dataIndex: 'department',
                sorter: (a, b) => a.department.localeCompare(b.department),
                ...this.getColumnSearchProps('department'),
                sortDirections: ['ascend', 'descend'],
            },
            {
                key: "designation",
                title: "Designation",
                dataIndex: 'designation',
                sorter: (a, b) => a.designation.localeCompare(b.designation),
                ...this.getColumnSearchProps('designation'),
                sortDirections: ['ascend', 'descend'],
            },
            {
                key: "cnic",
                title: "CNIC",
                dataIndex: 'cnic',
                sorter: (a, b) => a.cnic.localeCompare(b.cnic),
                ...this.getColumnSearchProps('cnic'),
                sortDirections: ['ascend', 'descend'],
            },
            {
                key: "date_of_joining",
                title: "Date Of Joining",
                dataIndex: 'date_of_joining',
                sorter: (a, b) => a.date_of_joining.localeCompare(b.date_of_joining),
                ...this.getColumnSearchProps('date_of_joining'),
                sortDirections: ['ascend', 'descend'],
            },
        ];

        const employeeFieldsApproachingCnicExpiry = [

            {
                key: "name",
                title: "Name",
                dataIndex: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
                ...this.getColumnSearchProps('name'),
                sortDirections: ['ascend', 'descend'],
            },
            {
                key: "department",
                title: "Department",
                dataIndex: 'department',
                sorter: (a, b) => a.department.localeCompare(b.department),
                ...this.getColumnSearchProps('department'),
                sortDirections: ['ascend', 'descend'],
            },
            {
                key: "designation",
                title: "Designation",
                dataIndex: 'designation',
                sorter: (a, b) => a.designation.localeCompare(b.designation),
                ...this.getColumnSearchProps('designation'),
                sortDirections: ['ascend', 'descend'],
            },
            {
                key: "cnic",
                title: "CNIC",
                dataIndex: 'cnic',
                sorter: (a, b) => a.cnic.localeCompare(b.cnic),
                ...this.getColumnSearchProps('cnic'),
                sortDirections: ['ascend', 'descend'],
            },
            {
                key: "cnic_expiry_date",
                title: "CNIC Expiry",
                dataIndex: 'cnic_expiry_date',
                sorter: (a, b) => a.cnic_expiry_date.localeCompare(b.cnic_expiry_date),
                ...this.getColumnSearchProps('cnic_expiry_date'),
                sortDirections: ['ascend', 'descend'],
            },
        ];


        /////////////////////////////////////////////////////

        const genderByDepartmentData = data ? data.gender_distribution_by_department : [];

        const genderByDepartmentChartConfig = {
            chart: {
                type: 'column',
                // height: 300,
            },
            title: {
                text: 'Gender Distribution by Department',
            },
            xAxis: {
                type: 'category',
                categories: genderByDepartmentData.map(entry => entry.department),
            },
            yAxis: {
                title: {
                    text: 'Employee Count',
                },
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                },
            },
            series: [
                { name: 'Male', data: genderByDepartmentData.map(entry => entry.male) },
                { name: 'Female', data: genderByDepartmentData.map(entry => entry.female) },
            ],
        };

        //Set headcount chart data
        const genderData = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
                height: 350,
                events: {
                    load() {
                        const chart = this,
                            x = chart.plotSizeX / 2 - chart.plotLeft / 2,
                            y = (chart.plotSizeY / 2 + chart.plotTop) + 10;

                        chart.middleValue = chart.renderer
                            .text((data ? data.head_count : 0), 0, 0)
                            .css({
                                fontSize: "32px",
                            })
                            .add();
                        chart.middleValue.translate(
                            x - chart.middleValue.getBBox().width / 4,
                            y
                        );
                    },
                    render() {
                        const chart = this,
                            x = chart.plotSizeX / 2 - chart.plotLeft / 2,
                            y = (chart.plotSizeY / 2 + chart.plotTop) + 10;

                        if (chart.middleValue) {
                            chart.middleValue.translate(
                                x - chart.middleValue.getBBox().width / 4,
                                y
                            );
                        }
                    },
                },
            },
            title: {
                text: "Headcount",
                align: "center",
                style: {
                    fontWeight: "bold",
                    fontSize: "22px",
                    color: "#333",
                    fontFamily: "sans-serif",
                },
            },
            legend: {
                enabled: true, // Enable/Disable the legend
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        distance: -18,
                        enabled: true,
                        format: "{y}",
                        style: {
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "black",
                            textShadow: "0px 0px black",
                        },
                    },
                    startAngle: 0,
                    endAngle: 360,
                    size: "100%",
                    showInLegend: true,
                },
            },
            series: [
                {
                    type: "pie",
                    name: "",
                    innerSize: "60%",
                    colors: ["#FF7C82", "#009CCD"],
                    data: [
                        {
                            name: "Females",
                            y: (data ? data.female_count : 0),
                            dataLabels: {
                                enabled: true,
                            },
                        },
                        {
                            name: "Males",
                            y: (data ? data.male_count : 0),
                            dataLabels: {
                                enabled: true,
                            },
                        },
                    ],
                },
            ]
        };


        ///////////////////////////////////////////////////////


        const statusData = data ? data.job_status_distributions : [];

        const statusChartConfig = {
            chart: {
                type: 'pie',
                height: 350,
            },
            title: {
                text: 'Employee Status Distribution',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    },
                    innerSize: '50%', // Adjust the size to create a hole in the center (donut chart)
                },
            },
            series: [
                {
                    name: 'Status Distribution',
                    colorByPoint: true,
                    data: statusData,
                },
            ],
        };


        ///////////////////////////////////////////////////////

        const maritalStatusDistributionData = data ? data.marital_status_distributions : [];

        const maritalStatusDistributionChartConfig = {
            chart: {
                type: 'pie',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
            },
            title: {
                text: 'Marital Status Distribution',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    },
                },
            },
            series: [
                {
                    name: 'Marital Status',
                    colorByPoint: true,
                    data: maritalStatusDistributionData,
                },
            ],
        };


        ///////////////////////////////////////////////////////

        const attendanceTypesTodayData = data ? data.attendance_types_today : [];

        const attendanceTypesTodayDataChartConfig = {
            chart: {
                type: 'pie',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                height: 350,
            },
            title: {
                text: 'Attendance Type Distribution',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    },
                },
            },
            series: [
                {
                    name: 'No of Employees',
                    colorByPoint: true,
                    data: attendanceTypesTodayData,
                },
            ],
        };

        /////////////////////////////////////////////////////

        const performanceEvaluationStatusData = data ? data.performance_evaluation_status_distribution : [];

        const performanceEvaluationStatusChartConfig = {
            chart: {
                type: 'pie',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                height: 350,
            },
            title: {
                text: 'Performance Evaluation Distribution',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    },
                },
            },
            series: [
                {
                    name: 'Status',
                    colorByPoint: true,
                    data: performanceEvaluationStatusData,
                },
            ],
        };

        /////////////////////////////////////////////////////

        const employeeDistributionByDepartmentData = data ? data.employee_distribution_by_department : [];
        const employeeDistributionByDepartment = {
            chart: {
                type: 'pie',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                height: 350,
            },
            title: {
                text: 'Department Strength',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    },
                },
            },
            series: [
                {
                    name: 'No. Of Employees',
                    colorByPoint: true,
                    data: employeeDistributionByDepartmentData,
                },
            ],
        };


        /////////////////////////////////////////////////////

        const attendanceDistributionByDepartmentData = data ? data.attendance_distribution_by_department : [];

        const attendanceDistributionByDepartmentChartConfig = {
            chart: {
                type: 'column',
                // height: 300,
            },
            title: {
                text: 'Attendance Distribution by Department',
            },
            xAxis: {
                type: 'category',
                categories: attendanceDistributionByDepartmentData.map(entry => entry.department),
            },
            yAxis: {
                title: {
                    text: 'Employee Count',
                },
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                },
            },
            series: [
                { name: TYPE_LEAVE, data: attendanceDistributionByDepartmentData.map(entry => entry[TYPE_LEAVE]) },
                { name: TYPE_PRESENT, data: attendanceDistributionByDepartmentData.map(entry => entry[TYPE_PRESENT]) },
                { name: TYPE_ABSENT, data: attendanceDistributionByDepartmentData.map(entry => entry[TYPE_ABSENT]) },
                { name: TYPE_WEEKLY_HOLIDAY, data: attendanceDistributionByDepartmentData.map(entry => entry[TYPE_WEEKLY_HOLIDAY]) },
                { name: TYPE_PUBLIC_HOLIDAY, data: attendanceDistributionByDepartmentData.map(entry => entry[TYPE_PUBLIC_HOLIDAY]) },
                { name: TYPE_ON_MOVE, data: attendanceDistributionByDepartmentData.map(entry => entry[TYPE_ON_MOVE]) },
                { name: TYPE_UNPRESENT, data: attendanceDistributionByDepartmentData.map(entry => entry[TYPE_UNPRESENT]) },
            ],
        };


        return (

            <div className='container-fluid' style={{
                padding: "1em", backgroundColor: "#E5E5E5", minHeight: "100vh", fontFamily: "Helvetica",
                pointerEvents: (isLoading) ? 'none' : 'auto'
            }}>
                {data !== null &&
                    <CRow style={{ fontFamily: "sans-serif", fontWeight: "bolder" }}>
                        <CCol md="12">
                            <CRow>
                                <CCol md="2">
                                    <div className="ceo-dashboard-parent-box"
                                        style={{ textAlign: "left", color: "#1C6BB8" }}
                                    >
                                        <h4 style={{ fontWeight: 700 }}> HR Dashboard</h4>
                                    </div>

                                    <div className="ceo-dashboard-parent-box">
                                        <div className="ceo-dashboard-child-box">
                                            <img style={{ height: 30, top: 8, left: 15, position: "relative", }}
                                                src={"avatars/dashboard-icons/headcount.png"} alt="headcount.png" />
                                            <div style={{ position: "relative" }}>
                                                <span style={{ left: 70, position: "absolute", fontSize: 14, bottom: 22 }}>
                                                    Head Count
                                                </span>
                                                <br />
                                                <span style={{ left: 70, top: 1, position: "absolute", fontSize: 14, bottom: 1 }}>
                                                    {data.head_count + " Employees"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ceo-dashboard-parent-box">
                                        <div className="ceo-dashboard-child-box">
                                            <img style={{ height: 30, top: 8, left: 15, position: "relative", }}
                                                src={"avatars/dashboard-icons/average-age.png"} alt="average-age.png" />
                                            <div style={{ position: "relative" }}>
                                                <span style={{ left: 55, position: "absolute", fontSize: 14, bottom: 22 }}>
                                                    Average Employee Age
                                                </span>
                                                <br />
                                                <span style={{ left: 80, top: 1, position: "absolute", fontSize: 14, bottom: 1 }}>
                                                    {wtRoundValue(data.average_employee_age, 2) + " Years"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ceo-dashboard-parent-box">
                                        <div className="ceo-dashboard-child-box">
                                            <img style={{ height: 30, top: 8, left: 8, position: "relative", }}
                                                src={"avatars/dashboard-icons/average-tenure.png"} alt="average-tenure.png" />
                                            <div style={{ position: "relative" }}>
                                                <span style={{ left: 40, position: "absolute", fontSize: 14, bottom: 22 }}>
                                                    Average Employee Tenure
                                                </span>
                                                <br />
                                                <span style={{ left: 80, top: 1, position: "absolute", fontSize: 14, bottom: 1 }}>
                                                    {wtRoundValue(data.average_employee_tenure, 2) + " Years"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </CCol>



                                <CCol md="2">
                                    <div
                                        style={{ height: "fit-content", marginTop: 30 }}
                                    >
                                        <HighchartsReact highcharts={Highcharts} options={genderData} />
                                    </div>
                                </CCol>

                                <CCol md="4">
                                    <div
                                        style={{ height: "fit-content", marginTop: 30 }}
                                    >
                                        <HighchartsReact highcharts={Highcharts} options={statusChartConfig} />
                                    </div>

                                </CCol>

                                <CCol md="4">
                                    <div
                                        style={{ height: "fit-content", marginTop: 30 }}
                                    >
                                        <HighchartsReact highcharts={Highcharts} options={attendanceTypesTodayDataChartConfig} />
                                    </div>

                                </CCol>

                            </CRow>

                            <br />

                            <CRow>

                                <CCol md="12">

                                    <div
                                        style={{ height: "fit-content" }}
                                    >
                                        <HighchartsReact highcharts={Highcharts} options={attendanceDistributionByDepartmentChartConfig} />
                                    </div>

                                </CCol>

                            </CRow>



                            <br />

                            <CRow>

                                {/* <CCol md="6">

                                    <div
                                        style={{ height: "fit-content" }}
                                    >
                                        <HighchartsReact highcharts={Highcharts} options={genderByDepartmentChartConfig} />
                                    </div>

                                </CCol> */}
                                <CCol md="6">

                                    <div
                                        style={{ height: "fit-content" }}
                                    >
                                        <HighchartsReact highcharts={Highcharts} options={performanceEvaluationStatusChartConfig} />
                                    </div>

                                </CCol>
                                <CCol md="6">

                                    <div
                                        style={{ height: "fit-content" }}
                                    >
                                        <HighchartsReact highcharts={Highcharts} options={employeeDistributionByDepartment} />
                                    </div>

                                </CCol>
                            </CRow>

                            <br />

                            <CRow>
                                <CCol md="8">
                                    <div
                                        style={{ height: "fit-content", backgroundColor: "#fff", height: 400 }}
                                    >
                                        <h5 style={{ fontWeight: 600, color: "#1C6BB8", padding: 20 }}> Employees With Upcoming Birthdays</h5>
                                        <Table
                                            dataSource={data.employees_with_upcoming_birthdays}
                                            columns={employeeFieldsUpcomingBirthdays}
                                            pagination={{ defaultPageSize: 5, position: ['bottomCenter'] }}
                                            size="small"
                                            style={{ textAlign: 'center' }}
                                        />
                                    </div>
                                </CCol>
                                <CCol md="4">
                                    <div
                                        style={{ height: "fit-content" }}
                                    >
                                        <HighchartsReact highcharts={Highcharts} options={maritalStatusDistributionChartConfig} />
                                    </div>

                                </CCol>

                            </CRow>
                            <br />

                            <CRow>
                                <CCol md="12">
                                    <div
                                        style={{ height: "fit-content", backgroundColor: "#fff", height: 400 }}
                                    >
                                        <h5 style={{ fontWeight: 600, color: "#1C6BB8", padding: 20 }}> Employees With Upcoming Anniversaries</h5>
                                        <Table
                                            dataSource={data.employees_with_upcoming_anniversaries}
                                            columns={employeeFieldsUpcomingAniversaries}
                                            pagination={{ defaultPageSize: 5, position: ['bottomCenter'] }}
                                            size="small"
                                            style={{ textAlign: 'center' }}
                                        />
                                    </div>
                                </CCol>
                            </CRow>

                            <br />

                            <CRow>
                                <CCol md="6">
                                    <div
                                        style={{ height: "fit-content", backgroundColor: "#fff", height: 400 }}
                                    >
                                        <h5 style={{ fontWeight: 600, color: "#1C6BB8", padding: 20 }}> Employees Approaching Cnic Expiry</h5>
                                        <Table
                                            dataSource={data.employees_approaching_expiry_cnic}
                                            columns={employeeFieldsApproachingCnicExpiry}
                                            pagination={{ defaultPageSize: 5, position: ['bottomCenter'] }}
                                            size="small"
                                            style={{ textAlign: 'center' }}
                                        />
                                    </div>
                                </CCol>

                                <CCol md="6">
                                    <div
                                        style={{ height: "fit-content", backgroundColor: "#fff", height: 400 }}
                                    >
                                        <h5 style={{ fontWeight: 600, color: "#1C6BB8", padding: 20 }}> Employees Joined Last Three Months</h5>
                                        <Table
                                            dataSource={data.employees_joined_last_three_months}
                                            columns={employeeFieldsUpcomingAniversaries}
                                            pagination={{ defaultPageSize: 5, position: ['bottomCenter'] }}
                                            size="small"
                                            style={{ textAlign: 'center' }}
                                        />
                                    </div>
                                </CCol>
                            </CRow>

                        </CCol>
                    </CRow>
                }
            </div >
        )
    }
}
export default HRDashboard;