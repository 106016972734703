import React, { useEffect } from 'react'
import { DatePicker, Space } from 'antd'
import moment from 'moment';
import dayjs from 'dayjs';

const WidgetDatePicker = ({ size = 'large', value, setDate, placeholder = "Select Date", className = "",disabledDates,disabled }) => {
  const dateFormat = 'YYYY-MM-DD';
  const onChange = (date, dateString) => {
    setDate(dateString)
  }
  if (className === 'is-invalid') {
    className = 'error'
  }
  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <DatePicker
          onChange={onChange}
          size={size}
          defaultValue={value ? dayjs(value, dateFormat) : ''}
          style={{ width: '100%' }}
          placeholder={placeholder}
          status={className}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode
          }}
          disabledDate={disabledDates}
          disabled={disabled}
        />
      </Space>
    </>
  )
}

export default WidgetDatePicker
