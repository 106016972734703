import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Row, Col, Spin, Table } from 'antd';
import { wtGetFromLocalStorage } from 'src/helpers/commons/LocalStorage';
import BaseListing from 'src/BaseListings';
import WidgetSelector from 'src/widgets/Selectors/WidgetSelector';
import { STATUS_NAME, STATUS_POSTED, VOUCHER_STATUS_DROPDOWN_OPTIONS } from 'src/models/vouchers';
import WidgetProjectSelector from 'src/widgets/Selectors/WidgetProjectSelector';
import { wtApiCall } from 'src/helpers/commons/ApiWrapper';
import WidgetBranchSelector from 'src/widgets/Selectors/WidgetBranchSelector'
import { lzNotifyErrorMessageFromResult } from 'src/helpers/commons/notifications';
import { wtFormatMoney } from 'src/helpers/commons/utils';
import { lastsFullYear, wtGetDateToday } from 'src/helpers/commons/moment';
import WidgetDatePicker from 'src/widgets/DatePickers/WidgetDatePicker';
import { TYPE_GROUP, TYPE_TOTAL } from 'src/models/pnlConfig';
import { CaretDownFilled, CaretRightFilled } from '@ant-design/icons'

const ProfitAndLoss = () => {
    const Base = new BaseListing('profit_and_loss')
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm();

    const [projects, setProjects] = useState([])
    const [branches, setBranches] = useState([])

    const [projectId, setProjectId] = useState()
    const [projectName, setProjectName] = useState('')

    const [voucherStatus, setVoucherStatus] = useState(STATUS_POSTED)
    const [voucherStatusName, setVoucherStatusName] = useState(STATUS_NAME[STATUS_POSTED])

    const [branchId, setBranchId] = useState(0)
    const [branchName, setBranchName] = useState('')
    const [profitAndLossData, setProfitAndLossData] = useState([])
    const [asOf, setAsOf] = useState(wtGetDateToday())

    const lastYearLastDate = lastsFullYear() + "-12-31";

    const companyName = wtGetFromLocalStorage("company_name");

    const [boldRows, setBoldRows] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);

    // Define columns of trial balance
    const profitAndLossHeaderColumns = [
        {
            title: <span style={{ fontSize: '0.8rem', marginLeft: '5%' }}>Account Description</span>,
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: 650,
            onCell: (record) => ({
                onClick: () => handleExpand(record),
                style: { cursor: 'pointer' }
            }),
            render: (text, record) => (
                <span style={{
                    fontWeight: (record.type === TYPE_TOTAL || boldRows.includes(record.key)) ? 'bold' : 'normal',
                    fontSize: (record.type === TYPE_TOTAL) ? "0.83rem" : "0.8rem",
                    cursor: "pointer"
                }}>
                    {text}
                </span>
            )
        },
        {
            title: <span style={{ fontSize: '0.8rem' }}>{"As Of " + asOf}</span>,
            align: 'right',
            children: [
                {
                    title: <span style={{ fontSize: '0.8rem' }}>Balance ( .Rs. )</span>,
                    dataIndex: 'current_year_balance',
                    key: 'current_year_balance',
                    align: 'right',
                    render: (text, record) => (
                        <span style={{
                            fontWeight: (record.type === TYPE_TOTAL || boldRows.includes(record.key)) ? 'bold' : 'normal',
                            fontSize: (record.type === TYPE_TOTAL) ? "0.83rem" : "0.8rem",
                        }}>
                            {getLink(record, text, 'asof')}
                        </span>
                    ),
                }
            ]
        },
        {
            title: <span style={{ fontSize: '0.8rem' }}>{"As Of " + lastYearLastDate}</span>,
            align: 'right',
            children: [
                {
                    title: <span style={{ fontSize: '0.8rem' }}>Balance ( .Rs. )</span>,
                    dataIndex: 'last_year_balance',
                    key: 'last_year_balance',
                    align: 'right',
                    render: (text, record) => (
                        <span style={{
                            fontWeight: (record.type === TYPE_TOTAL || boldRows.includes(record.key)) ? 'bold' : 'normal',
                            fontSize: (record.type === TYPE_TOTAL) ? "0.83rem" : "0.8rem",
                        }}>
                            {getLink(record, text, 'last')}
                        </span>
                    ),
                }
            ]
        },
    ];


    const getLink = (record, text, key) => {
        if (record.type === TYPE_TOTAL || record.hasItems) {
            return wtFormatMoney(text, "", false, 2);
        }

        let fromDate = "2000-01-01";
        let toDate = "";

        if (key === 'asof') {
            toDate = asOf;
        }

        if (key === 'last') {
            toDate = lastYearLastDate;
        }

        const queryParams = "fromDate=" + fromDate + "&toDate=" + toDate + "&accountId=" + (+record.source_id) + "&voucherStatus=" + (+voucherStatus) + "&branchId=" + (+branchId) + "&branchName=" + branchName + "&projectId=" + (+projectId) + " ";
        return (
            <a href={'/reports?' + queryParams + '#code:account-statement'}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#000000" }}
            >
                {wtFormatMoney(text, "", true, 2)}
            </a>
        )
    }

    const changeValues = (key, val) => {

        //Set initial values
        setProfitAndLossData([]);

        if (key === 'branch_id') {
            setBranchId(val.value)
            setBranchName(val.label)
        }
        if (key === 'project_id') {
            setProjectId(val)
            if (val === '') {
                setProjectName('');
            }
        }
        if (key === 'voucher_status') {
            setVoucherStatus(val.value)
            setVoucherStatusName(val.label)
        }
        if (key === 'as_of') {
            setAsOf(val);
        }
    }

    useEffect(() => {
        Base.dropdownData('projects,branches', {}, [setProjects, setBranches], 1)
    }, [])

    const getReportData = () => {

        setIsLoading(true);
        wtApiCall(
            'reports/profit_and_loss',
            {
                as_of: asOf,
                last_year: lastYearLastDate,
                branch_id: branchId,
                project_id: projectId,
                voucher_status: voucherStatus
            },
            'get',
            (response) => {

                //set data
                if (response !== null) {
                    if (response.data !== null) {
                        if (response.data.hasOwnProperty('profit_and_loss_data')) {
                            setProfitAndLossData(response.data.profit_and_loss_data);
                        }
                        else {
                            //Set initial values
                            setProfitAndLossData([]);
                        }
                    }
                }

                setIsLoading(false);
            },
            (error) => {
                lzNotifyErrorMessageFromResult(error);
                setIsLoading(false);
            },
        );

    };

    const expandIcon = ({ expanded, onExpand, record }) => {
        if (record.hasItems && record.type === TYPE_GROUP) {
            return (
                <span style={{ marginRight: '2%' }}>
                    {expanded ? (
                        <CaretDownFilled style={{ color: "#706f71", fontSize: 12 }} onClick={(e) => onExpand(record, e)} />
                    ) : (
                        <CaretRightFilled style={{ color: "#706f71", fontSize: 12 }} onClick={(e) => onExpand(record, e)} />
                    )}
                </span>
            )
        } else {
            return <span style={{ marginRight: '3%' }}></span>
        }
    };

    const handleExpand = (record) => {
        const key = record.key;
        if (record.type === TYPE_GROUP) {
            const isExpanded = expandedRows.includes(key);
            if (record.hasItems) {
                if (isExpanded) {
                    setExpandedRows(expandedRows.filter(rowKey => rowKey !== key));
                    setBoldRows(prevBoldRows => prevBoldRows.filter(key => key !== record.key)); // Remove the key from boldRows
                } else {
                    setExpandedRows([...expandedRows, key]);
                    setBoldRows(prevBoldRows => [...prevBoldRows, record.key]);
                }
            }
        }
    };


    return (
        <div className='container-fluid' style={{
            padding: "1em", backgroundColor: "white", minHeight: "100vh", fontFamily: "Helvetica",
        }}>
            <Form
                layout={'vertical'}
                form={form}
                onFinish={getReportData}
                style={{ maxWidth: "100%", margin: '0 auto' }}
            >
                <Card title={<span style={{ fontSize: '22px', marginLeft: "40%" }}>{companyName + " Profit & Loss"}</span>} style={{ width: "100%" }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={4} sm={2} md={1}></Col>
                        <Col xs={15} sm={10} md={4}>
                            <label>As Of: *</label>
                            <WidgetDatePicker
                                value={asOf}
                                setDate={setAsOf}
                                placeholder="As Of"
                            />
                        </Col>
                        <Col xs={12} sm={8} md={4}>
                            <label>Voucher Status: *</label>
                            <WidgetSelector
                                selectedValue={voucherStatus}
                                displayValue={voucherStatusName}
                                options={VOUCHER_STATUS_DROPDOWN_OPTIONS}
                                showCloudIcon={false}
                                size={"md"}
                                onChange={(values) => changeValues("voucher_status", values)}
                            />
                        </Col>
                        <Col xs={18} sm={12} md={6}>
                            <label>Branch:</label>
                            <WidgetBranchSelector
                                value={branchId}
                                displayValue={branchName}
                                branches={branches}
                                placeholder="Select Branch"
                                onChange={(values) => changeValues('branch_id', values)}
                                reloadDropdownData={() => Base.dropdownData('branches', {}, [setBranches], 1)}
                            />

                        </Col>
                        <Col xs={18} sm={12} md={6}>
                            <label>Project: </label>
                            <WidgetProjectSelector
                                onChange={(value) => changeValues('project_id', value)}
                                data={projects}
                                value={projectId}
                                displayValue={projectName}
                                height={35}
                                reloadDropdownData={() => Base.dropdownData('projects', {}, [setProjects], 1)}
                            />
                        </Col>

                        <Col xs={8} sm={4} md={2}>
                            <Button style={{ marginTop: 35 }} type="primary" htmlType="submit" disabled={isLoading ? true : false}>Search</Button>
                        </Col>
                    </Row>
                </Card>

                <br /><br />
                <div style={{ overflow: 'auto', margin: "auto", width: "60%" }}>
                    <Table
                        columns={profitAndLossHeaderColumns}
                        dataSource={profitAndLossData}
                        pagination={false}
                        expandable={{ expandedRowKeys: expandedRows, expandIcon: expandIcon }}
                        scroll={{ x: 'max-content' }}
                        bordered
                        size="small"
                        loading={{ indicator: <div><Spin /></div>, spinning: isLoading }}
                    />
                </div>

            </Form >
        </div >
    );
};

export default ProfitAndLoss;
