import { CButton, CCard, CCardBody, CCol, CForm, CInputGroup, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import BaseListing from 'src/BaseListings'
import { wtApiCall, wtApiPostWithContentTypeHandling } from '../../helpers/commons/ApiWrapper'
import { lzNotifyErrorMessageFromResult } from '../../helpers/commons/notifications'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import DataReportTable from './DataReportTable'
import Loading from 'src/helpers/commons/loader'
import WidgetDriverSelector from 'src/widgets/Selectors/WidgetDriverSelector'
import WidgetVehicleSelector from 'src/widgets/Selectors/WidgetVehicleSelector'
import { wtGetDateToday } from 'src/helpers/commons/moment'
import WidgetDatePicker from 'src/widgets/DatePickers/WidgetDatePicker'

export default function EmployeeOnMoveReport() {
  const Base = new BaseListing()
  const [isLoading, setIsLoading] = useState(false)
  const [Vehicles, setVehicles] = useState([])
  const [VehicleId, setVehicleId] = useState(0)
  const [VehicleName, setVehicleName] = useState('')
  const [FromDate, setFromDate] = useState(wtGetDateToday())
  const [ToDate, setToDate] = useState(wtGetDateToday())
  const [Drivers, setDrivers] = useState([])
  const [DriverId, setDriverId] = useState(0)
  const [DriverName, setDriverName] = useState('')
  const [errors, setErrors] = useState([])
  const [isMore, setIsMore] = useState(false)
  const [reportData, setReportData] = useState([])

  const changeValues = (key, val) => {
    if (key === 'vehicle_id') {
      setVehicleId(val.value)
      setVehicleName(val.label)
    }
    if (key === 'driver_id') {
      setDriverId(val.value)
      setDriverName(val.label)
    }
  }

  const getReportData = () => {
    setIsLoading(true)
    wtApiCall(
      'reports/employee_on_move_report',
      {
        from_date: FromDate,
        to_date: ToDate,
        vehicle_id: VehicleId,
        driver_id: DriverId,
      },
      'get',
      (response) => {
        const { data } = response
        setReportData(data)
        setIsLoading(false)
      },
      (error) => {
        lzNotifyErrorMessageFromResult(error)
      },
    )
  }

  useEffect(() => {
    Base.dropdownData('vehicles,drivers', {}, [setVehicles, setDrivers], 1)
  }, [])
  return (
    <>
      <div style={{ padding: '2%' }}>
        <center>
          <h4>Employee On Move Report</h4>
        </center>
        <CRow style={{ width: '100%' }}>
          <CCol md={12}>
            <CCard className="card-dark">
              <CCardBody style={{ backgroundColor: '#f7f9fa' }}>
                <br />
                <CForm>
                  <CRow>
                    {/* <CCol md={1}></CCol> */}
                    <CCol md={1}>
                      <CInputGroup>
                        <label style={{ fontSize: '140%', marginTop: '50px' }}>Filters:</label>
                      </CInputGroup>
                    </CCol>
                    <CCol md={3}>
                      <div className="form-group">
                        <label>From Date</label>
                        <WidgetDatePicker
                          value={FromDate}
                          setDate={setFromDate}
                          placeholder="Select From Date"
                          className={errors.from_date ? 'is-invalid' : ''}
                        />
                        {errors.from_date && <ErrorMessage messages={errors.from_date} />}
                      </div>
                    </CCol>
                    <CCol md={3}>
                      <div className="form-group">
                        <label>To Date</label>
                        <WidgetDatePicker
                          value={ToDate}
                          setDate={setToDate}
                          placeholder="Select To Date"
                          className={errors.to_date ? 'is-invalid' : ''}
                        />
                        {errors.to_date && <ErrorMessage messages={errors.to_date} />}
                      </div>
                    </CCol>
                    <CCol md={3}>
                      <CInputGroup>
                        <label>Vehicle No.</label>
                        <WidgetVehicleSelector
                          vehicles={Vehicles}
                          displayValue={VehicleName}
                          value={VehicleId}
                          placeholder="Select Vehicle No."
                          onChange={(values) => changeValues('vehicle_id', values)}
                          reloadDropdownData={() =>
                            Base.dropdownData('vehicles', {}, [setVehicles], 1)
                          }
                        />
                        {errors.vehicle_id && <ErrorMessage messages={errors.vehicle_id} />}
                      </CInputGroup>
                    </CCol>

                    <CCol md={2}>
                      <CButton
                        color="warning"
                        className="text-white"
                        onClick={() => setIsMore(!isMore)}
                        style={{ marginBottom: '20px', marginTop: '20px' }}
                      >
                        More
                      </CButton>

                      <CButton
                        color="primary"
                        onClick={() => getReportData()}
                        style={{ marginLeft: '1%' }}
                      >
                        Search
                      </CButton>
                      {/* <CButton
                        color="success"
                        className="text-white"
                        style={{ marginLeft: '1%' }}
                        onClick={exportCsvToLoadInits}
                      >
                        Export
                      </CButton>
                      <CButton
                        color="danger"
                        className="text-white"
                        style={{ marginLeft: '1%' }}
                        onClick={generatePDF}
                      >
                        PDF
                      </CButton> */}
                    </CCol>
                  </CRow>
                  {isMore && (
                    <>
                      <CRow>
                        <CCol md={1}></CCol>

                        <CCol md={3}>
                          <CInputGroup>
                            <label>Driver</label>
                            <WidgetDriverSelector
                              drivers={Drivers}
                              displayValue={DriverName}
                              value={DriverId}
                              placeholder="Select Driver Name"
                              onChange={(values) => changeValues('driver_id', values)}
                              reloadDropdownData={() =>
                                Base.dropdownData('drivers', {}, [setDrivers], 1)
                              }
                            />
                            {errors.driver_id && <ErrorMessage messages={errors.driver_id} />}
                          </CInputGroup>
                        </CCol>
                      </CRow>
                    </>
                  )}
                  <br />
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <br />
        <br />
        {isLoading ? (
          Loading
        ) : (
          <>
            {reportData.length > 0 ? (
              <DataReportTable
                reportData={reportData}
                setReportData={(data) => setReportData(data)}
                isLoading={isLoading}
              />
            ) : (
              <center style={{ fontSize: '19px', fontWeight: 'bold' }}>No record to show...</center>
            )}
          </>
        )}
      </div>
    </>
  )
}
