import React from 'react'

export default function Difference({ record, dates }) {
    const getDifference = (date) => {
        let difference = '-';
        record.map((item) => {
            if (item.hasOwnProperty(date)) {
                difference = item[date]
            }
        })
        return difference
    }
    return (
        <>
            {dates.map((item) => {
                const difference = getDifference(item);
                let color;
                if (difference === 'A' || parseFloat(difference) < 0) {
                    color = 'red'
                }
                if(difference === 'L'){
                    color='blue'
                }
                if (typeof difference === 'number' && !Number.isNaN(difference) && parseFloat(difference) > 0) {
                    color = 'green'
                }
                return (<td style={{ color: color }}>{difference}</td>)
            })}
        </>
    )
}
