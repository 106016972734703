// Source Types

export const BANK_TRANSACTION_TYPE_BANK_TO_BANK = 1
export const BANK_TRANSACTION_TYPE_CASH_TO_CASH = 2
export const BANK_TRANSACTION_TYPE_BANK_TO_CASH = 3
export const BANK_TRANSACTION_TYPE_CASH_TO_BANK = 4

export const BANK_TRANSACTION_TYPE_NAMES = {
    0: '',
    1: 'Bank To Bank',
    2: 'Cash To Cash',
    3: 'Bank To Cash',
    4: 'Cash To Bank'
}

export const BANK_TRANSACTION_TYPE_DROPDOWN_OPTIONS = [
    { value: BANK_TRANSACTION_TYPE_BANK_TO_BANK, label: BANK_TRANSACTION_TYPE_NAMES[BANK_TRANSACTION_TYPE_BANK_TO_BANK] },
    { value: BANK_TRANSACTION_TYPE_CASH_TO_CASH, label: BANK_TRANSACTION_TYPE_NAMES[BANK_TRANSACTION_TYPE_CASH_TO_CASH] },
    { value: BANK_TRANSACTION_TYPE_BANK_TO_CASH, label: BANK_TRANSACTION_TYPE_NAMES[BANK_TRANSACTION_TYPE_BANK_TO_CASH] },
    { value: BANK_TRANSACTION_TYPE_CASH_TO_BANK, label: BANK_TRANSACTION_TYPE_NAMES[BANK_TRANSACTION_TYPE_CASH_TO_BANK] },
];

//Status

// status
export const BANK_TRANSACTION_STATUS_JUST_PLACED = 1;
export const BANK_TRANSACTION_STATUS_APPROVED = 2;
export const BANK_TRANSACTION_STATUS_POSTED = 3;
export const BANK_TRANSACTION_STATUS_CANCELLED = 4;

export const BANK_TRANSACTION_STATUS_NAME = {
    1: 'Just Placed',
    2: 'Approved',
    3: 'Posted',
    4: 'Cancelled',
};

export const BANK_TRANSACTION_STATUS_DROPDOWN_OPTIONS = [
    { value: BANK_TRANSACTION_STATUS_JUST_PLACED, label: BANK_TRANSACTION_STATUS_NAME[BANK_TRANSACTION_STATUS_JUST_PLACED] },
    { value: BANK_TRANSACTION_STATUS_APPROVED, label: BANK_TRANSACTION_STATUS_NAME[BANK_TRANSACTION_STATUS_APPROVED] },
    { value: BANK_TRANSACTION_STATUS_POSTED, label: BANK_TRANSACTION_STATUS_NAME[BANK_TRANSACTION_STATUS_POSTED] },
    { value: BANK_TRANSACTION_STATUS_CANCELLED, label: BANK_TRANSACTION_STATUS_NAME[BANK_TRANSACTION_STATUS_CANCELLED] },
];