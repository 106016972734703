import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import { wtFormatRoundedValue } from 'src/helpers/commons/utils'
import {
  SOURCE_TYPE_DEFAULT,
  STATUS_CANCELLED,
  VOUCHER_TYPE_BANK_PAYMENT_VOUCHER,
  VOUCHER_TYPE_BANK_RECEIPT_VOUCHER,
  VOUCHER_TYPE_CASH_PAYMENT_VOUCHER,
  VOUCHER_TYPE_CASH_RECEIPT_VOUCHER,
  VOUCHER_TYPE_JOURNAL_VOUCHER,
  VOUCHER_TYPE_NAMES,
} from 'src/models/vouchers'
import QRCode from 'qrcode'
import { FRONT_BASE_URL } from 'src/config/Request'
import { wtGetFromLocalStorage } from 'src/helpers/commons/LocalStorage'
import { wtGetDateOnly, wtGetDateTimeFormat } from 'src/helpers/commons/moment'
import JsBarcode from 'jsbarcode'

const user = wtGetFromLocalStorage('authUser')
const VoucherPDF = (pdfData) => {
  const doc = new jsPDF()
  const logo = 'assets/wil-icon.png'
  let yPosition = 69.5

  const pageWidth = doc.internal.pageSize.width

  const addLogoToAllPages = () => {
    const pageCount = doc.internal.getNumberOfPages()
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      doc.setTextColor('#3c4b64')
      doc.setFont('Helvetica', 'bold')

      const pageWidth = doc.internal.pageSize.width
      doc.setTextColor('#3c4b64')
      doc.setFont('Helvetica', 'bold') //Header middle branch logo

      doc.addImage(logo, 'PNG', pageWidth - 200, 4, 25, 25)
      doc.line(pageWidth - 173, 7, pageWidth - 173, 27) // Adjust the coordinates as needed

      doc.setFontSize(11)
      doc.text('WAH', pageWidth - 171, 10, { align: 'left' })
      doc.setFontSize(11)
      doc.setFont('Helvetica')
      doc.text('INDUSTRIES', pageWidth - 171, 15)
      doc.text('LIMITED', pageWidth - 171, 20)
      doc.setFontSize(9)
      doc.text('www.wahindustries.com', pageWidth - 171, 25)
      doc.setFontSize(14)
      doc.text(VOUCHER_TYPE_NAMES[pdfData.voucher.type], pageWidth - 12, 10, { align: 'right' })
    }
  }

  addLogoToAllPages()

  doc.setFontSize(12)
  doc.setTextColor('#000000')
  doc.setFont('Helvetica', 'bold')

  doc.text('Voucher #', pageWidth - 90, 35, { align: 'left' })
  doc.setFont('Helvetica', 'bold')
  doc.setTextColor('#0202f7')
  doc.text(`${pdfData.voucher.voucher_no ? `${pdfData.voucher.type_short_name}-${pdfData.voucher.company_voucher_no}-${pdfData.voucher.voucher_no}` : ''}`, pageWidth - 67, 35, {
    align: 'left',
  })

  if (pdfData.voucher.status === STATUS_CANCELLED) {
    const watermarkFontSize = 100
    doc.setTextColor(255, 145, 145) // Set the color of the watermark text
    doc.setFont('Helvetica', 'bold') //Header middle branch logo
    doc.setFontSize(watermarkFontSize)
    doc.text(140, 200, 'CANCELLED', null, 45, 'center')
  }

  //////left side///////
  doc.setFontSize(10)
  doc.setTextColor('#000000')
  doc.setFont('Helvetica', 'bold')

  doc.text('Type:', pageWidth - 195, 45, { align: 'left' })
  doc.setFont('Helvetica', 'normal')
  doc.setTextColor('#0202f7')
  doc.text(
    `${pdfData.voucher.type ? VOUCHER_TYPE_NAMES[pdfData.voucher.type] : ''}`,
    pageWidth - 183,
    45,
    {
      align: 'left',
    },
  )

  doc.setTextColor('#000000')
  doc.setFont('Helvetica', 'bold')
  doc.text('Voucher Date:', pageWidth - 195, 50)
  doc.setFont('Helvetica', 'normal')
  doc.setTextColor('#0202f7')
  doc.text(`${pdfData.voucher.voucher_date ? pdfData.voucher.voucher_date : ''}`, pageWidth - 165, 50)



  if (pdfData.voucher.cheque_no) {
    doc.setTextColor('#000000')
    doc.setFont('Helvetica', 'bold')
    doc.text('Cheque No:', pageWidth - 195, 55)
    doc.setFont('Helvetica', 'normal')
    doc.setTextColor('#0202f7')
    doc.text(
      `${pdfData.voucher.cheque_no ? pdfData.voucher.cheque_no : ''}`,
      pageWidth - 175,
      55,
    )
  }

  if (pdfData.voucher.reference) {
    doc.setTextColor('#000000')
    doc.setFont('Helvetica', 'bold')
    doc.text('Pay to:', pageWidth - 195, 60)
    doc.setFont('Helvetica', 'normal')
    doc.setTextColor('#0202f7')
    doc.text(
      `${pdfData.voucher.reference ? pdfData.voucher.reference : ''}`,
      pageWidth - 182,
      60,
    )
  }

  /////right side//////
  doc.setFontSize(10)
  doc.setTextColor('#000000')
  doc.setFont('Helvetica', 'bold')
  doc.text('Branch:', pageWidth - 90, 45)
  doc.setFont('Helvetica', 'normal')
  doc.setTextColor('#0202f7')
  doc.text(`${pdfData.voucher.branch ? pdfData.voucher.branch.name : ''}`, pageWidth - 76, 45)

  doc.setTextColor('#000000')
  doc.setFont('Helvetica', 'bold')
  doc.text('Creation Date:', pageWidth - 90, 50)
  doc.setFont('Helvetica', 'normal')
  doc.setTextColor('#0202f7')
  doc.text(`${pdfData.voucher.created_at ? wtGetDateTimeFormat(pdfData.voucher.created_at) : ''}`, pageWidth - 62, 50)

  if (pdfData.voucher.cheque_date) {
    doc.setTextColor('#000000')
    doc.setFont('Helvetica', 'bold')
    doc.text('Cheque Date:', pageWidth - 90, 55)
    doc.setFont('Helvetica', 'normal')
    doc.setTextColor('#0202f7')
    doc.text(`${pdfData.voucher.cheque_date ? wtGetDateOnly(pdfData.voucher.cheque_date) : ''}`, pageWidth - 62, 55)
  }


  ////////////////////// Bank Account Details ////////////////////////
  if (
    pdfData.voucher.type === VOUCHER_TYPE_BANK_PAYMENT_VOUCHER ||
    pdfData.voucher.type === VOUCHER_TYPE_BANK_RECEIPT_VOUCHER
  ) {
    if (pdfData.voucher.source_type === SOURCE_TYPE_DEFAULT) {

      doc.setFontSize(13)
      doc.setTextColor('#0202f7')
      doc.setFont('Helvetica', 'bold')
      doc.text('Bank Account Details:', pageWidth - 195, 66)
      const textWidth = 48
      doc.setLineWidth(0.2)
      doc.line(pageWidth - 195, 67, pageWidth - 195 + textWidth, 67)

      doc.setFontSize(10)
      doc.setTextColor('#000000')
      doc.setFont('Helvetica', 'bold')

      doc.text('Bank Detail:', pageWidth - 195, 75, { align: 'left' })
      doc.setFont('Helvetica', 'normal')
      doc.text(pdfData.voucher.entry_types.bank_name, pageWidth - 173, 75, { align: 'left' })
      doc.setTextColor('#000000')
      doc.setFont('Helvetica', 'bold')
      doc.text('Branch Name:', pageWidth - 195, 80)
      doc.setFont('Helvetica', 'normal')
      doc.text(
        pdfData.voucher.entry_types.bank_branch_name
          ? pdfData.voucher.entry_types.bank_branch_name
          : '',
        pageWidth - 169,
        80,
      )
      doc.setTextColor('#000000')
      doc.setFont('Helvetica', 'bold')
      doc.text('Bank Account No:', pageWidth - 195, 85)
      doc.setFont('Helvetica', 'normal')
      doc.text(
        pdfData.voucher.entry_types.account_no ? pdfData.voucher.entry_types.account_no : '',
        pageWidth - 162,
        85,
      )

      doc.setFontSize(14)

      const Table1 = [
        [
          {
            content: '',
          },
        ],

        [
          {
            content: '',
          },
        ],
        [
          {
            content: '',
          },
        ],
      ]

      doc.autoTable({
        startY: yPosition,
        margin: { top: 20 },
        body: Table1, // Body rows for second table
        theme: 'plain',
        tableLineColor: '#000000',
        tableLineWidth: 0.2,
        tableWidth: 'auto', // Set table width automatically
        styles: {
          lineColor: '#ffffff',
          lineWidth: 0.2,
          halign: 'center',
          font: 'helvetica',
          cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        },
        didDrawPage: function (data) { },
      })
    }
  }


  //////////////////////////// Cash Account Details //////////////////////////
  if (
    pdfData.voucher.type === VOUCHER_TYPE_CASH_PAYMENT_VOUCHER ||
    pdfData.voucher.type === VOUCHER_TYPE_CASH_RECEIPT_VOUCHER
  ) {
    if (pdfData.voucher.source_type === SOURCE_TYPE_DEFAULT) {

      doc.setFontSize(13)
      doc.setTextColor('#0202f7')
      doc.setFont('Helvetica', 'bold')
      doc.text('Cash Account Details:', pageWidth - 195, 66)
      const textWidth = 48
      doc.setLineWidth(0.2)
      doc.line(pageWidth - 195, 67, pageWidth - 195 + textWidth, 67)

      doc.setFontSize(10)
      doc.setTextColor('#000000')
      doc.setFont('Helvetica', 'bold')

      doc.text('Account Name:', pageWidth - 195, 75, { align: 'left' })
      doc.setFont('Helvetica', 'normal')
      doc.text(pdfData.voucher.entry_types.name, pageWidth - 167, 75, { align: 'left' })
      // doc.setTextColor('#000000')
      // doc.setFont('Helvetica', 'bold')
      // doc.text('Branch Name:', pageWidth - 195, 80)
      // doc.setFont('Helvetica', 'normal')
      // doc.text(
      //   pdfData.voucher.entry_types.bank_branch_name
      //     ? pdfData.voucher.entry_types.bank_branch_name
      //     : '',
      //   pageWidth - 169,
      //   80,
      // )
      // doc.setTextColor('#000000')
      // doc.setFont('Helvetica', 'bold')
      // doc.text('Bank Account No:', pageWidth - 195, 85)
      // doc.setFont('Helvetica', 'normal')
      // doc.text(
      //   pdfData.voucher.entry_types.account_no ? pdfData.voucher.entry_types.account_no : '',
      //   pageWidth - 162,
      //   85,
      // )

      doc.setFontSize(14)

      const Table1 = [
        [
          {
            content: '',
          },
        ],
        [
          {
            content: '',
          },
        ],
      ]

      doc.autoTable({
        startY: yPosition,
        margin: { top: 20 },
        body: Table1, // Body rows for second table
        theme: 'plain',
        tableLineColor: '#000000',
        tableLineWidth: 0.2,
        tableWidth: 'auto', // Set table width automatically
        styles: {
          lineColor: '#ffffff',
          lineWidth: 0.2,
          halign: 'center',
          font: 'helvetica',
          cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        },
        didDrawPage: function (data) { },
      })
    }
  }


  const Table2 = [
    [
      {
        content: 'GL Entry',
        colSpan: 6,
        styles: { halign: 'center', fontStyle: 'bold', textColor: '#0202f7', fontSize: 14 },
      },
    ],

    [
      {
        content: 'GL Code',
        styles: { fontStyle: 'bold', halign: 'center' },
      },
      { content: 'Account', styles: { fontStyle: 'bold', halign: 'left' } },
      { content: 'Project', styles: { fontStyle: 'bold', halign: 'left' } },
      { content: 'Debit', styles: { fontStyle: 'bold', halign: 'center' } },
      {
        content: 'Credit',
        styles: { fontStyle: 'bold', halign: 'center' },
      },
      {
        content: 'Memo',
        styles: { fontStyle: 'bold', halign: 'left' },
      },
    ],
  ]

  const Table2Details = () => {
    let voucher_details = []
    if (pdfData && pdfData.voucher_detail && pdfData.voucher_detail.length) {
      pdfData.voucher_detail.map((item) =>
        voucher_details.push([
          { content: item.account.gl_code, styles: { textColor: 'green' } },
          item.account.gl_account_name,
          item.project.project_name,
          { content: item.debit !== 0 ? wtFormatRoundedValue(item.debit, "") : '-', styles: { halign: 'center' } },
          { content: item.credit !== 0 ? wtFormatRoundedValue(item.credit, "") : '-', styles: { halign: 'center' } },
          item.narration,
        ]),
      )
    }

    return voucher_details
  }
  const gapBetweenTables = doc.autoTable.previous.finalY + 2
  let startYForTable12 = yPosition;
  if (pdfData.voucher.source_type === SOURCE_TYPE_DEFAULT) {

    startYForTable12 =
      pdfData.voucher.type !== VOUCHER_TYPE_JOURNAL_VOUCHER
        ? gapBetweenTables
        : yPosition
  }

  let totalDebit = 0
  let totalCredit = 0

  if (pdfData && pdfData.voucher_detail && pdfData.voucher_detail.length) {
    pdfData.voucher_detail.forEach((item) => {
      totalDebit += parseFloat(item.debit) || 0
      totalCredit += parseFloat(item.credit) || 0
    })
  }
  doc.autoTable({
    startY: startYForTable12,
    margin: { top: 20 },
    head: Table2,
    body: Table2Details(),
    foot: [
      [
        {
          content: 'Total',
          colSpan: 3,
          styles: { halign: 'right', textColor: 'blue', fontSize: 12, fontStyle: 'bold' },
        },
        {
          content: wtFormatRoundedValue(totalDebit.toFixed(2), "")
            ? `${wtFormatRoundedValue(totalDebit.toFixed(2), "")}`
            : '',
          styles: { textColor: 'blue', fontSize: 12, fontStyle: 'bold', halign: 'center' },
        },
        {
          content: wtFormatRoundedValue(totalCredit.toFixed(2), "")
            ? `${wtFormatRoundedValue(totalCredit.toFixed(2), "")}`
            : '',
          styles: { textColor: 'blue', fontSize: 12, fontStyle: 'bold', halign: 'center' },
        },

        {
          content: '',
        },
      ],
    ],
    theme: 'plain',
    tableLineColor: '#3c4b64',
    tableLineWidth: 0.2,
    tableWidth: 'auto', // Set table width automatically
    headStyles: { fillColor: [217, 218, 219] },
    styles: {
      lineColor: '#3c4b64',
      lineWidth: 0.2,
      // halign: 'center',
      font: 'helvetica',
    },
    didDrawPage: function (data) { },
  })

  const Table3 = [
    [
      {
        content: 'Amount in words :',
        styles: { halign: 'left', fontStyle: 'bold', fontSize: 9 },
      },
      {
        content: ` ${pdfData.voucher.amount_in_words ? pdfData.voucher.amount_in_words : ''
          } Rupees only.`,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
          textColor: '#0202f7',
          fontSize: 9,
        },
      },
    ],
  ]

  const gapBetweenTable23 = 3
  const startYForTable23 = doc.autoTable.previous.finalY + gapBetweenTable23
  doc.autoTable({
    startY: startYForTable23,
    margin: { top: 20 },
    body: Table3, // Body rows for second table
    theme: 'plain',
    tableLineColor: '#ffffff',
    tableLineWidth: 0,
    tableWidth: 'auto', // Set table width automatically
    styles: {
      lineColor: '#ffffff',
      lineWidth: 0,
      halign: 'center',
      font: 'helvetica',
    },
    columnStyles: {
      0: { columnWidth: 32 },
      1: { columnWidth: 150 },
    },
    didDrawPage: function (data) { },
  })

  doc.setTextColor('#000000')
  doc.setFont('Helvetica', 'bold')
  doc.setFontSize(10)

  const gapBetweenTable = 9
  const startYForTable = doc.autoTable.previous.finalY + gapBetweenTable

  const PreparedBy = 'Prepared By'
  const ApprovedBy = 'Approved By'
  const PostedBy = 'Posted By'

  const PreparedByValue = pdfData.voucher.created_by_id ? pdfData.voucher.created_by.name : ''
  const ApprovedByValue = pdfData.voucher.approved_by_id ? pdfData.voucher.approved_by.name : ''
  const PostedByValue = pdfData.voucher.posted_by_id ? pdfData.voucher.posted_by.name : ''

  // Coordinates for PreparedBy
  const infoX = 16
  const infoY = startYForTable + 9

  // Coordinates for PostedBy
  const rightInfoX = 145
  const rightInfoY = startYForTable + 9

  // Coordinates for ApprovedBy
  const centerInfoX = 80
  const centerInfoY = startYForTable + 9

  const centerText = (text, x, width) => {
    const textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor
    const adjustedX = x + (width - textWidth) / 2
    return adjustedX
  }

  doc.setFontSize(9)

  // Display PreparedByValue above the line
  const lineYAbovePreparedBy = infoY - 4
  doc.setTextColor('#0501ff')
  doc.text(PreparedByValue, centerText(PreparedByValue, infoX, 50), lineYAbovePreparedBy)

  // Draw a horizontal line above PreparedBy details
  const lineYAbovePreparedByDetails = infoY - 3
  doc.setLineWidth(0.4)
  doc.line(infoX - 0, lineYAbovePreparedByDetails, infoX + 50, lineYAbovePreparedByDetails)

  doc.setTextColor('#000000')
  doc.text(PreparedBy, centerText(PreparedBy, infoX, 50), infoY)

  // Display PostedByValue above the line
  const lineYAboveRightInfo = rightInfoY - 4
  doc.setTextColor('#0501ff')
  doc.text(PostedByValue, centerText(PostedByValue, rightInfoX, 50), lineYAboveRightInfo)

  // Draw a horizontal line above right side details
  const lineYAboveRightInfoDetails = rightInfoY - 3
  doc.setLineWidth(0.4)
  doc.line(rightInfoX - 0, lineYAboveRightInfoDetails, rightInfoX + 50, lineYAboveRightInfoDetails)

  doc.setTextColor('#000000')
  doc.text(PostedBy, centerText(PostedBy, rightInfoX, 50), rightInfoY)

  // Display ApprovedByValue above the line
  const lineYAboveCenterInfo = centerInfoY - 4
  doc.setTextColor('#0501ff')
  doc.text(ApprovedByValue, centerText(ApprovedByValue, centerInfoX, 50), lineYAboveCenterInfo)

  // Draw a horizontal line above center side details
  const lineYAboveCenterInfoDetails = centerInfoY - 3
  doc.setLineWidth(0.4)
  doc.line(
    centerInfoX - 0,
    lineYAboveCenterInfoDetails,
    centerInfoX + 50,
    lineYAboveCenterInfoDetails,
  )

  doc.setTextColor('#000000')
  doc.text(ApprovedBy, centerText(ApprovedBy, centerInfoX, 50), centerInfoY)

  ////////DATE TIME////////////

  const gapBetweenNameTime = 12.9
  const startYForDateTime = doc.autoTable.previous.finalY + gapBetweenNameTime

  const CreatedAtValue = wtGetDateTimeFormat(
    pdfData.voucher.created_at ? pdfData.voucher.created_at : '',
  )
  const PostedAtValue = wtGetDateTimeFormat(
    pdfData.voucher.posted_at ? pdfData.voucher.posted_at : '',
  )
  const ApprovedAtValue = wtGetDateTimeFormat(
    pdfData.voucher.approved_at ? pdfData.voucher.approved_at : '',
  )

  // Coordinates for CreatedAt
  const infoX1 = 16
  const infoY1 = startYForDateTime + 12.9

  // Coordinates for PostedAt
  const rightInfoX1 = 145
  const rightInfoY1 = startYForDateTime + 12.9

  // Coordinates for ApprovedAt
  const centerInfoX1 = 80
  const centerInfoY1 = startYForDateTime + 12.9

  const centerText1 = (text, x, width) => {
    const textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor
    const adjustedX = x + (width - textWidth) / 2
    return adjustedX
  }

  doc.setFontSize(9)

  // Display CreatedAtValue above the line
  const lineYAboveCreatedAt = infoY1 - 4
  doc.setTextColor('#0501ff')
  doc.text(CreatedAtValue, centerText1(CreatedAtValue, infoX1, 50), lineYAboveCreatedAt)

  // Display PostedAtValue above the line
  const lineYAboveRightInfo1 = rightInfoY1 - 4
  doc.setTextColor('#0501ff')
  doc.text(PostedAtValue, centerText1(PostedAtValue, rightInfoX1, 50), lineYAboveRightInfo1)

  // Display ApprovedAtValue above the line
  const lineYAboveCenterInfo1 = centerInfoY1 - 4
  doc.setTextColor('#0501ff')
  doc.text(ApprovedAtValue, centerText1(ApprovedAtValue, centerInfoX1, 50), lineYAboveCenterInfo1)

  const gapAfterThis = 10
  const startYForgapAfterThis = doc.autoTable.previous.finalY + gapAfterThis

  doc.setFontSize(10)
  doc.setTextColor('#000000')
  doc.setFont('Helvetica', 'normal')
  const printReport = 'This voucher is printed through ERP at '
  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')

  const textWidth1 =
    (doc.getStringUnitWidth(printReport) * doc.internal.getFontSize()) / doc.internal.scaleFactor
  const textWidth2 =
    (doc.getStringUnitWidth(currentTime) * doc.internal.getFontSize()) / doc.internal.scaleFactor
  const totalWidth = textWidth1 + textWidth2

  const startXForPrintReport = (pageWidth - totalWidth) / 2
  const startYForPrintReport = startYForgapAfterThis + 30

  doc.text(printReport, startXForPrintReport, startYForPrintReport)
  doc.setFont('Helvetica', 'bold')
  doc.setTextColor('#0202f7')
  doc.text(currentTime, startXForPrintReport + textWidth1, startYForPrintReport)

  // // Function to add QR code after the table
  // const addQRCode = () => {
  //   const qrCodeSize = 44
  //   const qrCodeX = (pageWidth - qrCodeSize) / 2
  //   const qrCodeY = doc.autoTable.previous.finalY + 50
  //   const qrCodeText = FRONT_BASE_URL + 'vouchers#view:' + pdfData.voucher.id

  //   QRCode.toDataURL(qrCodeText, { width: qrCodeSize }, (err, url) => {
  //     if (err) {
  //       console.error(err)
  //     } else {
  //       doc.addImage(url, 'PNG', qrCodeX, qrCodeY, qrCodeSize, qrCodeSize)
  //     }
  //   })
  // }
  // addQRCode()


  // Function to add QR code after the table
  const addBarCode = (doc) => {
    const qrCodeSize = 44
    const qrCodeX = (pageWidth - qrCodeSize) / 2
    const qrCodeY = doc.autoTable.previous.finalY + 50
    const qrCodeText = FRONT_BASE_URL + 'vouchers#view:' + pdfData.voucher.id

    const { voucher } = pdfData
    const { id, voucher_no, company_voucher_no } = voucher;
    // Generate barcode
    const canvas = document.createElement('canvas');
    JsBarcode(canvas, (`${id}-${voucher_no}-${company_voucher_no}`), {
      format: 'CODE128', // Specify the barcode format
      displayValue: true, // Display the value below the barcode
      fontSize: 32,
    });

    // Convert canvas to data URL
    const barcodeDataURL = canvas.toDataURL('image/png');

    // Add the barcode image to the PDF
    doc.addImage(barcodeDataURL, 'PNG', qrCodeX, qrCodeY, 50, 20); // Adjust position and size as needed
  }
  addBarCode(doc)



  const addFooter = (doc) => {
    const pageCount = doc.internal.getNumberOfPages()
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      doc.setTextColor('black')
      doc.setFontSize(9)

      const lineHeight = 286
      const lineWidth = 182
      const lineX = 14
      const lineY = lineHeight
      doc.line(lineX, lineY, lineX + lineWidth, lineY)
      doc.text(`Page ${i} / ${pageCount}`, 190, 290, { align: 'right' })
      doc.text(`Printed By `, 20, 290, { align: 'left' })
      doc.setTextColor('blue')
      doc.text(`${user ? user.name : ''}`, 37, 290, { align: 'left' })
    }
  }
  addFooter(doc)

  const documentName = `${pdfData.voucher.type_short_name}-${pdfData.voucher.company_voucher_no}-${pdfData.voucher.voucher_no}`;
  doc.save(`${documentName}.pdf`)
}

export default VoucherPDF
