import React from 'react'
import { CCard, CCardBody } from '@coreui/react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
export default function EmployeePunctualityChart({ data, title, seriesName = "" }) {
  const chartOptions = {
    chart: {
      type: 'pie',
    },
    title: {
      text: title,
    },
    series: [
      {
        name: seriesName ? seriesName : 'No. Of Employees',
        data: data,
      },
    ],
    credits: {
      enabled: false,
    },
  }

  return (
    <>
      <CCard>
        <CCardBody>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </CCardBody>
      </CCard>
    </>
  )
}
