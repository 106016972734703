import React from "react";
import WidgetSelector from "./WidgetSelector";

export default function WidgetBranchSelector({
  value,
  displayValue,
  branches,
  onChange,
  reloadDropdownData,
  viewOnly,
  size,
}) {
  return (
    <React.Fragment>
      <WidgetSelector
        viewOnly={viewOnly || false}
        selectedValue={value}
        displayValue={displayValue}
        options={branches}
        onChange={(values) => onChange(values)}
        reloadDropdownData={() => reloadDropdownData()}
        placeHolder="Select Branch"
        showCloudIcon={true}
        size={size}
      />
    </React.Fragment>
  );
}
