import React, { useState } from 'react'
import { CCol, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import './table.css'
import { wtRoundNumberToSpecifedDigits } from 'src/helpers/commons/utils'
import { Link } from 'react-router-dom'
import { wtConvertHourValue } from 'src/helpers/commons/moment'
import Difference from './Difference'
import moment from 'moment'

export default function DataReportTable({ reportData, setReportData, dates }) {
  const [sortedKey, setSortedKey] = useState('')
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc',
  })

  const sortTable = (key) => {
    let direction = 'asc'
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    const sorted = [...reportData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1
      }
      return 0
    })
    setReportData(sorted)
    setSortedKey(key)
    setSortConfig({ key, direction })
  }


  return (
    <>
      <thead class="thead-dark" style={{ backgroundColor: '#e8e9eb' }}>
        <tr>
          <th onClick={() => sortTable('id')}>Sr</th>
          <th onClick={() => sortTable('id')}>
            ID{' '}
            {sortedKey === 'id' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('full_name')}>
            Full Name
            {sortedKey === 'full_name' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>

          <th onClick={() => sortTable('designations')}>
            Designation
            {sortedKey === 'designations' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          {dates.map((item) => <th key={item}>{moment(item).format("DD")}</th>)}
          <th onClick={() => sortTable('total_overtime')}>
            Total Overtime hrs
            {sortedKey === 'total_overtime' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          {/* <th onClick={() => sortTable('total_difference')}>
            Total Overtime (min)
            {sortedKey === 'total_difference' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th> */}
          <th onClick={() => sortTable('total_leaves')}>
            Total Leaves
            {sortedKey === 'total_leaves' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('total_holidays')}>
            Total Holidays
            {sortedKey === 'total_holidays' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('total_absent')}>
            Total Absent
            {sortedKey === 'total_absent' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('total_attendance_missing')}>
            Total Attendance Missing
            {sortedKey === 'total_attendance_missing' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {reportData.map((item, index) => (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td style={{ color: 'blue' }}>
              <Link
                to={`/employee-profile?id=${item.id}&tab=emp_attendance`}
                target="_blank"
              >
                {item.id}
              </Link>
            </td>
            <td style={{ color: 'blue' }}>
              <Link
                to={`/employee-profile?id=${item.id}&tab=emp_attendance`}
                target="_blank"
              >
                {item.full_name}
              </Link>
            </td>
            <td>{item.designation.name}</td>
            <Difference record={item.attendance_difference} dates={dates} />
            <td style={{ color: item.total_overtime > 0 ? 'red' : 'green' }}>{item.total_overtime}</td>
            {/* <td style={{ color: item.total_difference > 0 ? 'red' : 'green' }}>{wtRoundNumberToSpecifedDigits(item.total_difference)}</td> */}
            <td>{item.total_leave}</td>
            <td>{item.total_holidays}</td>
            <td>{item.total_absent}</td>
            <td>{item.total_attendance_missing}</td>
          </tr>
        ))}
      </tbody>
    </>
  )
}
