import React from 'react'

export default function AttendanceReportLegends({hrs = false}) {
  return (
    <div>
      <>
        <h5>Legend:</h5>
        <ul>
          <li>
            <strong>A: </strong>Absent
          </li>
          <li>
            <strong>P: </strong>Present
          </li>
          <li>
            <strong>L: </strong>Leave
          </li>
          <li>
            <strong>U: </strong>Unpresented
          </li>
          <li>
            <strong>E: </strong>Exempted From Attendance
          </li>
          <li>
            <strong>MV: </strong>On Move
          </li>
          <li>
            <strong>PH: </strong>Public Holiday
          </li>
          <li>
            <strong>WH: </strong>Weekly Holiday
          </li>
          <li>``
            <strong>M(IN): </strong>Check IN Missing
          </li>
          <li>
            <strong>M(OUT): </strong>Check OUT Missing
          </li>
        </ul>
        <label>
          <strong>** All values are in {hrs ? 'hrs' : 'min'}</strong>
        </label>
      </>
    </div>
  )
}
