import React from 'react'
import WidgetSelector from './WidgetSelector'

export default function WidgetDriverSelector({
  value,
  displayValue,
  drivers,
  onChange,
  viewOnly,
  reloadDropdownData,
  size,
}) {
  return (
    <React.Fragment>
      <WidgetSelector
        viewOnly={viewOnly || false}
        selectedValue={value}
        displayValue={displayValue}
        options={drivers}
        onChange={(values) => onChange(values)}
        reloadDropdownData={() => reloadDropdownData()}
        placeHolder="Select Driver"
        showCloudIcon={true}
        size={size}
      />
    </React.Fragment>
  )
}
