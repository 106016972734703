import { cilCloudDownload, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CFormInput, CInputGroup, CInputGroupText } from '@coreui/react'
import React from 'react'
import Select from 'react-select'
export default function WidgetSelector({
  selectedValue,
  options,
  onChange,
  placeHolder,
  viewOnly = false,
  size,
  displayValue = "",
  reloadDropdownData,
  showCloudIcon
}) {
  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
    control: (base) => ({
      ...base,
      height: 'sm' === size ? 30 : 35,
      minHeight: 'sm' === size ? 30 : 35,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingTop: 'sm' === size ? 2 : 5,
    }),
    placeholder: (base) => ({
      ...base,
      marginTop: 0,
      marginBottom: 'sm' === size ? 6 : 0,
      position: 'absolute',
    }),
  }

  return (
    <React.Fragment>
      {+selectedValue > 0 && displayValue !== "" ? (
        <>
          <React.Fragment>
            <CInputGroup size={size || ''}>
              <CFormInput value={displayValue} disabled />
              {!viewOnly && (
                <CInputGroupText
                  style={{ cursor: 'pointer' }}
                  onClick={() => onChange({ value: 0, label: '' })}
                >
                  <CIcon icon={cilTrash} alt="Remove" title="Remove" />
                </CInputGroupText>
              )}
            </CInputGroup>
          </React.Fragment>
        </>
      ) : (
        <CInputGroup style={{ height: 30 }}>
          <div style={{ flexGrow: 1 }}>
            <Select
              menuShouldBlockScroll
              menuPosition="fixed"
              menuPortalTarget={document.querySelector('body')}
              isClearable={false}
              styles={selectStyles}
              placeholder={placeHolder}
              isDisabled={viewOnly}
              menuPlacement="auto"
              options={options}
              value={options.filter(({ value }) => value === selectedValue)}
              onChange={(values) => onChange(values)}
            />
          </div>
          {showCloudIcon && (
            <>
              <CInputGroupText
                style={{ cursor: 'pointer' }}
                onClick={() => reloadDropdownData()}
              >
                <CIcon icon={cilCloudDownload} alt="Remove" title="Remove" />
              </CInputGroupText>
            </>
          )}
        </CInputGroup>
      )}
    </React.Fragment>
  )
}
