import React, { useState } from 'react'
import { CCol, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import './table.css'
import { wtRoundNumberToSpecifedDigits } from 'src/helpers/commons/utils'
import { Link } from 'react-router-dom'
import { wtConvertHourValue } from 'src/helpers/commons/moment'

export default function DataReportTable({ reportData,
}) {
  const [sortedKey, setSortedKey] = useState('')
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc',
  })

  const sortTable = (key) => {
    let direction = 'asc'
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    const sorted = [...reportData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1
      }
      return 0
    })
    setReportData(sorted)
    setSortedKey(key)
    setSortConfig({ key, direction })
  }

  const getDifference = (record) => {
    const { working_hours, updated_working_hours, shift_duration } = record
    if (updated_working_hours !== null) {
      return wtRoundNumberToSpecifedDigits(Math.abs(updated_working_hours - shift_duration) * 60, 2,) + ' min'
    }
    else if (working_hours !== null) {
      return wtRoundNumberToSpecifedDigits(Math.abs(working_hours - shift_duration) * 60, 2,) + ' min'
    }
    else {
      return '-'
    }
  }

  const getDifferenceColor = (record) => {
    const { working_hours, updated_working_hours, shift_duration } = record

    if (updated_working_hours !== null) {
      if (updated_working_hours > shift_duration) {
        return 'green'
      }
      else if (updated_working_hours < shift_duration) {
        return 'red'
      }
      else {
        return 'blue'
      }
    }
    else if (working_hours !== null) {
      if (working_hours > shift_duration) {
        return 'green'
      }
      else if (working_hours < shift_duration) {
        return 'red'
      }
      else {
        return 'blue'
      }
    }
    else {
      return 'blue'
    }
  }

  return (
    <CRow>
      <CCol md={12}>
        <table className="table table-bordered data-table">
          <thead class="thead-dark" style={{ backgroundColor: '#e8e9eb' }}>
            <tr>
              <th onClick={() => sortTable('id')}>Sr</th>
              <th onClick={() => sortTable('id')}>
                Card#{' '}
                {sortedKey === 'id' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('full_name')}>
                Full Name
                {sortedKey === 'full_name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>

              <th onClick={() => sortTable('designation.name')}>
                Designation
                {sortedKey === 'designation.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('department.name')}>
                Department
                {sortedKey === 'department.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('employee_shift.name')}>
                Date
                {sortedKey === 'employee_shift.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>

              <th onClick={() => sortTable('employee_shift.name')}>
                Shift Name
                {sortedKey === 'employee_shift.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('check_in')}>
                Check In
                {sortedKey === 'check_in' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('check_out')}>
                Check Out
                {sortedKey === 'check_out' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('late_coming')}>
                Late Coming
                {sortedKey === 'late_coming' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('early_going')}>
                Early Going
                {sortedKey === 'early_going' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('working_hours')}>
                Working Hours
                {sortedKey === 'working_hours' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('shift_duration')}>
                Shift Duration
                {sortedKey === 'shift_duration' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th>Difference</th>
              <th onClick={() => sortTable('ovetime_hours')}>
                Overtime Hours
                {sortedKey === 'ovetime_hours' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th>Approved Overtime</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {reportData.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td style={{ color: 'blue' }}>
                  <Link
                    to={`/employee-profile?id=${item.employee_id}&tab=emp_attendance`}
                    target="_blank"
                  >
                    {item.employee.personal_file_no}
                  </Link>
                </td>
                <td style={{ color: 'blue' }}>
                  <Link
                    to={`/employee-profile?id=${item.employee_id}&tab=emp_attendance`}
                    target="_blank"
                  >
                    {item.employee.full_name}
                  </Link>
                </td>
                <td>{item.employee.designation.name}</td>
                <td>{item.employee.department.name}</td>
                <td>{item.attendance_date}</td>
                <td>{item.employee_shift ? item.employee_shift.shift.name : '-'}</td>
                <>
                  <td style={{ color: 'blue' }}>{item.is_updated ? item.updated_check_in : item.check_in}</td>
                  <td style={{ color: 'blue' }}>{item.is_updated ? item.updated_check_out : item.check_out}</td>

                  <td style={{ color: 'red', textAlign: 'center' }}>
                    {item.is_updated ? wtRoundNumberToSpecifedDigits(item.updated_late_coming) + ' min' : item.late_coming ? wtRoundNumberToSpecifedDigits(item.late_coming) + ' min' : '-'}
                  </td>
                  <td style={{ color: 'red', textAlign: 'center' }}>
                    {item.is_updated ? wtRoundNumberToSpecifedDigits(item.updated_early_going) + ' min' : item.early_going ? wtRoundNumberToSpecifedDigits(item.early_going) + ' min' : '-'}
                  </td>
                  <td>{wtConvertHourValue(item.is_updated ? item.updated_working_hours : item.working_hours)}</td>
                  <td>{wtConvertHourValue(item.shift_duration)}</td>
                  <td
                    style={{ color: getDifferenceColor(item) }}
                  >{getDifference(item)}</td>
                  <td style={{ color: item.overtime_hours ? 'green' : 'red' }}>{item.is_updated === 1 ? wtConvertHourValue(item.updated_overtime_hours) : wtConvertHourValue(item.overtime_hours)} </td>

                  <td style={{ color: item.is_overtime_approved === 0 ? 'red' : 'green' }}>
                    {item.is_overtime_approved === 0 ? '-' : (item.updated_overtime_hours ? wtRoundNumberToSpecifedDigits(item.updated_overtime_hours) + ' Hours' : '-')}
                  </td>
                  <td>{item.updated_type ? item.updated_type : item.type} {(item.updated_type === 'Public Holiday' || item.type === 'Public Holiday') && (<span>{item.remarks}</span>)}</td>


                </>


              </tr>
            ))}
          </tbody>
        </table>
      </CCol>
    </CRow>
  )
}
