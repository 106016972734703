export const genderOptions = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
]

export const relationshipOptions = [
  { value: 'Father', label: 'Father' },
  { value: 'Mother', label: 'Mother' },
  { value: 'Spouse', label: 'Spouse' },
  { value: 'Brother', label: 'Brother' },
  { value: 'Sister', label: 'Sister' },
  { value: 'Son', label: 'Son' },
  { value: 'Daughter', label: 'Daughter' },
]

export const maritalstatusOptions = [
  { value: 'Single', label: 'Single' },
  { value: 'Married', label: 'Married' },
  { value: 'Widowed', label: 'Widowed' },
  { value: 'Divorced', label: 'Divorced' },
]

export const bloodgroupOptions = [
  { value: 'A+', label: 'A RhD positive (A+)' },
  { value: 'A-', label: 'A RhD negative (A-)' },
  { value: 'B+', label: 'B RhD positive (B+)' },
  { value: 'B-', label: 'B RhD negative (B-)' },
  { value: 'O+', label: 'O RhD positive (O+)' },
  { value: 'O-', label: 'O RhD negative (O-)' },
  { value: 'AB+', label: 'AB RhD positive (AB+)' },
  { value: 'AB-', label: 'AB RhD negative (AB-)' },
]

export const religionstatusOptions = [
  {
    value: 'African Traditional &amp; Diasporic',
    label: 'African Traditional &amp; Diasporic',
  },
  { value: 'Agnostic', label: 'Agnostic' },
  { value: 'Atheist', label: 'Atheist' },
  { value: 'Buddhism', label: 'Buddhism' },
  { value: 'Cao Dai', label: 'Cao Dai' },
  {
    value: 'Chinese traditional religion',
    label: 'Chinese traditional religion',
  },
  { value: 'Christianity', label: 'Christianity' },
  { value: 'Hinduism', label: 'Hinduism' },
  { value: 'Islam', label: 'Islam' },
  { value: 'Jainism', label: 'Jainism' },
  { value: 'Juche', label: 'Juche' },
  { value: 'Judaism', label: 'Judaism' },
  { value: 'Neo-Paganism', label: 'Neo-Paganism' },
  { value: 'Nonreligious', label: 'Nonreligious' },
  { value: 'Rastafarianism', label: 'Rastafarianism' },
  { value: 'Secular', label: 'Secular' },
  { value: 'Shinto', label: 'Shinto' },
  { value: 'Sikhism', label: 'Sikhism' },
  { value: 'Spiritism', label: 'Spiritism' },
  { value: 'Tenrikyo', label: 'Tenrikyo' },
  { value: 'Zoroastrianism', label: 'Zoroastrianism' },
  { value: 'primal-indigenous', label: 'primal-indigenous' },
]

export const educationstatusOptions = [
  { value: 1, label: 'Educated' },
  { value: 0, label: 'Uneducated' },
]

export const priorexperienceOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

export const CurrentlyUnemployedOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

export const PreviousEmployerContactOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

export const ServiceBondOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

export const ArmedForceOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

export const DisabilityOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

export const PoliticalPartyOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

export const CriminalChargeOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

export const paidtypeOptions = [
  { value: 'Paid', label: 'Paid' },
  { value: 'Unpaid', label: 'Unpaid' },
]

export const leavetypeOptions = [
  { value: 'Sick', label: 'Sick' },
  { value: 'Casual', label: 'Casual' },
  { value: 'Annual', label: 'Annual' },
]

export const daytypeOptions = [
  { value: 'Full Day', label: 'Full Day' },
  { value: 'Half Day', label: 'Half Day' },
]

export const holidayOptions = [
  { value: 'Sunday', label: 'Sunday' },
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
]
export const ResignationTypeOptions = [
  { value: 'Resign', label: 'Resign' },
  { value: 'Terminate', label: 'Terminate' },
]

export const AccountTypeOptions = [
  { value: 'Sub Account', label: 'Sub Account' },
  { value: 'Parent Account', label: 'Parent Account' },
]

export const JobTypeOptions = [
  { value: 'Regular', label: 'Regular' },
  { value: 'Contractual', label: 'Contractual' },
  { value: 'Deputationist', label: 'Deputationist' },
  { value: 'Daily Wager', label: 'Daily Wager' },
]

export const EligibleforOvertimeOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

export const EmployeeAttendanceTypeOptions = [
  { value: 'Present', label: 'Present' },
  { value: 'Absent', label: 'Absent' },
  { value: 'Leave', label: 'Leave' },
  { value: 'On Move', label: 'On Move' },
  { value: 'Weekly Holiday', label: 'Weekly Holiday' },
  { value: 'Public Holiday', label: 'Public Holiday' },
  { value: 'Late', label: 'Late' },
  { value: 'Half Day', label: 'Half Day' },
  { value: 'Short Leave', label: 'Short Leave' },
  { value: 'Unpresented', label: 'Unpresented' },
]

export const AttendanceDayTypeOptions = [
  { value: 'Sunday', label: 'Sunday' },
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
]

export const DayChangedOptions = [
  { value: 1, label: 'Yes' },
  { value: 2, label: 'No' },
]

export const IsDefaultOptions = [
  { value: 1, label: 'Yes' },
  { value: 2, label: 'No' },
]

export const QuestionsOptions = [
  { value: 5, label: 'Excellent Performance (5)' },
  { value: 4, label: 'Exceeds expectations (4)' },
  { value: 3, label: 'Meets Expectations (3)' },
  { value: 2, label: 'Needs Development (2)' },
  { value: 1, label: 'Unsatisfactory Performance (1)' },
]

export const RecommendedActionOptions = [
  { value: 'Successful completion', label: 'Successful completion' },
  { value: 'Termination of employment', label: 'Termination of employment' },
  { value: 'Extension of probationary period', label: 'Extension of probationary period' },
  { value: 'Demotion', label: 'Demotion' },
]

export const GradeOptions = [
  { value: 'BS-0', label: 'BS-0' },
  { value: 'BS-1', label: 'BS-1' },
  { value: 'BS-2', label: 'BS-2' },
  { value: 'BS-3', label: 'BS-3' },
  { value: 'BS-4', label: 'BS-4' },
  { value: 'BS-5', label: 'BS-5' },
  { value: 'BS-6', label: 'BS-6' },
  { value: 'BS-7', label: 'BS-7' },
  { value: 'BS-8', label: 'BS-8' },
  { value: 'BS-9', label: 'BS-9' },
  { value: 'BS-10', label: 'BS-10' },
  { value: 'BS-11', label: 'BS-11' },
  { value: 'BS-12', label: 'BS-12' },
  { value: 'BS-13', label: 'BS-13' },
  { value: 'BS-14', label: 'BS-14' },
  { value: 'BS-15', label: 'BS-15' },
  { value: 'BS-16', label: 'BS-16' },
  { value: 'BS-17', label: 'BS-17' },
  { value: 'BS-18', label: 'BS-18' },
  { value: 'BS-19', label: 'BS-19' },
  { value: 'BS-20', label: 'BS-20' },
  { value: 'BS-21', label: 'BS-21' },
  { value: 'BS-22', label: 'BS-22' },
  { value: 'BS-23', label: 'BS-23' },
]

export const PerformanceEvaluationQuestionsOptions = [
  { value: 10, label: 'Outstanding (10)' },
  { value: 9, label: 'Excellent (9)' },
  { value: 8, label: 'Very Good (8)' },
  { value: 7, label: 'Good (7)' },
  { value: 6, label: 'Above Average (6)' },
  { value: 5, label: 'Average (5)' },
  { value: 4, label: 'Below Average (4)' },
  { value: 3, label: 'Weak (3)' },
  { value: 2, label: 'Very Weak (2)' },
  { value: 1, label: 'Poor (1)' },
]

export const multiplefields = [
  { value: 'date_of_birth', label: 'Date Of Birth' },
  { value: 'gender', label: 'Gender' },
  { value: 'grade', label: 'Grade' },
  { value: 'domicile', label: 'Domicile' },
  { value: 'personal_file_no', label: 'Perosnal File No' },
  { value: 'date_of_join', label: 'Date Of Join' },
  { value: 'mobile_no', label: 'Mobile No' },
  { value: 'permanent_address', label: 'Permanent Address' },
  { value: 'resident_address', label: 'Resident Address' },
  { value: 'cnic', label: 'CNIC' },
  { value: 'personal_email', label: 'Personal Email' },
  { value: 'official_mail', label: 'Offical Email' },
  { value: 'cnic_issue_date', label: 'Cnic Issue Date' },
  // { value: 'phone_no', label: 'Cnic Expiry Date' },
]
export const performanceEvaluationRatingRemarks = {
  1: 'Poor',
  2: 'Very Weak',
  3: 'Weak',
  4: 'Below Average',
  5: 'Average',
  6: 'Above Average',
  7: 'Good',
  8: 'Very Good',
  9: 'Excellent',
  10: 'Outstanding',
}

export const FileStatusOptions = [
  { value: 0, label: 'Pending' },
  { value: 1, label: 'Received' },
  { value: 2, label: 'Validated' },
]

export const TypeOptions = [
  { value: 1, label: 'Annual' },
  { value: 2, label: 'Monthly' },
  { value: 3, label: 'One Time' },
]

export const NatureOfDutyOptions = [
  { value: 'Official Visit', label: 'Official Visit' },
  { value: 'Personal Visit', label: 'Personal Visit' },
]

export const IsOfficerOptions = [
  { value: 1, label: 'Yes' },
  { value: 2, label: 'No' },
]

export const IsVisibleOptions = [
  { value: 1, label: 'Yes' },
  { value: 2, label: 'No' },
]

export const IsMonthlyLeaveApplicableOptions = [
  { value: 1, label: 'Yes' },
  { value: 2, label: 'No' },
]

export const YesNoOptions = [
  { value: 1, label: 'Yes' },
  { value: 2, label: 'No' },
]

export const ApprovalStatusOptions = [
  { value: 1, label: 'Approved' },
  { value: 2, label: 'Rejected' },
  { value: 3, label: 'Pending' },
]

export const PerformanceEvaluationFormOptions = [
  { value: 'salesTemplate', label: 'Sales Template Form' },
  { value: 'staffTemplate', label: 'Staff Template Form' },
  { value: 'staffOutletTemplate', label: 'Staff Outlet Template Form' },
  { value: 'cafeteriaStaffTemplate', label: 'Cafeteria Staff Template Form' },
  { value: 'officerTemplate', label: 'Officer Template Form' },
  { value: 'outletLabTemplate', label: 'Outlet Lab Template Form' },
  { value: 'staffV2Template', label: 'Staff V2 Template Form' },
  { value: 'cafeteriaStaffV2Template', label: 'Cafeteria Staff V2 Template Form' },
]

export const fileStatusOptions = {
  0: 'Pending',
  1: 'Received',
  2: 'Validated',
}

export const LetterTypeOptions = [
  { value: 'Warning Letter', label: 'Warning Letter' },
  { value: 'Explanation', label: 'Explanation' },
  { value: 'Advisory', label: 'Advisory' },
  { value: 'Other', label: 'Other' },
]

export const StatusOptions = [
  { value: 1, label: 'Active' },
  { value: 2, label: 'Inactive' },
]

export const EnableOptions = [
  { value: 1, label: 'Enabled' },
  { value: 2, label: 'Disabled' },
]

export const FuelingOptions = [
  { value: 1, label: 'Yes' },
  { value: 2, label: 'No' },
]
