import { CButton, CCard, CCardBody, CCol, CForm, CInputGroup, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import BaseListing from 'src/BaseListings'
import WidgetBranchSelector from '../../widgets/Selectors/WidgetBranchSelector'
import WidgetDepartmentSelector from '../../widgets/Selectors/WidgetDepartmentSelector'
import WidgetDesignationSelector from '../../widgets/Selectors/WidgetDesignationSelector'
import { wtApiCall } from '../../helpers/commons/ApiWrapper'
import { lzNotifyErrorMessageFromResult } from '../../helpers/commons/notifications'
import WidgetEmployeeSelector from 'src/widgets/Selectors/WidgetEmployeeSelector'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import DataReportTable from './DataReportTable'
import Loading from 'src/helpers/commons/loader'
import WidgetDatePicker from 'src/widgets/DatePickers/WidgetDatePicker'

export default function DesignationTrackingReport() {
  const Base = new BaseListing()
  const [isLoading, setIsLoading] = useState(false)
  const [FromDate, setFromDate] = useState('')
  const [ToDate, setToDate] = useState('')
  const [Employees, setEmployees] = useState([])
  const [EmployeeId, setEmployeeId] = useState(0)
  const [EmployeeName, setEmployeeName] = useState('')
  const [branches, setBranches] = useState([])
  const [branchId, setBranchId] = useState(0)
  const [branch_name, setBranchName] = useState('')
  const [departments, setDepartments] = useState([])
  const [department_id, setDepartmentId] = useState(0)
  const [department_name, setDepartmentName] = useState('')
  const [designations, setDesignations] = useState([])
  const [designation_id, setDesignationId] = useState(0)
  const [designation_name, setDesignationName] = useState('')
  const [errors, setErrors] = useState([])
  const [isMore, setIsMore] = useState(false)
  const [reportData, setReportData] = useState([])
  const [EffectiveDate, setEffectiveDate] = useState('')
  const [NewDesignationId, setNewDesignationId] = useState(0)
  const [OldDesignationId, setOldDesignationId] = useState(0)

  const generatePDF = () => {
    const doc = new jsPDF()
    const logo = 'assets/wil-icon.png'
    const img = new Image()
    var totalPagesExp = '{total_pages_count_string}'
    let yPosition = 45

    const headRows = [
      {
        content: 'Sr',
      },
      {
        dataKey: 'id',
        content: 'ID',
      },
      {
        dataKey: 'full_name',
        content: 'Full Name',
      },
      {
        dataKey: 'branch.name',
        content: 'Branch',
      },
      {
        dataKey: 'department.name',
        content: 'Department',
      },
      {
        dataKey: 'effective_date',
        content: 'Effective From',
      },
      {
        dataKey: 'to_date',
        content: 'To Date',
      },
      {
        dataKey: 'new_designation.name',
        content: 'Current Designation',
      },
      {
        dataKey: 'old_designation.name',
        content: 'Old Designation',
      },
    ]

    const employeeDetails = reportData.map((item, index) => [
      index + 1,
      { content: item.employee_id, styles: { textColor: '#0000FF' } },
      { content: item.employee.full_name, styles: { textColor: '#0000FF' } },
      item.employee.branch?.name || '',
      item.employee.department?.name || '',
      item.effective_date,
      { content: item.to_date || 'Present', styles: { textColor: 'green' } },
      { content: item.new_designation?.name || '', styles: { textColor: 'blue' } },
      { content: item.old_designation?.name || '', styles: { textColor: 'red' } },
    ])

    doc.autoTable({
      head: [headRows],
      body: employeeDetails,
      startY: 45,
      margin: { top: 50, bottom: 20, left: 10, right: 10 },
      theme: 'plain',
      tableLineColor: '#3c4b64',
      tableLineWidth: 0.2,
      tableWidth: 190,
      styles: { lineColor: '#3c4b64', lineWidth: 0.2, halign: 'center' },
      headStyles: {
        fillColor: '#fff',
        textColor: [60, 75, 100],
        setFontSize: 8,
        font: 'helvetica',
      },

      didDrawPage: function (data) {
        //get page no
        totalPagesExp = doc.internal.getNumberOfPages()
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp)
        }
        let pageNo = doc.internal.getNumberOfPages()
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth() // Header

        doc.setTextColor('#3c4b64')
        doc.setFont('Helvetica', 'bold') //Header middle branch logo

        doc.addImage(logo, 'PNG', pageWidth - 200, 4, 25, 25)
        doc.line(pageWidth - 173, 7, pageWidth - 173, 27) // Adjust the coordinates as needed

        doc.setFontSize(11)
        doc.text('WAH', pageWidth - 171, 10, { align: 'left' })
        doc.setFontSize(11)
        doc.setFont('Helvetica')
        doc.text('INDUSTRIES', pageWidth - 171, 15)
        doc.text('LIMITED', pageWidth - 171, 20)
        doc.setFontSize(9)
        doc.text('www.wahindustries.com', pageWidth - 171, 25)
        doc.setFontSize(14)
        doc.text('Designation Tracking Report', pageWidth - 12, 10, { align: 'right' }) // Add header separator

        yPosition -= -5 // Set alignment for the text that follows the line
        doc.setFont('Helvetica')
        doc.setFontSize(12)
        doc.setTextColor('#3c4b64')

        //Page No
        var str = "Page " + pageNo;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === "function") {
          str = str + " of " + totalPagesExp;
        }

        doc.setTextColor("black");
        doc.text(str, 10, pageHeight - 4);
      },
    })

    doc.setFontSize(10)
    const printReport =
      'This Report is printed through ERP at ' + moment().format('YYYY-MM-DD HH:mm:ss')
    const styleReport = { halign: 'center' }
    const pageWidth = doc.internal.pageSize.width
    const textWidth =
      (doc.getStringUnitWidth(printReport) * doc.internal.getFontSize()) / doc.internal.scaleFactor
    const startXForPrintReport = (pageWidth - textWidth) / 2
    const startYForPrintReport = doc.autoTable.previous.finalY + 20

    doc.text(printReport, startXForPrintReport, startYForPrintReport, styleReport)

    doc.save('EmployeeDetails.pdf')
  }

  const getDepartments = (branch_id) => {
    if (branch_id !== 0) {
      wtApiCall(
        'dropdown',
        {
          dropdown_names: 'departments',
          branch_id: branch_id,
        },
        'get',
        (result) => {
          if (result !== null) {
            if (result.data.length > 0) {
              setDepartments(result.data[0]['departments']) // as I am calling only department dropdown I have mentioned it explicitly
            }
          }
        },
        (result) => {
          lzNotifyErrorMessageFromResult(result)
        },
      )
    }
  }

  const changeValues = (key, val) => {
    if (key === 'branch_id') {
      setBranchId(val.value)
      setBranchName(val.label)
      getDepartments(val.value)
    }
    if (key === 'department_id') {
      setDepartmentId(val.value)
      setDepartmentName(val.label)
    }
    if (key === 'designation_id') {
      setDesignationId(val.value)
      setDesignationName(val.label)
    }
    if (key === 'employee_id') {
      setEmployeeId(val.value)
      setEmployeeName(val.label)
    }
  }

  const getReportData = () => {
    setIsLoading(true)
    wtApiCall(
      'designation_tracking_report',
      {
        employee_id: EmployeeId,
        branch_id: branchId,
        department_id: department_id,
        designation_id: designation_id,
        new_designation_id: NewDesignationId,
        old_designation_id: OldDesignationId,
        effective_date: EffectiveDate,
        from_date: FromDate,
        to_date: ToDate,
      },
      'get',
      (response) => {
        const { data } = response
        setReportData(data)
        setIsLoading(false)
      },
      (error) => {
        lzNotifyErrorMessageFromResult(error)
        setIsLoading(false)
      },
    )
  }

  // const getPDFReportData = () => {
  //   wtApiCall(
  //     'designation_tracking_report',
  //     {},
  //     'get',
  //     (response) => {
  //       const { data } = response
  //       generatePDF(data)
  //     },
  //     (error) => {
  //       lzNotifyErrorMessageFromResult(error)
  //     },
  //   )
  // }

  useEffect(() => {
    Base.dropdownData(
      'employees,branches,designations',
      {},
      [setEmployees, setBranches, setDesignations],
      1,
    )
  }, [])
  return (
    <>
      <div style={{ padding: '2%' }}>
        <center>
          <h4>Designation Tracking Report</h4>
        </center>
        <CRow style={{ width: '100%' }}>
          <CCol md={12}>
            <CCard className="card-dark">
              <CCardBody style={{ backgroundColor: '#f7f9fa' }}>
                <br />
                <CForm>
                  <CRow>
                    {/* <CCol md={1}></CCol> */}
                    <CCol md={1}>
                      <CInputGroup>
                        <label style={{ fontSize: '140%', marginTop: '50px' }}>Filters:</label>
                      </CInputGroup>
                    </CCol>
                    <CCol md={3}>
                      <div className="form-group">
                        <label>From</label>
                        <WidgetDatePicker
                          value={FromDate}
                          setDate={setFromDate}
                          placeholder="Select From Date"
                          className={errors.from_date ? 'is-invalid' : ''}
                        />
                        {errors.from_date && <ErrorMessage messages={errors.from_date} />}
                      </div>
                    </CCol>
                    <CCol md={3}>
                      <div className="form-group">
                        <label>To</label>
                        <WidgetDatePicker
                          value={ToDate}
                          setDate={setToDate}
                          placeholder="Select To Date"
                          className={errors.to_date ? 'is-invalid' : ''}
                        />
                        {errors.to_date && <ErrorMessage messages={errors.to_date} />}
                      </div>
                    </CCol>
                    <CCol md={3}>
                      <CInputGroup>
                        <label>Employee</label>
                        <WidgetEmployeeSelector
                          employees={Employees}
                          displayValue={EmployeeName}
                          value={EmployeeId}
                          placeholder="Select Employee"
                          onChange={(values) => changeValues('employee_id', values)}
                          reloadDropdownData={() =>
                            Base.dropdownData('employees', {}, [setEmployees], 1)
                          }
                        />
                        {errors.employee_id && <ErrorMessage messages={errors.employee_id} />}
                      </CInputGroup>
                    </CCol>

                    <CCol md={2}>
                      <CButton
                        color="warning"
                        className="text-white"
                        onClick={() => setIsMore(!isMore)}
                        style={{ marginBottom: '20px', marginTop: '20px' }}
                      >
                        More
                      </CButton>

                      <CButton
                        color="primary"
                        onClick={() => getReportData()}
                        style={{ marginLeft: '1%' }}
                      >
                        Search
                      </CButton>

                      <CButton
                        color="danger"
                        className="text-white"
                        style={{ marginLeft: '1%' }}
                        onClick={generatePDF}
                      >
                        PDF
                      </CButton>
                    </CCol>
                  </CRow>
                  {isMore && (
                    <>
                      <CRow>
                        <CCol md={1}></CCol>
                        <CCol md={3}>
                          <CInputGroup>
                            <label>Branch</label>
                            <WidgetBranchSelector
                              branches={branches}
                              displayValue={branch_name}
                              value={branchId}
                              placeholder="Select Branch"
                              onChange={(values) => changeValues('branch_id', values)}
                              reloadDropdownData={() =>
                                Base.dropdownData('branches', {}, [setBranches], 1)
                              }
                            />
                            {errors.branch_id && <ErrorMessage messages={errors.branch_id} />}
                          </CInputGroup>
                        </CCol>
                        <CCol md={3}>
                          <CInputGroup>
                            <label>Department</label>
                            <WidgetDepartmentSelector
                              departments={departments}
                              displayValue={department_name}
                              value={department_id}
                              placeholder="Select Department"
                              onChange={(values) => changeValues('department_id', values)}
                              reloadDropdownData={() => getDepartments(branchId)}
                            />
                            {errors.department_id && (
                              <ErrorMessage messages={errors.department_id} />
                            )}
                          </CInputGroup>
                        </CCol>
                        <CCol md={3}>
                          <CInputGroup>
                            <label>Designation</label>
                            <WidgetDesignationSelector
                              designations={designations}
                              displayValue={designation_name}
                              value={designation_id}
                              placeholder="Select Designation"
                              onChange={(values) => changeValues('designation_id', values)}
                              reloadDropdownData={() =>
                                Base.dropdownData('designations', {}, [setDesignations], 1)
                              }
                            />
                            {errors.designation_id && (
                              <ErrorMessage messages={errors.designation_id} />
                            )}
                          </CInputGroup>
                        </CCol>
                      </CRow>
                    </>
                  )}
                  <br />
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <br />
        <br />
        {isLoading ? (
          Loading
        ) : (
          <>
            {reportData.length > 0 ? (
              <DataReportTable
                reportData={reportData}
                setReportData={(data) => setReportData(data)}
                isLoading={isLoading}
              />
            ) : (
              <center style={{ fontSize: '19px', fontWeight: 'bold' }}>No record to show...</center>
            )}
          </>
        )}
      </div>
    </>
  )
}
