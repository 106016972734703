import React, { useState } from 'react'
import { CCol, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import './table.css'
import { wtGetDateMonthYearFormat, wtFormatDate } from 'src/helpers/commons/moment'
import { Link } from 'react-router-dom'

export default function DataReportTable({ reportData, setReportData }) {
  const [sortedKey, setSortedKey] = useState('')
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc',
  })

  const sortTable = (key) => {
    let direction = 'asc'
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    const sorted = [...reportData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1
      }
      return 0
    })
    setReportData(sorted)
    setSortedKey(key)
    setSortConfig({ key, direction })
  }

  return (
    <CRow>
      <CCol md={12}>
        <table className="table table-bordered data-table">
          <thead class="thead-dark" style={{ backgroundColor: '#e8e9eb' }}>
            <tr>
              <th onClick={() => sortTable('id')}>Sr</th>
              <th onClick={() => sortTable('id')}>
                ID{' '}
                {sortedKey === 'id' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('pl_number')}>
                PL Number{' '}
                {sortedKey === 'pl_number' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('full_name')}>
                Full Name
                {sortedKey === 'full_name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('branch.name')}>
                Branch
                {sortedKey === 'branch.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('department.name')}>
                Department
                {sortedKey === 'department.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('designation.name')}>
                Designation
                {sortedKey === 'designation.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('date_of_birth')}>
                Date Of Birth
                {sortedKey === 'date_of_birth' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('date_of_joining')}>
                Date Of joining
                {sortedKey === 'date_of_joining' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('cnic')}>
                CNIC
                {sortedKey === 'cnic' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('mobile_no')}>
                Mobile No.
                {sortedKey === 'mobile_no' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('resident_address')}>
                Address
                {sortedKey === 'resident_address' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('relationship')}>
                Relationship
                {sortedKey === 'relationship' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('name')}>
                Name
                {sortedKey === 'name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('cnic')}>
                CNIC
                {sortedKey === 'cnic' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('date_of_birth')}>
                DOB
                {sortedKey === 'date_of_birth' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('occupation')}>
                Occupation
                {sortedKey === 'occupation' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>

            </tr>
          </thead>
          <tbody>
            {reportData.map((employee, index) => {
              const rowspanCount = employee.family_particular.length > 0 ? employee.family_particular.length : 1;
              return (
                <>
                  <tr key={employee.id}>
                    <td rowSpan={rowspanCount}>{index + 1}</td>
                    <td rowSpan={rowspanCount} style={{ color: 'blue' }}>
                      <Link to={`/employee-profile?id=${employee.id}`} target="_blank">
                        {employee.id}
                      </Link>
                    </td>
                    <td rowSpan={rowspanCount} style={{ color: 'blue' }}>{employee.pl_number}</td>
                    <td rowSpan={rowspanCount} style={{ color: 'blue' }}>
                      <Link to={`/employee-profile?id=${employee.id}`} target="_blank">
                        {employee.full_name}
                      </Link>
                    </td>
                    <td rowSpan={rowspanCount}>{employee.branch.name}</td>
                    <td rowSpan={rowspanCount}>{employee.department.name}</td>
                    <td rowSpan={rowspanCount}>{employee.designation.name}</td>
                    <td rowSpan={rowspanCount}>{wtFormatDate(employee.date_of_birth)}</td>
                    <td rowSpan={rowspanCount}>{wtFormatDate(employee.date_of_joining)}</td>
                    <td rowSpan={rowspanCount}>{employee.cnic}</td>
                    <td rowSpan={rowspanCount}>{employee.mobile_no}</td>
                    <td rowSpan={rowspanCount}>{employee.resident_address}</td>
                    {employee.family_particular.length > 0 ? (
                      <>
                        <td>{employee.family_particular[0].relationship}</td>
                        <td>{employee.family_particular[0].name}</td>
                        <td>{employee.family_particular[0].cnic}</td>
                        <td>{wtFormatDate(employee.family_particular[0].date_of_birth)}</td>
                        <td>{employee.family_particular[0].occupation}</td>
                      </>
                    ) : (
                      <>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </>
                    )}
                  </tr>
                  {employee.family_particular.length > 1 && (
                    employee.family_particular.map((item, itemIndex) => {
                      if (itemIndex !== 0) {
                        return (
                          <tr>
                            <td>{item.relationship}</td>
                            <td>{item.name}</td>
                            <td>{item.cnic}</td>
                            <td>{wtFormatDate(item.date_of_birth)}</td>
                            <td>{item.occupation}</td>
                          </tr>
                        )
                      }
                    })

                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </CCol>
    </CRow>
  )
}
