import React, { Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { wtGetFromLocalStorage } from './helpers/commons/LocalStorage'
import './scss/style.scss'
import { connect } from 'react-redux'
import Reports from './views/Reports'
import Print from './views/Orders/Print'
function App(props) {
  // const loading = (
  //   <div className="pt-3 text-center">
  //     <div className="sk-spinner sk-spinner-pulse"></div>
  //   </div>
  // );

  // const authToken = wtGetFromLocalStorage("authToken");

  const AuthenticatedRoutes = ({ children }) => {
    const authUser = wtGetFromLocalStorage('authUser')
    return authUser ? children : <Navigate to="/" />
  }

  const CheckLogin = () => {
    const authUser = wtGetFromLocalStorage('authUser')
    if (authUser && authUser.is_first_login) {
      return <Navigate to="/my-profile" />
    }

    return authUser ? <Navigate to="/dashboard" /> : <Login />
  }

  const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
  // Pages
  const Login = React.lazy(() => import('./views/pages/login/Login'))
  const Register = React.lazy(() => import('./views/pages/register/Register'))
  const ForgotPassword = React.lazy(() => import('./views/pages/ForgotPassword/ForgotPassword'))
  const OtpVerification = React.lazy(() => import('./views/pages/OtpVerification/OtpVerification'))
  const ResetPassword = React.lazy(() => import('./views/pages/ResetPassword/ResetPassword'))
  const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
  const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
  return (
    <Suspense>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" name="Login Page" element={<CheckLogin />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route
            exact
            path="/forgot_password"
            name="Forgot Password"
            element={<ForgotPassword />}
          />
          <Route exact path="/reset_password" name="Reset Password" element={<ResetPassword />} />
          <Route
            exact
            path="/otp_verification"
            name="Otp Verification"
            element={<OtpVerification />}
          />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route
            path="/reports/*"
            element={
              <AuthenticatedRoutes>
                <Reports
                  authToken={props.authToken}
                  authUser={props.authUser}
                  idle_timeout={props.idle_timeout}
                />
              </AuthenticatedRoutes>
            }
          />
          <Route
            path="/print"
            element={
              <AuthenticatedRoutes>
                <Print />
              </AuthenticatedRoutes>
            }
          />
          <Route
            path="*"
            element={
              <AuthenticatedRoutes>
                <DefaultLayout
                  authToken={props.authToken}
                  authUser={props.authUser}
                  idle_timeout={props.idle_timeout}
                />
              </AuthenticatedRoutes>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

const mapStateToProps = (state) => {
  return {
    authToken: state.authToken,
    authUser: state.authUser,
    idle_timeout: state.idle_timeout,
  }
}

export default connect(mapStateToProps)(App)
