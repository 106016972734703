import React from 'react'
import Select from 'react-select'

export default function WidgetEmployeeMultiSelector({ onChange, Employees, value,isDisabled }) {
  const handleMultiSelect = (options) => {
    if (options.length) {
      if (options[0].value === '*') {
        onChange([...Employees])
      } else {
        onChange(options)
      }
    } else {
      onChange([])
    }
  }
  return (
    <Select
      isMulti
      options={[{ value: '*', label: 'Select All' }, ...Employees]}
      onChange={handleMultiSelect}
      value={value}
      isDisabled={isDisabled}
    />
  )
}
