import React from 'react'
import { TreeSelect } from 'antd';
import { CInputGroup, CInputGroupText, CFormInput } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilCloudDownload, cilTrash } from '@coreui/icons';
export default function WidgetProjectSelector({ data, value, onChange, reloadDropdownData, viewOnly, displayValue, width = '100%', showCloudIcon = true, height }) {
    return (

        <>
            {+value > 0 && displayValue !== "" ? (
                <React.Fragment >
                    <CInputGroup>
                        <CFormInput value={displayValue} disabled />
                        {!viewOnly && (
                            <CInputGroupText
                                style={{ cursor: 'pointer' }}
                                onClick={() => onChange(null)}
                            >
                                <CIcon icon={cilTrash} alt="Remove" title="Remove" />
                            </CInputGroupText>
                        )}
                    </CInputGroup>
                </React.Fragment >

            ) : (
                <CInputGroup style={{ height: 30 }}>
                    <div style={{ flexGrow: 1 }}>
                        <TreeSelect
                            showSearch
                            style={{ width: width, height: height }}
                            dropdownStyle={{ maxHeight: 400, overflowY: 'auto', overflowX: 'hidden' }}
                            placeholder="Please select"
                            allowClear
                            treeDefaultExpandAll
                            onChange={(value) => onChange(value)}
                            treeData={data}
                            value={value}
                            disabled={viewOnly}
                            treeNodeFilterProp="title"
                            virtual={false}

                        />

                    </div>
                    {showCloudIcon && (
                        <>
                            <CInputGroupText
                                style={{ cursor: 'pointer' }}
                                onClick={() => reloadDropdownData()}
                            >
                                <CIcon icon={cilCloudDownload} alt="Remove" title="Remove" />
                            </CInputGroupText>
                        </>
                    )}
                </CInputGroup>
            )}
        </>
    )
}
