import React, { useState } from 'react'
import { CCol, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import './table.css'
import { wtGetDateMonthYearFormat } from 'src/helpers/commons/moment'

export default function DataReportTable({ reportData, setReportData }) {
  const [sortedKey, setSortedKey] = useState('')
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc',
  })

  const sortTable = (key) => {
    let direction = 'asc'
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    const sorted = [...reportData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1
      }
      return 0
    })
    setReportData(sorted)
    setSortedKey(key)
    setSortConfig({ key, direction })
  }

  return (
    <CRow>
      <CCol md={12}>
        <table className="table table-bordered data-table">
          <thead class="thead-dark" style={{ backgroundColor: '#e8e9eb' }}>
            <tr>
              <th onClick={() => sortTable('id')}>Sr</th>

              <th onClick={() => sortTable('vehicle.name')}>
                Vehicle No.
                {sortedKey === 'vehicle.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('driver.name')}>
                Driver
                {sortedKey === 'driver.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('from_date')}>
                From Date
                {sortedKey === 'from_date' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('from_time')}>
                From Time
                {sortedKey === 'from_time' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('to_date')}>
                To Date
                {sortedKey === 'to_date' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>

              <th onClick={() => sortTable('to_time')}>
                To Time
                {sortedKey === 'to_time' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('place_of_visit')}>
                Place Of Visit
                {sortedKey === 'place_of_visit' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('nature_of_duty')}>
                Nature Of Duty
                {sortedKey === 'nature_of_duty' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('details')}>
                Employees
                {sortedKey === 'details' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('other_employee')}>
                Other Employee
                {sortedKey === 'other_employee' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {reportData.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>

                <td>{item.vehicle.vehicle_no}</td>
                <td>{item.driver ? item.driver.full_name : ""}</td>
                <td>{wtGetDateMonthYearFormat(item.from_date)}</td>
                <td>{item.from_time}</td>
                <td>{wtGetDateMonthYearFormat(item.to_date)}</td>
                <td>{item.to_time}</td>
                <td>{item.city.name}</td>
                <td>{item.nature_of_duty}</td>
                <td>{item.details.length ? item.details.map((item) => item.employee.full_name + ',') : ''}</td>
                <td>{item.other_employee}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </CCol>
    </CRow>
  )
}
