import React, { useState } from 'react'
import { CCol, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
// import './table.css'
import { wtRoundNumberToSpecifedDigits } from 'src/helpers/commons/utils'
import { Link } from 'react-router-dom'
import { wtConvertHourValue } from 'src/helpers/commons/moment'
// import Difference from './Difference'
import moment from 'moment'

export default function DataReportTable({ reportData, setReportData, isOfficer, leaveTypeData }) {
  const [sortedKey, setSortedKey] = useState('')
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc',
  })

  const sortTable = (key) => {
    let direction = 'asc'
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    const sorted = [...reportData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1
      }
      return 0
    })
    setReportData(sorted)
    setSortedKey(key)
    setSortConfig({ key, direction })
  }

  const getTotalLeaveCount = (record) => {
    let total = 0;
    leaveTypeData.map((type) => {
      if (record.hasOwnProperty(type.leave_name)) {
        total += record[type.leave_name];
      }
    })
    return total;
  }

  return (
    <>
      <thead class="thead-dark" style={{ backgroundColor: '#e8e9eb' }}>
        <tr>
          <th onClick={() => sortTable('id')}>Sr</th>
          <th onClick={() => sortTable('id')}>
            Employee ID{' '}
            {sortedKey === 'id' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('full_name')}>
            Full Name
            {sortedKey === 'full_name' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>

          <th onClick={() => sortTable('designations')}>
            Designation
            {sortedKey === 'designations' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          <th onClick={() => sortTable('department')}>
            Department
            {sortedKey === 'department' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
          {leaveTypeData.map((item) => (
            <th onClick={() => sortTable('no_of_cl_availed')}>
              No Of {item.name} Availed
              {sortedKey === 'no_of_cl_availed' && (
                <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
              )}
            </th>
          ))}
          <th onClick={() => sortTable('total_leave')}>
            Total
            {sortedKey === 'total_leave' && (
              <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {reportData.map((item, index) => (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td style={{ color: 'black' }}>
              <Link
                to={`/employee-profile?id=${item.id}&tab=emp_leave`}
                target="_blank"
              >
                {item.employee_id}
              </Link>
            </td>
            <td style={{ color: 'blue' }}>
              <Link
                to={`/employee-profile?id=${item.employee_id}&tab=emp_leave`}
                target="_blank"
              >
                {item.first_name + ' ' + item.last_name}
              </Link>
            </td>
            <td >{item.designation_name}</td>
            <td >{item.department_name}</td>
            <td>{item.casual}</td>
            <td>{item.lfp}</td>
            <td style={{ color: 'blue' }}>{item.net_total}</td>
          </tr>
        ))}
      </tbody>
    </>
  )
}

