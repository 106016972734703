import { CButton, CCard, CCardBody, CCol, CForm, CInputGroup, CRow } from '@coreui/react'
import React, { useEffect,useRef, useState } from 'react'
import BaseListing from 'src/BaseListings'
import WidgetBranchSelector from '../../widgets/Selectors/WidgetBranchSelector'
import WidgetDepartmentSelector from '../../widgets/Selectors/WidgetDepartmentSelector'
import WidgetDesignationSelector from '../../widgets/Selectors/WidgetDesignationSelector'
import { wtApiCall, wtApiPostWithContentTypeHandling } from '../../helpers/commons/ApiWrapper'
import { lzNotifyErrorMessageFromResult } from '../../helpers/commons/notifications'
import WidgetEmployeeSelector from 'src/widgets/Selectors/WidgetEmployeeSelector'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import DataReportTable from './DataReportTable'
import Loading from 'src/helpers/commons/loader'
import { wtGetDateMonthYearFormat } from 'src/helpers/commons/moment'
import { useDownloadExcel } from 'react-export-table-to-excel'

export default function EmployeeFamilyDetailReport() {
  const Base = new BaseListing()
  const [isLoading, setIsLoading] = useState(false)
  const [Employees, setEmployees] = useState([])
  const [EmployeeId, setEmployeeId] = useState(0)
  const [EmployeeName, setEmployeeName] = useState('')
  const [branches, setBranches] = useState([])
  const [branchId, setBranchId] = useState(0)
  const [branch_name, setBranchName] = useState('')
  const [departments, setDepartments] = useState([])
  const [department_id, setDepartmentId] = useState(0)
  const [department_name, setDepartmentName] = useState('')
  const [designations, setDesignations] = useState([])
  const [designation_id, setDesignationId] = useState(0)
  const [designation_name, setDesignationName] = useState('')
  const [errors, setErrors] = useState([])
  const [isMore, setIsMore] = useState(false)
  const [reportData, setReportData] = useState([])
  const tableRef = useRef(null)

  const generatePDF = () => {
    const doc = new jsPDF('landscape')
    const logo = 'assets/wil-icon.png'
    const img = new Image()
    var totalPagesExp = '{total_pages_count_string}'
    let yPosition = 45

    const headRows = [
      {
        content: 'Sr',
      },
      {
        dataKey: 'id',
        content: 'ID',
      },
      {
        dataKey: 'personal_file_no',
        content: 'Personal File No.',
      },
      {
        dataKey: 'full_name',
        content: 'Full Name',
      },
      {
        dataKey: 'branch.name',
        content: 'Branch',
      },
      {
        dataKey: 'department.name',
        content: 'Department',
      },
      {
        dataKey: 'designation.name',
        content: 'Designation',
      },
      {
        dataKey: 'mobile_no',
        content: 'Mobile no.',
      },
      {
        dataKey: 'cnic',
        content: 'CNIC',
      },
      {
        dataKey: 'date_of_birth',
        content: 'Date Of Birth',
      },
      {
        dataKey: 'date_of_joining',
        content: 'Date Of Joining',
      },
      {
        dataKey: 'duration',
        content: 'Duration',
      },
      {
        dataKey: 'age',
        content: 'Age',
      },
      {
        dataKey: 'job_status',
        content: 'Job Status',
      },
    ]

    const employeeDetails = reportData.map((employee, index) => [
      index + 1,
      { content: employee.id, styles: { textColor: '#0000FF' } },
      employee.personal_file_no,
      employee.full_name,
      employee.branch?.name || '',
      employee.department?.name || '',
      employee.designation?.name || '',
      employee.mobile_no,
      employee.cnic,
      { content: wtGetDateMonthYearFormat(employee.date_of_birth) || '-' },
      { content: wtGetDateMonthYearFormat(employee.date_of_joining) || '-' },
      employee.duration,
      employee.age,
      {
        content: employee.job_status,
        styles: {
          textColor: employee.job_status === 'Trainee' ? '#0000FF' : '#008000',
        },
      },
    ])

    doc.autoTable({
      head: [headRows],
      body: employeeDetails,
      startY: 45,
      margin: { top: 50, bottom: 20, left: 10, right: 10 },
      theme: 'plain',
      tableLineColor: '#3c4b64',
      tableLineWidth: 0.2,
      tableWidth: 280,
      styles: { lineColor: '#3c4b64', lineWidth: 0.2, halign: 'center' },
      headStyles: {
        fillColor: '#fff',
        textColor: [60, 75, 100],
        setFontSize: 8,
        font: 'helvetica',
      },

      didDrawPage: function (data) {
        //get page no
        totalPagesExp = doc.internal.getNumberOfPages()
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp)
        }
        let pageNo = doc.internal.getNumberOfPages()
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth() // Header

        doc.setTextColor('#3c4b64')
        doc.setFont('Helvetica', 'bold') //Header middle branch logo

        doc.addImage(logo, 'PNG', pageWidth - 280, 4, 25, 25)
        doc.line(pageWidth - 254, 7, pageWidth - 254, 27) // Adjust the coordinates as needed
        doc.setFontSize(11)
        doc.text('WAH', pageWidth - 251, 10, { align: 'left' })
        doc.setFont('Helvetica')
        doc.text('INDUSTRIES', pageWidth - 251, 15)
        doc.text('LIMITED', pageWidth - 251, 20)
        doc.setFontSize(9)
        doc.text('www.wahindustries.com', pageWidth - 251, 25)

        doc.setFontSize(14)
        doc.text('Employee Data Report', pageWidth - 20, 10, { align: 'right' }) // Add header separator

        yPosition -= -5 // Set alignment for the text that follows the line
        doc.setFont('Helvetica')
        doc.setFontSize(12)
        doc.setTextColor('#3c4b64')

        //Page No
        var str = 'Page ' + pageNo
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }

        doc.setTextColor('black')
        doc.text(str, 10, pageHeight - 4)
      },
    })

    doc.setFontSize(10)
    const printReport =
      'This Report is printed through ERP at ' + moment().format('YYYY-MM-DD HH:mm:ss')
    const styleReport = { halign: 'center' }
    const pageWidth = doc.internal.pageSize.width
    const textWidth =
      (doc.getStringUnitWidth(printReport) * doc.internal.getFontSize()) / doc.internal.scaleFactor
    const startXForPrintReport = (pageWidth - textWidth) / 2
    const startYForPrintReport = doc.autoTable.previous.finalY + 20

    doc.text(printReport, startXForPrintReport, startYForPrintReport, styleReport)

    doc.save('EmployeeDetails.pdf')
  }

  // const getDepartments = (branch_id) => {
  //   if (branch_id !== 0) {
  //     wtApiCall(
  //       'dropdown',
  //       {
  //         dropdown_names: 'departments',
  //         branch_id: branch_id,
  //       },
  //       'get',
  //       (result) => {
  //         if (result !== null) {
  //           if (result.data.length > 0) {
  //             setDepartments(result.data[0]['departments']) // as I am calling only department dropdown I have mentioned it explicitly
  //           }
  //         }
  //       },
  //       (result) => {
  //         lzNotifyErrorMessageFromResult(result)
  //       },
  //     )
  //   }
  // }

  const changeValues = (key, val) => {
    // if (key === 'branch_id') {
    //   setBranchId(val.value)
    //   setBranchName(val.label)
    //   getDepartments(val.value)
    // }
    if (key === 'department_id') {
      setDepartmentId(val.value)
      setDepartmentName(val.label)
    }
    if (key === 'designation_id') {
      setDesignationId(val.value)
      setDesignationName(val.label)
    }
    if (key === 'employee_id') {
      setEmployeeId(val.value)
      setEmployeeName(val.label)
    }
  }

  const getReportData = () => {
    setIsLoading(true)
    wtApiCall(
      'reports/employee_family_details_report',
      {
        employee_id: EmployeeId,
        branch_id: branchId,
        department_id: department_id,
        designation_id: designation_id,
      },
      'get',
      (response) => {
        const { data } = response
        setReportData(data)
        setIsLoading(false)
      },
      (error) => {
        lzNotifyErrorMessageFromResult(error)
      },
    )
  }
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Employee Family Report',
    sheet: 'Sheet',
  })

  // const getPDFReportData = () => {
  //   wtApiCall(
  //     'employee_data_report',
  //     {},
  //     'get',
  //     (response) => {
  //       const { data } = response
  //       generatePDF(data)
  //     },
  //     (error) => {
  //       lzNotifyErrorMessageFromResult(error)
  //     },
  //   )
  // }


  useEffect(() => {
    Base.dropdownData(
      'employees,branches,designations,departments',
      {},
      [setEmployees, setBranches, setDesignations,setDepartments],
      1,
    )
    // getData()
  }, [])
  return (
    <>
      <div style={{ padding: '2%' }}>
        <center>
          <h4>Employee Family Detail Report</h4>
        </center>
        <CRow style={{ width: '100%' }}>
          <CCol md={12}>
            <CCard className="card-dark">
              <CCardBody style={{ backgroundColor: '#f7f9fa' }}>
                <br />
                <CForm>
                  <CRow>
                    {/* <CCol md={1}></CCol> */}
                    <CCol md={1}>
                      <CInputGroup>
                        <label style={{ fontSize: '140%', marginTop: '50px' }}>Filters:</label>
                      </CInputGroup>
                    </CCol>
                    <CCol md={3}>
                      <CInputGroup>
                        <label>Employee</label>
                        <WidgetEmployeeSelector
                          employees={Employees}
                          displayValue={EmployeeName}
                          value={EmployeeId}
                          placeholder="Select Employee"
                          onChange={(values) => changeValues('employee_id', values)}
                          reloadDropdownData={() =>
                            Base.dropdownData('employees', {}, [setEmployees], 1)
                          }
                        />
                        {errors.employee_id && <ErrorMessage messages={errors.employee_id} />}
                      </CInputGroup>
                    </CCol>
                    <CCol md={3}>
                      <CInputGroup>
                        <label>Department</label>
                        <WidgetDepartmentSelector
                          departments={departments}
                          displayValue={department_name}
                          value={department_id}
                          placeholder="Select Department"
                          onChange={(values) => changeValues('department_id', values)}
                          reloadDropdownData={() =>
                            Base.dropdownData('departments', {}, [setDepartments], 1)
                          }
                        />
                        {errors.department_id && <ErrorMessage messages={errors.department_id} />}
                      </CInputGroup>
                    </CCol>
                    <CCol md={3}>
                      <CInputGroup>
                        <label>Designation</label>
                        <WidgetDesignationSelector
                          designations={designations}
                          displayValue={designation_name}
                          value={designation_id}
                          placeholder="Select Designation"
                          onChange={(values) => changeValues('designation_id', values)}
                          reloadDropdownData={() =>
                            Base.dropdownData('designations', {}, [setDesignations], 1)
                          }
                        />
                        {errors.designation_id && <ErrorMessage messages={errors.designation_id} />}
                      </CInputGroup>
                    </CCol>
                    <CCol md={2}>
                      {/* <CButton
                        color="warning"
                        className="text-white"
                        onClick={() => setIsMore(!isMore)}
                        style={{ marginBottom: '20px', marginTop: '20px' }}
                      >
                        More
                      </CButton> */}

                      <CButton
                        color="primary"
                        onClick={() => getReportData()}
                        style={{ marginBottom: '20px', marginTop: '20px', marginLeft: '1%' }}
                      >
                        Search
                      </CButton>
                      <CButton
                        color="success"
                        className="text-white"
                        style={{ marginBottom: '20px', marginTop: '20px', marginLeft: '1%' }}
                        onClick={onDownload}
                      >
                        Export
                      </CButton>
                      {/* <CButton
                        color="danger"
                        className="text-white"
                        style={{ marginBottom: '20px', marginTop: '20px', marginLeft: '1%' }}
                        onClick={generatePDF}
                      >
                        PDF
                      </CButton> */}
                    </CCol>
                  </CRow>
                  {/* {isMore && (
                    <>
                      <CRow>
                        <CCol md={1}></CCol>

                        <CCol md={3}>
                          <CInputGroup>
                            <label>Designation</label>
                            <WidgetDesignationSelector
                              designations={designations}
                              displayValue={designation_name}
                              value={designation_id}
                              placeholder="Select Designation"
                              onChange={(values) => changeValues('designation_id', values)}
                              reloadDropdownData={() =>
                                Base.dropdownData('designations', {}, [setDesignations], 1)
                              }
                            />
                            {errors.designation_id && (
                              <ErrorMessage messages={errors.designation_id} />
                            )}
                          </CInputGroup>
                        </CCol>
                      </CRow>
                    </>
                  )} */}
                  <br />
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <br />
        <br />
        <table className="table table-bordered data-table" ref={tableRef}>
        {isLoading ? (
          Loading
        ) : (
          <>
            {reportData.length > 0 ? (
              <DataReportTable
                reportData={reportData}
                setReportData={(data) => setReportData(data)}
                isLoading={isLoading}
              />
            ) : (
              <center style={{ fontSize: '19px', fontWeight: 'bold' }}>No record to show...</center>
            )}
          </>
        )}
        </table>

      </div>
    </>
  )
}
