import HttpHelper from "../../httpHelper";
import { saveAs } from 'file-saver';
import { wtSaveToLocalStorage, wtGetFromLocalStorage } from "../LocalStorage";
import { lzNotifyErrorMessageFromResult } from "../notifications";
import { currentDateTime } from '../../../helpers/commons/moment';
import { WtAppConstants } from '../../../helpers/commons/constants';

export const wtApiCall = (url, data, method, successCallback, failureCallback, entity = "", timeout = 90000, regardless = function () { }) => {


  let idleTimeout = wtGetFromLocalStorage('idle_timeout', currentDateTime());
  let authToken = wtGetFromLocalStorage('authToken', '');


  // Build headers to send
  const headers = {
    'Content-type': 'multipart/form-data',
  };

  // Set method if not set already
  if (!method) {
    method = 'get';
  }

  // Init data to send (if not already init) and attach basic values to it
  if (!data) {
    data = {};
  }
  if (data instanceof FormData) {
    data.append('_branch_id', wtGetFromLocalStorage('default_branch_id', 0));
  } else {
    data._branch_id = wtGetFromLocalStorage('default_branch_id', 0);
  }

  // Special handling for laravel resources
  if ('get' === method || 'post' === method) {
    data._method = method;
  } else {
    // this goes for methods like patch, put, delete, etc.
    data._method = method;
    method = 'post';
  }

  //Send idle timeout and current time for verification of idle system
  data._idleTimeOut = idleTimeout;
  data._currentDateTime = currentDateTime();

  // Attach auth token if exists
  if (authToken && authToken !== '') {
    headers.Authorization = 'Bearer ' + authToken;
  }

  // Build settings for the call
  let settings = {
    url,
    headers,
    data,
    timeout: timeout
  };

  // Make the http request object
  const httpRequest = new HttpHelper();

  if (method === 'get') {
    settings['params'] = data;
  }
  else {
    settings['data'] = data;
  }

  // Set failure callback if not set already
  if (!failureCallback) {
    failureCallback = (result) => {
      lzNotifyErrorMessageFromResult(result);
    };
  }

  // set the new idleTimeout for other requests
  wtSaveToLocalStorage('idle_timeout', currentDateTime());

  // Call based on method
  if ('get' === method) {
    httpRequest.http_get(
      settings,
      {
        401: () => doLogout(),
        419: () => doLogout(),
        200: (result) => successCallback(JSON.parse(result)),
        500: (result) => failureCallback(JSON.parse(result)),
        403: (result) => failureCallback(JSON.parse(result)),
      },
      regardless
    );
  } else if (method === 'post') {
    httpRequest.http_post(
      settings,
      {
        401: () => doLogout(),
        419: () => doLogout(),
        200: (result) => successCallback(JSON.parse(result)),
        500: (result) => failureCallback(JSON.parse(result)),
        413: (result) => failureCallback(JSON.parse(result)),
        422: (result) => failureCallback(JSON.parse(result)),
        403: (result) => failureCallback(JSON.parse(result)),
      },
      regardless
    );
  } else if (method === 'put') {
    httpRequest.http_put(
      settings,
      {
        401: () => doLogout(),
        200: (result) => successCallback(JSON.parse(result)),
        500: (result) => failureCallback(JSON.parse(result)),
        403: (result) => failureCallback(JSON.parse(result)),
      },
      regardless
    );
  }

  else if (method === 'delete') {
    httpRequest.http_delete(
      settings,
      {
        401: () => doLogout(),
        200: (result) => successCallback(JSON.parse(result)),
        500: (result) => failureCallback(JSON.parse(result)),
        403: (result) => failureCallback(JSON.parse(result)),
      },
      regardless
    );
  }

};



export const wtParseApiCallFailureMessage = (result) => {
  if (typeof result === 'string') {
    return result;
  }

  let msg = '';

  if (result && result.message) {
    msg = result.message;
    if (result.data) {
      const keys = Object.keys(result.data);
      for (let key of keys) {
        msg += "\n    * " + result.data[key];
      }
    }
  }

  if ('' === msg) {
    if (result && result.exception && result.exception.indexOf('PostTooLargeException') > 0) {
      msg = 'The submitted payload exceeds the max allowed limit!';
    }
  }

  return msg;
};

export const doLogout = () => {
  // Get dispatch from redux store
  const reduxDispatch = window.reduxStore.dispatch;

  // Dispatch logout to redux store

  wtSaveToLocalStorage('authUser', null);
  wtSaveToLocalStorage('authToken', '');
  wtSaveToLocalStorage('company_id', 0);
  wtSaveToLocalStorage('company_name', '');
  wtSaveToLocalStorage('branches', []);
  wtSaveToLocalStorage('default_branch_id', 0);
  wtSaveToLocalStorage('default_branch_name', '');
  wtSaveToLocalStorage('idle_timeout', '');
  wtSaveToLocalStorage('errorMessages', []);
  wtSaveToLocalStorage('successMessages', []);
  reduxDispatch({ type: 'set', authUser: null, authToken: '', company_id: 0, company_name: '', idle_timeout: '', branches: [], default_branch_id: 0, default_branch_name: '', errorMessages: [], successMessages: [], loggedInAttempt: false });

  // Invalidate the display
  window.location.reload();
};

export const lzIsArray = (input) => {
  return Array.isArray(input);
};


export const wtApiPostWithContentTypeHandling = async (url, data) => {

  let idleTimeout = wtGetFromLocalStorage('idle_timeout', currentDateTime());
  let authToken = wtGetFromLocalStorage('authToken', '');

  // Build headers to send
  const headers = {
    'Content-type': 'application/json',
  };


  // Init data to send (if not already init) and attach basic values to it
  if (!data) {
    data = {};
  }
  if (data instanceof FormData) {
    data.append('_branchId', wtGetFromLocalStorage('default_branch_id', 0));
  } else {
    data.__branchId = wtGetFromLocalStorage('default_branch_id', 0);
  }

  // Special handling for laravel resources
  data._method = 'POST';

  //Send idle timeout and current time for verification of idle system
  data._idleTimeOut = idleTimeout;
  data._currentDateTime = currentDateTime();

  // Attach auth token if exists
  if (authToken && authToken !== '') {
    headers.Authorization = 'Bearer ' + authToken;
  }

  // Make init data
  const initData = {
    method: 'POST',
    body: JSON.stringify(data),
    headers,
  };

  const response = await fetch(WtAppConstants.api_base_url + url, initData);

  // If response is a csv then show it
  const responseContentType = response?.headers?.get('Content-Type');
  if (responseContentType?.indexOf('text/csv') >= 0) {
    const filename = response.headers.get('Content-Disposition')?.replace('attachment; filename=', '') || 'export.csv';
    response.blob().then((blobData) => lzDownloadBlobFile(blobData, filename));
    return null;
  }

  if (responseContentType?.indexOf('application/pdf') >= 0) {
    const filename = response.headers.get('Content-Disposition')?.replace('attachment; filename="', '')?.replace('"', '') || 'report.pdf';
    response.blob().then((blobData) => lzDownloadBlobFile(blobData, filename));
    return null;
  }

  // Parse the json out of the response
  try {
    const json = await response.json();

    // Handle the data
    if (!json || typeof json.data === 'undefined' || typeof json.message === 'undefined') {
      lzNotifyErrorMessageFromResult(json.message || 'Invalid data returned from server.');
      return null;
    }

    // Validate success and return it if valid
    if (json.success) {
      return json.data;
    }

    lzNotifyErrorMessageFromResult(json.message);
  } catch (e) {
    lzNotifyErrorMessageFromResult('An error has occured in the api call to server!<br/>Please check logs for details.');
  }
  return null;
};


export const lzDownloadBlobFile = (blob, filename) => {
  saveAs(blob, filename);
};
