import React, { useState } from 'react'
import { CCol, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import './table.css'
import { wtGetDateMonthYearFormat } from 'src/helpers/commons/moment'

export default function DataReportTable({ reportData, setReportData }) {
  const [sortedKey, setSortedKey] = useState('')
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc',
  })

  const sortTable = (key) => {
    let direction = 'asc'
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    const sorted = [...reportData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1
      }
      return 0
    })
    setReportData(sorted)
    setSortedKey(key)
    setSortConfig({ key, direction })
  }

  return (
    <CRow>
      <CCol md={12}>
        <table className="table table-bordered data-table">
          <thead class="thead-dark" style={{ backgroundColor: '#e8e9eb' }}>
            <tr>
              <th onClick={() => sortTable('id')}>Sr</th>
              <th onClick={() => sortTable('id')}>
                ID{' '}
                {sortedKey === 'id' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('full_name')}>
                Full Name
                {sortedKey === 'full_name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('branch.name')}>
                Branch
                {sortedKey === 'branch.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('department.name')}>
                Department
                {sortedKey === 'department.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('effective_date')}>
                Effective Date
                {sortedKey === 'effective_date' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('to_date')}>
                To Date
                {sortedKey === 'to_date' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('new_designation.name')}>
                Current Designation
                {sortedKey === 'new_designation.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
              <th onClick={() => sortTable('old_designation.name')}>
                Old Designation
                {sortedKey === 'old_designation.name' && (
                  <CIcon icon={sortConfig.direction === 'asc' ? cilChevronTop : cilChevronBottom} />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {reportData.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td style={{ color: 'blue' }}>{item.employee_id}</td>
                <td style={{ color: 'blue' }}>{item.employee.full_name}</td>
                <td>{item.employee.branch.name}</td>
                <td>{item.employee.department.name}</td>
                <td>{wtGetDateMonthYearFormat(item.effective_date)}</td>
                <td style={{ color: 'green' }}>Present</td>
                <td style={{ color: 'blue' }}>{item.new_designation.name}</td>
                <td style={{ color: 'red' }}>{item.old_designation.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </CCol>
    </CRow>
  )
}
