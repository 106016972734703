import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CToast, CToastBody, CToastClose, CToaster } from '@coreui/react'
import { wtLoadLoggedInData } from 'src/helpers/commons/utils'
import { wtGetFromLocalStorage } from 'src/helpers/commons/LocalStorage'
import { Navigate } from 'react-router-dom'
import EmployeeDataReport from '../EmployeeDataReport'
import { wtHrefHashGetVars } from 'src/helpers/commons/HrefHash'
import { lzNotifyErrorMessageFromResult } from 'src/helpers/commons/notifications'
import DesignationTrackingReport from '../DesignationTrackingReport'
import EmployeeHierarchy from '../../views/EmployeeHierarchy/EmployeeHierarchy'
import EmployeeDetailedDataReport from '../EmployeeDetailedDataReport'
import HRDashboard from '../HRDashboard'
import EmployeeOnMoveReport from '../EmployeeOnMoveReport'
import EmployeeAttendanceReport from '../EmployeeAttendanceReport'
import EmployeeAttendanceDifferenceReport from '../EmployeeAttendanceDifferenceReport'
import EmployeeLateComingReport from '../EmployeeLateComingReport'
import EmployeeEarlyGoingReport from '../EmployeeEarlyGoingReport'
import EmployeePerformanceEvaluationReport from '../EmployeePerformanceEvaluationReport'
import AttendanceAnalysisReport from '../AttendanceAnalysisReport'
import LeaveReport from '../LeaveReport'
import TrialBalance from '../TrialBalance'
import AccountStatement from '../AccountStatement'
import BalanceSheet from '../BalanceSheet'
import ProfitAndLoss from '../ProfitAndLoss'
import EmployeePerformanceEvaluationDetailedReport from '../EmployeePerformanceEvaluationDetailedReport'
import VehicleLogReport from '../VehicleLogReport'


import OvertimeReport from '../OvertimeReport'
import EmployeeFamilyDetailReport from '../EmployeeFamilyDetailReport'

export default function Reports({ authToken }) {
  const [reportCode, setReportCode] = useState('')
  const [reportParams, setReportParams] = useState('')
  const errorMessages = useSelector((state) => state.errorMessages)
  const successMessages = useSelector((state) => state.successMessages)

  const discardErrorMsg = (dex) => {
    const reduxDispatch = window.reduxStore.dispatch
    const msgs = errorMessages.slice()
    msgs.splice(dex, 1)
    reduxDispatch({ type: 'set', errorMessages: msgs })
  }

  const discardSuccessMsg = (dex) => {
    const reduxDispatch = window.reduxStore.dispatch

    const msgs = successMessages.slice()
    msgs.splice(dex, 1)
    reduxDispatch({ type: 'set', successMessages: msgs })
  }

  const loadReport = (params) => {
    setReportCode(params.code)
    setReportParams(params)
  }
  useEffect(() => {
    //call logged in api again to update user (user rights)
    const authToken = wtGetFromLocalStorage('authToken', '')
    if (authToken !== null && authToken !== '' && typeof authToken !== 'undefined') {
      wtLoadLoggedInData()
    }
    // Get hash params
    const hashParams = wtHrefHashGetVars()

    // Check if code parameter is passed or not
    if (!hashParams.hasOwnProperty('code')) {
      lzNotifyErrorMessageFromResult('Invalid code provided')
      return
    }

    loadReport(hashParams)
  }, [])
  return (
    <div>
      <CToaster placement="top-end">
        {errorMessages.length > 0 &&
          errorMessages.map((eMsg, eDex) => (
            <CToast
              animation={true}
              autohide={true}
              visible={true}
              key={eDex}
              color="danger"
              onClick={() => discardErrorMsg(eDex)}
            >
              <CToastBody className="text-white">
                <CToastClose className="me-2 m-auto" white style={{ float: 'right' }} />
                <h4>
                  <strong>Error!</strong>
                </h4>
                {eMsg}
              </CToastBody>
            </CToast>
          ))}

        {successMessages.length > 0 &&
          successMessages.map((eMsg, eDex) => (
            <CToast key={eDex} animation={true} autohide={true} visible={true} color="success">
              <CToastBody className="text-white">
                <CToastClose
                  className="me-2 m-auto"
                  white
                  style={{ float: 'right' }}
                  onClick={() => discardSuccessMsg(eDex)}
                />
                <h4>
                  <strong>Success!</strong>
                </h4>
                {eMsg}
              </CToastBody>
            </CToast>
          ))}
      </CToaster>
      {authToken !== '' && authToken !== null ? (
        <>
          {'employee-data-report' === reportCode && <EmployeeDataReport {...reportParams} />}
          {'designation-tracking-report' === reportCode && (
            <DesignationTrackingReport {...reportParams} />
          )}
          {'employees-hierarchy' === reportCode && <EmployeeHierarchy {...reportParams} />}
          {'employee-detailed-data-report' === reportCode && (
            <EmployeeDetailedDataReport {...reportParams} />
          )}
          {'hr-dashboard' === reportCode && <HRDashboard {...reportParams} />}
          {'employee-on-move-report' === reportCode && <EmployeeOnMoveReport {...reportParams} />}
          {'employee-attendance-report' === reportCode && (
            <EmployeeAttendanceReport {...reportParams} />
          )}
          {'employee-attendance-difference-report' === reportCode && (
            <EmployeeAttendanceDifferenceReport {...reportParams} />
          )}
          {'employee-late-coming-report' === reportCode && (
            <EmployeeLateComingReport {...reportParams} />
          )}
          {'employee-early-going-report' === reportCode && (
            <EmployeeEarlyGoingReport {...reportParams} />
          )}
          {'overtime-report' === reportCode && <OvertimeReport {...reportParams} />}
          {'employee-performance-evaluation-report' === reportCode && (
            <EmployeePerformanceEvaluationReport {...reportParams} />
          )}
          {'employee-attendance-analysis-report' === reportCode && (
            <AttendanceAnalysisReport {...reportParams} />
          )}
          {'employee-leave-report' === reportCode && (
            <LeaveReport {...reportParams} />
          )}

          {'trial-balance' === reportCode && (
            <TrialBalance {...reportParams} />
          )}
          {'account-statement' === reportCode && (
            <AccountStatement {...reportParams} />
          )}
          {'balance-sheet' === reportCode && (
            <BalanceSheet {...reportParams} />
          )}
          {'profit-and-loss' === reportCode && (
            <ProfitAndLoss {...reportParams} />
          )}
          {'employee-performance-details-report' === reportCode && (
            <EmployeePerformanceEvaluationDetailedReport {...reportParams} />
          )}
          {'vehicle-log-report' === reportCode && (
            <VehicleLogReport {...reportParams} />
          )}
          {'employee-family-details-report' === reportCode && (
            <EmployeeFamilyDetailReport {...reportParams} />
          )}
        </>
      ) : (
        <Navigate to={'/login'} />
      )}
    </div>
  )
}
